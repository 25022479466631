// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./CookiesPolicyStyles";
import { AppStyles } from "../../theme";
import { WebHeader, WebFooter } from "../../components";
export default function CookiesPolicyView(props) {
  return (
    <div>
      <WebHeader isChildPage={true} bannerTitle={"Terms of Use"} />
      <section>
        <div className={`px-5 ${css(AppStyles.container)}`}>
          <div className={css(styles.view)}>
            <h2 className={`mb-3 ${css([styles.heading])}`}>
            QCAST COOKIE POLICY
            </h2>
            <p className={`mb-3 ${css([styles.description])}`}>LAST UPDATED: 19 NOVEMBER 2021</p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>WHAT ARE COOKIES?</p>
            <p className={`mb-3 ${css(styles.description)}`}>
            A cookie is a small text file that a website stores on your computer or mobile device when you visit the site.
            </p>
            <ul>
              <li>First party cookies are cookies set by the website you’re visiting. Only that website can read them. In addition, a website might potentially use external services, which also set their own cookies, known as third-party cookies.</li>
              <li>Persistent cookies are cookies saved on your computer and that are not deleted automatically when you quit your browser, unlike a session cookie, which is deleted when you quit your browser.</li>
              </ul>
              <p className={`mb-3 ${css(styles.description)}`}>
              Every time you visit the Qcast website, you’ll be prompted to accept or refuse cookies.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
            The purpose is to enable the site to remember your preferences (such as username, language, etc.) for a certain period of time.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
            That way, you don’t have to re-enter them when browsing around the site during the same visit.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
            Cookies can also be used to establish anonymised statistics about the browsing experience on our sites.
            </p>
            <p className={`mb-3 ${css([styles.subHeading])} `}>HOW DO WE USE COOKIES?</p>
            <p className={`mb-3 ${css(styles.description)} ${css([styles.bold])}`}>
            Analytics cookies
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            These cookies allow us to gather analytics to improve the performance and functionality of our Websites. These analytics can include measurements on the number of users on our Websites, how long they stay on our Websites for, and what parts of our Websites they visit. We use this information in aggregate form to help us to improve the way our Websites work.
            </p>
            <p className={`mb-3 ${css(styles.description)} ${css([styles.bold])}`}>
            Social media cookies
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            These cookies allow us to integrate social media functions into our Websites.
            </p>
            <p className={`mb-3 ${css(styles.description)} ${css([styles.bold])}`}>
            Web beacons
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            As well as cookies, we use other technologies to recognize and track your use of our Websites. A web beacon (also known as a “tracking pixel” or “clear GIF”) is a clear graphic image (typically a one-pixel tag) that is delivered through a web browser or HTML e-mail, typically in conjunction with a cookie.
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            Web beacons allows us, for example, to monitor how users move from one page within our Websites to another, to track access to our communications, to understand whether users have come to our Websites from an online advertisement displayed on a third-party website, to measure how ads have been viewed and to improve site performance.
            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>HOW WE YOU MANAGE COOKIES?</p>

            <p className={`mb-3 ${css(styles.description)}`}>
              Unless otherwise specified, the terms of use detailed in this
              section apply generally when using this Website. Single or
              additional conditions of use or access may apply in specific
              scenarios and in such cases are additionally indicated within this
              document. By using this Website, Users confirm to meet the
              following requirements: There are no restrictions for Users in
              terms of being Consumers or Business Users; Users must be older
              than 18;
            </p>
            <p className={`mb-3 ${css(styles.description)} ${css([styles.bold])}`}>
            Removing cookies from your device
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            You can delete all cookies that are already on your device by clearing the browsing history of your browser. This will remove all cookies from all websites you have visited.
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            Be aware though that you may also lose some saved information (e.g. saved login details, site preferences)
            </p>



            <p className={`mb-3 ${css(styles.description)} ${css([styles.bold])}`}>
            Managing site-specific cookies
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            For more detailed control over site-specific cookies, check the privacy and cookie settings in your preferred browser
            </p>



            <p className={`mb-3 ${css(styles.description)} ${css([styles.bold])}`}>
            Blocking cookies
            </p>
            <p className={`mb-3 ${css(styles.subDescription)}`}>
            You can set most modern browsers to prevent any cookies being placed on your device, but you may then have to manually adjust some preferences every time you visit a site/page. And some services and functionalities may not work properly at all (e.g. profile logging-in).
            </p>
          </div>
        </div>
      </section>
      <WebFooter />
    </div>
  );
}
