// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./PortalStyles";
import { WebHeader, WebFooter } from "../../components";
import { Container, Row, Col } from "react-bootstrap";
import image from "../../assets/images/about-image-1/about-image-1.png";

export default function PortalView(props) {
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <h1 className={css(styles.heading)}>Qcast Portal</h1>
            </Col>
          </Row>

          <Row style={{ textAlign: "center" }}>
            <Col>
              <p className={css(styles.description)}>
                Log in to Qcast Portal. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Mauris aliquet nec tellus nullam posuere mauris
                accumsan amet, tortor. Tellus sed arcu feugiat libero venenatis
                aliquet diam.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section2)}>
        <Container className={css(styles.customWidth)}>
          <form>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <input
                  type="email"
                  className={css(styles.textInput)}
                  placeholder={"Email"}
                />
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <input
                  type="password"
                  className={css(styles.textInput)}
                  placeholder={"Password"}
                />
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <input
                  type="submit"
                  className={css(styles.submitButton)}
                  placeholder={"Password"}
                  value="Log in"
                />
              </Col>
            </Row>
          </form>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
