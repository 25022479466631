// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 80,
    "@media (max-width: 980px)": {
      marginTop: 40,
    },
  },

  heading: {
    fontSize: 48,
    fontFamily: "Poppins",
    fontWeight: "700",
    color: "#23517B",
    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  description: {
    fontSize: 20,
    color: "#515A6B",
    fontFamily: "Poppins",
    marginTop: 10,
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 300px)": {
      fontSize: 18,
    },
  },

  section2: {
    marginTop: 5,
    marginBottom: 120,
  },

  textInput: {
    width: "46%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 17,
    paddingRight: 17,
    marginTop: 20,
    borderRadius: 10,
    background: "#EFF6FC",
    border: "none",
    boxShadow:
      "inset -2px -5px 7px rgba(255, 255, 255, 0.7), inset 2px 5px 8px rgba(0, 0, 0, 0.25)",
    fontSize: 18,
    color: "#7B94B5",
    fontWeight: "500",
    "@media (max-width: 980px)": {
      width: "60%",
    },
    "@media (max-width: 600px)": {
      width: "80%",
    },
    ":focus": {
      outline: "none",
    },
  },

  submitButton: {
    width: "46%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 17,
    paddingRight: 17,
    marginTop: 20,
    borderRadius: 100,
    background: "Linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    border: "none",
    boxShadow:
      "1px -3px 10px rgba(255, 255, 255, 0.7), 5px 3px 10px rgba(0, 0, 0, 0.18)",
    fontSize: 18,
    color: "#7B94B5",
    fontWeight: "700",
    "@media (max-width: 980px)": {
      width: "60%",
    },
    "@media (max-width: 600px)": {
      width: "80%",
    },
    ":focus": {
      outline: "none",
    },
  },
});
