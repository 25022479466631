// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";
import RightArrow from "../../assets/images/sliderRightArrowIcon/sliderRightArrowIcon.png";
import LeftArrow from "../../assets/images/sliderLeftArrowIcon/sliderLeftArrowIcon.png";
import "./Team.scss"
export default StyleSheet.create({
  imageView: {
    width: "100%",
    textAlign: "center",
    marginTop: 10,
    ":focus": {
      outline: "none",
    },
  },

  

  detailView: {
    //width: "50%",
    
    margin: "auto",
    marginTop: 0,
    paddingBottom: 30,
    "@media (max-width: 991px)": {
      //width: "80%",
      textAlign: "center",
    },
  },

  

  detailDescription: {
    fontSize: 18,
    color: "#515A6B",
    textAlign:"center", 
    "@media (max-width: 650px)": {
      fontSize: 12,
    },
  },

  sliderView: {
    position: "relative",
    marginBottom: 50,
  },

  nextArrow: {
    background: "transparent",

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${RightArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -26,
    },

    "@media (max-width: 650px)": {
      right: 25,
    },
  },

  prevArrow: {
    background: "transparent",
    zIndex: 9999,

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${LeftArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -32,
      left: -25,
    },

    "@media (max-width: 650px)": {
      left: 20,
    },
  },
  newLayout:{
    display:"flex",
    flexDirection:"row",
    flexWrap:"wrap",
    marginTop:50
  },
  columnData:{
    width:"25%",
    minHeight:"150px",
    cursor:"pointer",
    "@media (max-width: 1170px)": {
      width:"33%",
    },"@media (max-width: 965px)": {
      width:"50%",
    },"@media (max-width: 560px)": {
      width:"100%",
    }
  },
  insideData:{
    display:"flex",
    flexDirection:"column",
    position:"relative",
    "@media (max-width: 1170px)": {
      flexDirection:"column",
    },"@media (max-width: 965px)": {
      flexDirection:"column",
    },"@media (max-width: 560px)": {
      flexDirection:"row",
      
    }
  },
  image: {
    width: 200,
    height:"auto",
    "@media (max-width: 1170px)": {
    },"@media (max-width: 965px)": {
    },"@media (max-width: 560px)": {
      width: 150
      
    }
  },
  title:{
    paddingLeft:10,
    "@media (max-width: 1170px)": {
    },"@media (max-width: 965px)": {
    },"@media (max-width: 560px)": {
      display:"flex",
      flexDirection:"column",
      justifyContent:"center"
      
    }
  },
  detailTitle: {
    fontWeight: "700",
    fontSize: 24,
    color: "#6497C7",
    marginBottom:10,
    "@media (max-width: 1170px)": {
    },"@media (max-width: 965px)": {
    },"@media (max-width: 560px)": {
      fontSize: 18,
      
    }
  },

  detailSubTitle: {
    fontSize: 14,
    fontWeight: "700",
    color: "black",
    marginBottom:10,
    "@media (max-width: 1170px)": {
    },"@media (max-width: 965px)": {
    },"@media (max-width: 560px)": {
      fontSize: 12,
      
    }
  },
  modal:{
    display:"flex",
    flexDirection:"column",
    padding:"20px 50px",
    "@media (max-width: 560px)": {
      padding:"20px 15px",
      
    }
  },
  modalHeader:{
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    gap:10
  },
  modalHeaderImage:{
    width:70,
    height:"auto"
  },modaldetailSubTitle:{
    fontSize: 12,
    fontWeight: "700",
    color: "black",
    marginBottom:10,
  },modaldetailTitle:{
    fontWeight: "700",
    fontSize: 20,
    color: "#6497C7",
    marginBottom:10,
  },
 
});
