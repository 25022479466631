// @flow
import React from "react";
import CorporateSocialResponsibilityView from "./CorporateSocialResponsibilityView";

class SustainabilityAndEnvironmentController extends React.Component {
  static propTypes = {};

  render() {
    return <CorporateSocialResponsibilityView {...this.props} />;
  }
}

export default SustainabilityAndEnvironmentController;
