// @flow
import React from "react";
import AboutView from "./AboutView";

class AboutController extends React.Component {
  static propTypes = {};

  render() {
    return <AboutView {...this.props} />;
  }
}

export default AboutController;
