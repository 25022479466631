// @flow
import _ from "lodash";
import React from "react";
import Util from "../../services/Util";
import UsesCasesView from "./UsesCasesView";

class UsesCasesController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {};

  render() {
    return <UsesCasesView {...this.props} />;
  }
}

export default UsesCasesController;
