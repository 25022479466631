// @flow
import React,{useState} from 'react';
import _ from 'lodash';
import { css } from 'aphrodite/no-important';
import styles from './styles';
import rightIconBlue from "../../assets/images/rightIconBlue/rightIconBlue.png";
import { Link } from 'react-router-dom';
import {ROUTES} from "../../constants";

export default class VideoPlayer extends React.PureComponent {





  render() {
    const ref = React.createRef();
    const ref1 = React.createRef();
    const ref2 = React.createRef();
    return(<></>)
   /*  return (
      <div style={{display:'flex',flexDirection:"column",gap:30}}>
      <div className={css(styles.pricingCard)}>
        <div className={css(styles.Row1)}><div className={css(styles.h1)}>FREE PLAN</div><div className={css(styles.cost)}>£0</div></div>
        <div className={css(styles.Row2)}>per Person / Month</div>
        <div className={css(styles.Row3)}>Storage Quota: 2 GB a month
for recorded Qcasts + Qcast Responses</div>
        <div className={css(styles.Row4)}>For people starting out, using
Qcast to begin engagement.</div>
        <div className={css(styles.Row5)} onClick={
          function(){
            if (ref.current ) {
              if(ref.current.classList.contains("pricing-mobile-feacture-visible")){
                ref.current.classList.remove('pricing-mobile-feacture-visible')
              }else{
                ref.current.classList.add('pricing-mobile-feacture-visible')
              }
            }
          }
        }><div className={css(styles.viewFeature)}><p>View features <i className='fa fa-angle-down'></i></p></div><div className={css(styles.button)}><Link to={ROUTES.CONTACT} style={{decoration:"none"}}>Subscribe</Link></div></div>
        <div className='pricing-mobile-feacture' ref={ref}>

        <div  className={css(styles.options)}><div >Subscriber Limit</div><div className={css(styles.optionsItem)}>1</div></div>

        <div className={css(styles.options)}><div>Question Per Qcast</div><div className={css(styles.optionsItem)}>6</div></div>
        <div className={css(styles.options)}><div>Question Length</div><div className={css(styles.optionsItem)}>1 minute</div></div>
        <div className={css(styles.options)}><div>Response Length</div><div className={css(styles.optionsItem)}>3 minutes</div></div>
        <div className={css(styles.options)}><div>Team Channel</div><div className={css(styles.optionsItem)}></div></div>

        <div className={css(styles.options)}><div>Webcam Feature</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Video Editing</div><div className={css(styles.optionsItem)}>In App only</div></div>
        <div className={css(styles.options)}><div>Video Uploading</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Channel Permission</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Ban and report</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Workspace Privacy</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Single Sign On (SSO + SAML)</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Account Transfer and ownership of content (Admin, Contributor, View)</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Telephone Based Support</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Qcast Chat</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Dedicated Acount Manager</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Qcast Plugin Feature</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Qcast Keynote</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Archive Feature</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Insights</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Sentiment Analysis</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Inspect Permissions</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Session Duration Management</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Global Search</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Search</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Google Searchable Content</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>

        
        <div className={css(styles.end)} onClick={
          function(){
            if (ref.current ) {
              ref.current.classList.remove('pricing-mobile-feacture-visible')
            }
          }
        }><i className='fa fa-angle-up'></i><p style={{margin:"0 5px 0 5px"}}>Hide features</p><i className='fa fa-angle-up'></i></div>



        </div>
      </div>

      <div className={css(styles.pricingCard)}>
        <div className={css(styles.Row1)}><div className={css(styles.h1)}>PRO PLAN</div><div className={css(styles.cost)}>£5.99</div></div>
        <div className={css(styles.Row2)}>per Person / Month</div>
        <div className={css(styles.Row3)}>Storage Quota: 2 GB a month
for recorded Qcasts + Qcast Responses</div>
        <div className={css(styles.Row4)}>For people who want to share deeper immersive engagement with their audience.</div>
        <div className={css(styles.Row5)} onClick={
          function(){
            if (ref1.current ) {
              if(ref1.current.classList.contains("pricing-mobile-feacture-visible")){
                ref1.current.classList.remove('pricing-mobile-feacture-visible')
              }else{
                ref1.current.classList.add('pricing-mobile-feacture-visible')
              }
            }
          }
        }><div className={css(styles.viewFeature)}><p>View features <i className='fa fa-angle-down'></i></p></div><div className={css(styles.button)}><Link to={ROUTES.CONTACT} style={{decoration:"none"}}>Subscribe</Link></div></div>
        <div className='pricing-mobile-feacture' ref={ref1}>

        <div  className={css(styles.options)}><div >Subscriber Limit</div><div className={css(styles.optionsItem)}>1</div></div>

        <div className={css(styles.options)}><div>Question Per Qcast</div><div className={css(styles.optionsItem)}>12</div></div>
        <div className={css(styles.options)}><div>Question Length</div><div className={css(styles.optionsItem)}>2 minute</div></div>
        <div className={css(styles.options)}><div>Response Length</div><div className={css(styles.optionsItem)}>10 minutes</div></div>
        <div className={css(styles.options)}><div>Team Channel</div><div className={css(styles.optionsItem)}></div></div>

        <div className={css(styles.options)}><div>Webcam Feature</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Video Editing</div><div className={css(styles.optionsItem)}>In App only + Website</div></div>
        <div className={css(styles.options)}><div>Video Uploading</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Permission</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Ban and report</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Workspace Privacy</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Single Sign On (SSO + SAML)</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Account Transfer and ownership of content (Admin, Contributor, View)</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Telephone Based Support</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Qcast Chat</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Dedicated Acount Manager</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Qcast Plugin Feature</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Qcast Keynote</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Archive Feature</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Insights</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Sentiment Analysis</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Inspect Permissions</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Session Duration Management</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Global Search</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Search</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Google Searchable Content</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>

        
        <div className={css(styles.end)} onClick={
          function(){
            if (ref1.current ) {
              ref1.current.classList.remove('pricing-mobile-feacture-visible')
            }
          }
        }><i className='fa fa-angle-up'></i><p style={{margin:"0 5px 0 5px"}}>Hide features</p><i className='fa fa-angle-up'></i></div>



        </div>
      </div>



      <div className={css(styles.pricingCard)}>
        <div className={css(styles.Row1)}><div className={css(styles.h1)}>BUSINESS PLAN</div><div className={css(styles.cost)}>£2.99</div></div>
        <div className={css(styles.Row2)}>per Person
(£14.95 for x5 User; starting price) / Month</div>
        <div className={css(styles.Row3)}>Storage Quota: 2 GB a month
for recorded Qcasts + Qcast Responses</div>
        <div className={css(styles.Row4)}>For teams of people who want to use Qcast with a private network.
(User Limits Apply)</div>
        <div className={css(styles.Row5)} ><div className={css(styles.viewFeature)} onClick={
          function(){
            if (ref2.current ) {
              if(ref2.current.classList.contains("pricing-mobile-feacture-visible")){
                ref2.current.classList.remove('pricing-mobile-feacture-visible')
              }else{
                ref2.current.classList.add('pricing-mobile-feacture-visible')
              }
            }
          }
        }><p>View features <i className='fa fa-angle-down'></i></p></div><div className={css(styles.button)}><Link to={ROUTES.CONTACT} style={{decoration:"none"}}>Subscribe</Link></div></div>
        <div className='pricing-mobile-feacture' ref={ref2}>

        <div  className={css(styles.options)}><div >Subscriber Limit</div><div className={css(styles.optionsItem)}>5+</div></div>

        <div className={css(styles.options)}><div>Question Per Qcast</div><div className={css(styles.optionsItem)}>20</div></div>
        <div className={css(styles.options)}><div>Question Length</div><div className={css(styles.optionsItem)}>5 minute</div></div>
        <div className={css(styles.options)}><div>Response Length</div><div className={css(styles.optionsItem)}>20 minutes</div></div>
        <div className={css(styles.options)}><div>Team Channel</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>

        <div className={css(styles.options)}><div>Webcam Feature</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Video Editing</div><div className={css(styles.optionsItem)}>In App only + Website</div></div>
        <div className={css(styles.options)}><div>Video Uploading</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Permission</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Ban and report</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Workspace Privacy</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Single Sign On (SSO + SAML)</div><div className={css(styles.optionsItem)}></div></div>
        <div className={css(styles.options)}><div>Account Transfer and ownership of content (Admin, Contributor, View)</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Telephone Based Support</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Qcast Chat</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Dedicated Acount Manager</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Qcast Plugin Feature</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Qcast Keynote</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Archive Feature</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Insights</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Sentiment Analysis</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Inspect Permissions</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Session Duration Management</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Global Search</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Channel Search</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>
        <div className={css(styles.options)}><div>Google Searchable Content</div><div className={css(styles.optionsItem)}><img src={rightIconBlue} alt="" /></div></div>

        
        <div className={css(styles.end)} onClick={
          function(){
            if (ref2.current ) {
              ref2.current.classList.remove('pricing-mobile-feacture-visible')
            }
          }
        }><i className='fa fa-angle-up'></i><p style={{margin:"0 5px 0 5px"}}>Hide features</p><i className='fa fa-angle-up'></i></div>



        </div>
      </div>

      </div>
      
      
    ); */
  }
}
