// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 50,
  },

  heading: {
    color: "#23517B",
    fontSize: 48,
    fontFamily: "Poppins",
    fontWeight: "700",

    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  subHeading: {
    color: "#6497C7",
    fontSize: 32,
    fontFamily: "Poppins",
    fontWeight: "600",
    marginTop: 10,

    "@media (max-width: 500px)": {
      fontSize: 26,
    },
    "@media (max-width: 400px)": {
      fontSize: 20,
    },
  },

  description: {
    color: "#515A6B",
    fontFamily: "Poppins",
    fontSize: 20,
    width: "60%",

    "@media (max-width: 980px)": {
      width: "100%",
    },
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },

  section2: {
    marginTop: 100,
    marginBottom: 50,
    "@media (max-width: 980px)": {
      marginTop: 40,
    },
  },

  section2View: {
    display: "flex",
    alignItems: "center",
  },

  img: {
    width:318,
    "@media (max-width: 980px)": {
      margin: "auto",
      width: "60%",
      display: "flex",
    },
  },

  section2Description: {
    color: "#515A6B",
    fontFamily: "Poppins",
    fontSize: 20,
    marginBottom: 30,

    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },

  downloadLink: {
    background: "#97AAC3",
    fontSize: 16,
    fontFamily: "Poppins",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    color: "#fff",
    boxShadow:
      "-2px -2px 5px rgba(255, 255, 255, 0.7), 2px 2px 5px rgba(0, 0, 0, 0.18)",
    borderRadius: 5,
  },
});
