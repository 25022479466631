// @flow
import React from "react";
import FaqView from "./FaqView";

class FaqController extends React.Component {
  static propTypes = {};

  render() {
    return <FaqView {...this.props} />;
  }
}

export default FaqController;
