import { css } from 'aphrodite'
import React from 'react'
import { connect } from 'react-redux'
import { WebHeader, WebFooter } from '../../components'
import styles from './styles'
import { Container, Row, Col } from 'react-bootstrap'
import image3 from '../../assets/images/heroSectionImage3/3 - Phone_Purple.png'
/* import QCastLogo from "../../assets/images/QCastLogo/QCastLogo.png"; */
import qcastHomepage from '../../assets/images/qcastHomepage.png'
import logo from '../../assets/images/logo/logo.png'
import computerImg from '../../assets/images/computerImg/computerImg.png'
import Tooltip from '@material-ui/core/Tooltip'
import iPad from '../../assets/images/computerImg/new/iPad_Purple.png'
import Laptop from '../../assets/images/computerImg/new/Laptop_Purple.png'
import Phone from '../../assets/images/computerImg/new/Phone_Purple.png'
import Phone2 from '../../assets/images/computerImg/Phone2.png'
import indestryImage from '../../assets/images/indestryImage/mobileview.png'
import HomeMan from '../../assets/images/indestryImage/Cool Guys Qcast.png'
import HomeLady from '../../assets/images/indestryImage/2 - Phone_Purple.png'
import socialMediaImage from '../../assets/images/indestryImage/4 - Phone_Purple.png'
/* import touchSectionImage from "../../assets/images/touchSectionImage/touchSectionImage.png"; */

import touchMobile from '../../assets/images/touchSectionImage/touchMobile.png'
import touch from '../../assets/images/touchSectionImage/touch.png'
import touchResponse from '../../assets/images/touchSectionImage/touchResponse.png'

import { Images } from '../../theme'
import Fade from 'react-reveal/Fade'
import { DownloadLine } from '../../components/DownloadLine'
import { IMAGES } from '../../assets/images/path'
import { DownloadButtons } from '../../components/DownloadButtons'

class LandingPage extends React.Component {
    render() {
        return (
            <div>
                <WebHeader isHomePage={true} />
                <div className={css(styles.viewDesktop)}>
                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <Row>
                                <Col lg="6" md="12">
                                    <div className={css(styles.view)}>
                                        {/* <div style={{display:"flex",paddingRight:120,paddingTop:50}}>
                  <img src={qcastHomepage} className={css(styles.logo)} />
                  </div> */}

                                        <h1
                                            className={`HeadingGradient ${css(
                                                styles.heading
                                            )} `}
                                            style={{ paddingTop: 50 }}>
                                            How could you harness
                                            <br /> the power of questions?
                                        </h1>
                                        <p
                                            className={css(
                                                styles.heroSectionPara
                                            )}>
                                            Create shareable questions
                                            <br />
                                            for insightful responses
                                        </p>
                                        <DownloadLine />
                                        <DownloadButtons />
                                    </div>
                                </Col>

                                <Col
                                    lg="6"
                                    md="12"
                                    className={css(styles.image1View)}>
                                    <Fade right>
                                        <img
                                            src={Phone}
                                            className={css(styles.phone)}
                                        />
                                    </Fade>
                                    <Fade left>
                                        <img
                                            src={Laptop}
                                            className={css(styles.laptop)}
                                        />
                                    </Fade>

                                    <Fade right>
                                        <img
                                            src={iPad}
                                            className={css(styles.iPad)}
                                        />
                                    </Fade>

                                    {/* <Fade right>
              <img src={computerImg} className={css(styles.image1Style)} />

              </Fade> */}
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div className={css(styles.view2)}>
                                <Row>
                                    <Col
                                        lg="5"
                                        md="12"
                                        className={css(styles.image2View)}>
                                        <div
                                            className={css(
                                                styles.image2StyleContainer
                                            )}>
                                            <Fade left>
                                                <img
                                                    src={HomeMan}
                                                    className={css(
                                                        styles.image2Style
                                                    )}
                                                    width="702px"
                                                />
                                            </Fade>
                                            <Fade right>
                                                <img
                                                    src={HomeLady}
                                                    className={css(
                                                        styles.image2StyleInner
                                                    )}
                                                    width="220px"
                                                />
                                            </Fade>
                                        </div>
                                    </Col>

                                    <Col
                                        lg="7"
                                        md="12"
                                        className={css(styles.section2Col2)}>
                                        <div className={css(styles.view)}>
                                            {/*<p className={css(styles.tagline)}>Industry First</p>*/}
                                            <h1
                                                className={`${css(
                                                    styles.heading2
                                                )} HeadingGradient`}>
                                                What is Qcast?
                                            </h1>
                                            <p className={css(styles.para)}>
                                                Qcast is a new social network
                                                built on visual questions and
                                                answers.
                                                <br />
                                                Our goal is to open up new
                                                creative thoughts and enable
                                                users to ask questions that
                                                spark fresh ideas and inspire
                                                conversations.
                                            </p>

                                            <DownloadLine />
                                            <DownloadButtons />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div className={css(styles.view3)}>
                                <Row className={css(styles.customRowStyle)}>
                                    <Col
                                        lg="6"
                                        md="12"
                                        style={{ display: 'flex' }}
                                        className={css(styles.image3View)}>
                                        <Fade right>
                                            <img
                                                style={{ margin: 'auto' }}
                                                src={image3}
                                                className={css(
                                                    styles.image3Style
                                                )}
                                            />
                                        </Fade>
                                    </Col>

                                    <Col
                                        lg="6"
                                        md="12"
                                        className={css(styles.section3Col2)}>
                                        <div
                                            className={css(styles.view)}
                                            style={{ marginTop: 60 }}>
                                            {/*<p className={css(styles.tagline)}>Progressive Tech</p>*/}
                                            <h1
                                                className={`${css(
                                                    styles.heading2
                                                )} HeadingGradient`}>
                                                How does it work?
                                            </h1>
                                            <p className={css(styles.para)}>
                                                As a Qcaster, you simply pose
                                                the questions you’d like
                                                responses to. Record your
                                                questions on video in the app
                                                and then publish them so your
                                                fellow Qcasters can respond.
                                            </p>
                                            <p className={css(styles.para)}>
                                                You can respond to the questions
                                                that inspire you, follow your
                                                favourite Qcasters and share the
                                                Qcasts and Qcast Responses you
                                                love.
                                            </p>
                                            <DownloadLine />
                                            <DownloadButtons />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div className={css(styles.view4)}>
                                <Row>
                                    <Col
                                        lg="6"
                                        md="12"
                                        style={{ display: 'flex' }}
                                        className={css(styles.image4View)}>
                                        <Fade left>
                                            <img
                                                style={{ margin: 'auto' }}
                                                src={socialMediaImage}
                                                className={css(
                                                    styles.image4Style
                                                )}
                                            />
                                        </Fade>
                                    </Col>

                                    <Col
                                        lg="6"
                                        md="12"
                                        className={css(styles.section4Col2)}>
                                        <div className={css(styles.view)}>
                                            {/*} <p className={css(styles.tagline)}>
                      Social Media Evolution
                  </p>*/}
                                            <h1
                                                className={`${css(
                                                    styles.heading2
                                                )} HeadingGradient`}>
                                                Why Qcast?
                                            </h1>
                                            <p className={css(styles.para)}>
                                                Qcast evolved from the idea that
                                                too much of social media is
                                                dominated by the closing down of
                                                discussion. We’d like to change
                                                the paradigm.
                                            </p>
                                            <p className={css(styles.para)}>
                                                We seek to provoke insight,
                                                broaden the canvas and open up
                                                ideas by asking questions that
                                                spark up discussions and inspire
                                                conversation.
                                            </p>
                                            <DownloadLine />
                                            <DownloadButtons />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div className={css(styles.view5)}>
                                <Row className={css(styles.customRowStyle)}>
                                    <Col
                                        lg="6"
                                        md="12"
                                        className={css(styles.image5View)}>
                                        {/* <Fade right>
                <img
                    src={touchResponse}
                    className={css(styles.touchResponse)}
                  />
                  </Fade> */}
                                        <Fade left>
                                            <img
                                                src={touch}
                                                className={css(styles.touch)}
                                            />
                                        </Fade>

                                        {/* <Fade right>
                  <img
                    src={touchSectionImage}
                    className={css(styles.image5Style)}
                  />
                  </Fade> */}
                                    </Col>

                                    <Col
                                        lg="6"
                                        md="12"
                                        className={css(styles.section5Col2)}
                                        style={{ paddingRight: 40 }}>
                                        <div className={css(styles.view)}>
                                            {/*<p className={css(styles.tagline)}>Touch</p>*/}
                                            <h1
                                                className={`${css(
                                                    styles.heading2
                                                )} HeadingGradient`}>
                                                What makes a question different?
                                            </h1>
                                            <p className={css(styles.para)}>
                                                By asking questions, we share
                                                and encourage insight. By coming
                                                together to answer a question,
                                                we can help to solve some of the
                                                big challenges of our day.
                                            </p>
                                            <p className={css(styles.para)}>
                                                Will you join the wider Qcast
                                                community online?
                                            </p>
                                            <p className={css(styles.para)}>
                                                Or will you use Qcast privately
                                                to power discussions with your
                                                audiences, stakeholders,
                                                customers or staff?
                                            </p>
                                            <DownloadLine />
                                            <DownloadButtons />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </section>
                </div>
                <div className={css(styles.viewMobile) + ' landingMobile'}>
                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <Row>
                                <Col lg="6" md="12">
                                    <div className={css(styles.view)}>
                                        <p
                                            className={css(
                                                styles.heroSectionPara
                                            )}>
                                            Create shareable questions
                                            <br />
                                            for insightful responses
                                        </p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: '0 20px',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <img
                                                src={Phone}
                                                style={{
                                                    color: 'black',
                                                    width: '80%',
                                                    // paddingBottom: 25,
                                                }}
                                            />

                                            <p
                                                className={css(styles.para)}
                                                style={{
                                                    fontWeight: 600,
                                                    width: '80%',
                                                }}>
                                                Ask, respond and engage through
                                                Qcast{' '}
                                            </p>
                                        </div>

                                        <DownloadLine />
                                        <DownloadButtons />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div
                                className={css(styles.view2)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    //padding: "0 20px",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <h1 
                                 className={`HeadingGradient ${css(
                                    styles.heading2
                                )} `}
                                >
                                    What is Qcast?
                                </h1>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        padding: '15px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <div>
                                        <img
                                            src={indestryImage}
                                            style={{ width: '100%' }}
                                        />
                                    </div>

                                    <div>
                                        <div className={css(styles.view)}>
                                            {/*<p className={css(styles.tagline)}>Industry First</p>*/}

                                            <p className={css(styles.para)}>
                                                Qcast is a new social network
                                                built on visual questions and
                                                answers.
                                                <br />
                                                Our goal is to open up new
                                                creative thoughts and enable
                                                users to ask questions that
                                                spark fresh ideas and inspire
                                                conversations.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <DownloadLine />
                                    <DownloadButtons/>
                                </div>
                            </div>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div
                                className={css(styles.view2)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    //padding: "0 20px",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <h1 className={`HeadingGradient ${css(
                                    styles.heading2
                                )} `}>
                                    How does it work?
                                </h1>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        //padding: "0 20px",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '20px 0px 20px 0px',
                                    }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ marginLeft: '5px' }}>
                                            {/*<p className={css(styles.tagline)}>Industry First</p>*/}

                                            <p className="mobileP">
                                                As a Qcaster, you simply pose
                                                the questions you’d like
                                                responses to. Record your
                                                questions on video in the app
                                                and then publish them so your
                                                fellow Qcasters can respond.
                                            </p>
                                            <p className="mobileP">
                                                You can respond to the questions
                                                that inspire you, follow your
                                                favourite Qcasters and share the
                                                Qcasts and Qcast Responses you
                                                love.
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <img
                                            style={{ width: '90%' }}
                                            src={image3}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <DownloadLine />
                                    <DownloadButtons/>
                                </div>
                            </div>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div
                                className={css(styles.view2)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    //padding: "0 20px",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <h1 className={`HeadingGradient ${css(
                                    styles.heading2
                                )} `}>
                                    Why Qcast?
                                </h1>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        //padding: "0 20px",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: '20px 0px 20px 0px',
                                    }}>
                                    <div style={{ flex: 1 }}>
                                        <img
                                            style={{ width: '90%' }}
                                            src={socialMediaImage}
                                        />
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <div style={{ marginLeft: '5px' }}>
                                            {/*<p className={css(styles.tagline)}>Industry First</p>*/}

                                            <p className="mobileP">
                                                Qcast evolved from the idea that
                                                too much of social media is
                                                dominated by the closing down of
                                                discussion. We’d like to change
                                                the paradigm.
                                            </p>
                                            <p className="mobileP">
                                                We seek to provoke insight,
                                                broaden the canvas and open up
                                                ideas by asking questions that
                                                spark up discussions and inspire
                                                conversation.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <DownloadLine />
                                    <DownloadButtons/>
                                </div>
                            </div>
                        </Container>
                    </section>

                    <section style={{ overflow: 'hidden' }}>
                        <Container className={css(styles.customWidth)}>
                            <div
                                className={css(styles.view2)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    //padding: "0 20px",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <h1 className={`HeadingGradient ${css(
                                    styles.heading2
                                )} `}>
                                    <p
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '30px',
                                        }}>
                                        What makes a question different?
                                    </p>
                                </h1>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        margin: '20px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ marginLeft: '5px' }}>
                                            {/*<p className={css(styles.tagline)}>Industry First</p>*/}

                                            <p className="mobileP">
                                                By asking questions, we share
                                                and encourage insight. By coming
                                                together to answer a question,
                                                we can help to solve some of the
                                                big challenges of our day.
                                            </p>
                                        </div>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <img
                                            style={{ width: '100%' }}
                                            src={touchMobile}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <p className="mobileP">
                                        Will you join the wider Qcast community
                                        online?
                                    </p>
                                    <p
                                        className="mobileP"
                                        style={{ marginBottom: 30 }}>
                                        Or will you use Qcast privately to power
                                        discussions with your audiences,
                                        stakeholders, customers or staff?
                                    </p>
                                    <DownloadLine />
                                    <DownloadButtons/>
                                </div>
                            </div>
                        </Container>
                    </section>
                </div>

                <WebFooter />
            </div>
        )
    }
}

const mapStateToProps = () => ({})

const actions = {}

export default connect(mapStateToProps, actions)(LandingPage)
