// @flow
import React from "react";
import PortalView from "./PortalView";

class PortalController extends React.Component {
  static propTypes = {};

  render() {
    return <PortalView {...this.props} />;
  }
}

export default PortalController;
