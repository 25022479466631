// @flow
import _ from "lodash";
import React from "react";
import Util from "../../services/Util";
import ContactView from "./ContactView";

class ContactController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      nameError: "",
      emailError: "",
      messageError: "",
    };
  }

  static propTypes = {};

  validation = () => {
    const { name, email, message } = this.state;
    let validate = true;

    if (_.isEmpty(name)) {
      this.setState({ nameError: "Name is empty" });
      validate = false;
    }

    if (!Util.isValidName(name)) {
      this.setState({ nameError: "Invalid name" });
      validate = false;
    }

    if (_.isEmpty(email)) {
      this.setState({ emailError: "Email is empty" });
      validate = false;
    }

    if (!Util.isEmailValid(email)) {
      this.setState({ emailError: "Invalid email" });
      validate = false;
    }

    if (_.isEmpty(message)) {
      this.setState({ messageError: "Email is empty" });
      validate = false;
    }

    return validate;
  };

  render() {
    const { name, email, message, nameError, emailError, messageError } =
      this.state;

    return (
      <ContactView
        {...this.props}
        name={name}
        email={email}
        message={message}
        nameError={nameError}
        emailError={emailError}
        messageError={messageError}
        validation={this.validation}
      />
    );
  }
}

export default ContactController;
