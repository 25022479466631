// @flow
import React from "react";

import CookiesPolicyView from "./CookiesPolicyView";

export default class CookiesPolicyController extends React.Component {
  static propTypes = {};

  static defaultProps = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return <CookiesPolicyView {...this.props} />;
  }
}
