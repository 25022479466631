// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 80,
    marginBottom: 80,
    "@media (max-width: 980px)": {
      marginTop: 40,
      marginBottom: 40,
    },
  },

  heading: {
    fontFamily: "Poppins",
    fontSize: 48,
    fontWeight: "700",
    color: "#23517B",
    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  description: {
    fontSize: 24,
    color: "#515A6B",
    fontFamily: "Poppins",
    marginTop: 20,
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 300px)": {
      fontSize: 18,
    },
  },

  pdfView: {
    display: "flex",
    alignItems: "center",
    marginBottom: 40,
    marginTop: 10,
  },

  downloadText: {
    color: "#97AAC3",
    fontSize: 24,
    fontWeight: "700",
    marginTop: 15,
  },
});
