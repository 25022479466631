// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./CareerStyles";
import { WebHeader, WebFooter } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";
import careerImg from "../../assets/images/careerImg/careerImg@3x.png";

export default function CareerView(props) {
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.heading)}>Careers</h1>
              <p className={css(styles.description)}>
                No opening at the moment, check back soon.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section2)}>
        <Container>
          <Row>
            <Col>
              <img src={careerImg} width="100%" />
            </Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
