// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import InlineView from "./InlineView";
import satalite from "../../assets/data/satalite";
import { ROUTES } from "../../constants";
class InlineController extends React.Component {
 

  static propTypes = {};
  
  render() {
    let pageData=satalite.filter(v=>v.tag==="rise-education-series");
    console.log(this)
    if(pageData.length>0){
      let videoData=pageData[0].data.filter(v=>v.tag==="rise-education")
      if(videoData.length>0){

        return <InlineView {...this.props} videoProp={{video:pageData[0],data:videoData[0]}}/>;
      }else{
        return <Redirect to={ROUTES.SATELITE+"/"+pageData[0].tag} />
      }
     
    }else{
      return <Redirect to={ROUTES.SATELITE} />
    }

    
  }
}

export default InlineController;
