// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  heading: {
    fontSize: 48,
    color: "#23517B",
    fontWeight: "700",
    fontFamily: "Poppins",

    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 38,
    },
  },

  section1: {
    marginTop: 70,

    "@media (max-width: 98px)": {
      marginTop: 40,
    },
  },

  section2: {
    marginTop: 10,
    marginBottom: 30,
  },

  listMainView: {
    display: "flex",
    flexWrap: "wrap",
  },

  listView: {
    paddingLeft: 10,
    marginTop: 30,
  },

  listHeading: {
    color: "#2C3037",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "600",
    listStyle: "none",
  },

  list: {
    listStyle: "none",
    marginTop: 10,
  },

  listText: {
    color: "#515A6B",
    fontSize: 14,
    fontFamily: "Poppins",
    textDecoration: "none",
  },
});
