// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./PrivacyPolicyStyles";
import { AppStyles } from "../../theme";
import { WebHeader, WebFooter } from "../../components";

export default function PrivacyPolicyView(props) {
  return (
    <div>
      <WebHeader isChildPage={true} bannerTitle={"Privacy Policy"} />
      <section>
        <div className={`px-5 ${css(AppStyles.container)}`}>
          <div className={`my-4 mb-5`}>
            <h2 className={`mb-4 mt-5  ${css([styles.heading])}`}>
              QCAST PRIVACY NOTICE
            </h2>

            <p className={`mb-3 ${css(styles.description)}`}>
              This   privacy   notice   for   Qcast   Ltd   ("Company,"   "we,"   "us,"   or   "our"),
              describes   how   and   why   we   might   collect,   store,   use,   and/or   share
              ("process") your information when you use our services ("Services")<br />
              Questions   or   concerns?   Reading   this   privacy   notice   will   help   you
              understand your privacy rights and choices. If you do not agree with our
              policies and practices, please do not use our Services. If you still have any
              questions or concerns, please contact us by emailing data@qcast.io.

            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>SUMMARY OF KEY POINTS</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              This summary provides key points from our privacy notice, but you can
              find out more details about any of these topics by clicking the link
              following each key point or by using our table of contents below to find
              the section you are looking for. You can also click here to go directly to
              our table of contents.<br />
              What   personal   information   do   we   process?   When   you   visit,   use,   or
              navigate our Services, we may process personal information depending on
              how you interact with and the Services, the choices you make, and the
              products and features you use. Click here to learn more.
              Do we process any sensitive personal information? We do not process
              sensitive personal information.<br />
              Do you  receive  any  information  from  third parties?  We may  receive
              information   from   public   databases,   marketing   partners,   social   media
              platforms, and other outside sources. Click here to learn more.
              How do you process my information? We process your information to
              provide, improve, and administer our Services, communicate with you, for
              security and fraud prevention, and to comply with law. We may also
              process   your   information   for   other   purposes   with   your   consent.   We
              process your information only when we have a valid legal reason to do so.
              Click here to learn more.<br />
              What   are   your   rights?   Depending   on   where   you   are   located
              geographically, the applicable privacy law may mean you have certain
              rights regarding your personal information. Click here to learn more.
              How do I exercise my rights? The easiest way to exercise your rights is by
              filling out by contacting us at data@qcast.io and inserting ‘data subject
              request form’ in the subject line. We will consider and act upon any
              request in accordance with applicable data protection laws.

            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>TABLE OF CONTENTS</p>
            <ol className={`mb-3 ${css(styles.description)}`}>
              <li>The type of personal information we collect </li>
              <li>How Qcast collects personal information and why we have it</li>
              <li>How to access, change and erase your personal information</li>
              <li>Your data protection rights</li>
              <li>Do we make updates to this notice?</li>
              <li>How can you contact us about this notice?</li>
              <li>How to complain</li>
            </ol>
            <p className={`mb-3 ${css([styles.subHeading])}`}>1.	THE TYPE OF PERSONAL INFORMATION WE COLLECT</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              ‘Qcast  Products’ specifically refers to the mobile  application  and the
              website <a href="https://qcast.io/">https://qcast.io/</a>. To provide the Qcast Products, we must process
              information about you. Qcast is a video-based platform that is committed
              to creating a safe space for people to inquire about their most pressing
              problems and connect with people globally to find answers and share
              stories. <br />

              We currently collect and process the following information:


            </p>
            <p><b>Personal information you disclose to us</b></p>
            <p className={`mb-3 ${css(styles.description)}`}>
              In Short: We collect personal information that you provide to us.
              We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our Products and Services (collectively refers to Qcast Plugin, <a href="https://qcast.io/">https://qcast.io/</a> and the Qcast mobile application), when you participate in activities on the Services, or otherwise when you contact us.
            </p>
            <p><b>Sensitive Information. We do not process sensitive information.</b></p>
            <p className={`mb-3 ${css(styles.description)}`}>
              All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </p>
            <p><b>Information automatically collected</b></p>
            <p className={`mb-3 ${css(styles.description)}`}>
              In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
              Like many businesses, we also collect information through cookies and similar technologies.

            </p>
            <ol className={`mb-3 ${css(styles.description)}`} type="a">
              <li>Things that you and others do and provide
                <ul>
                  <li><b>Information and content you provide.</b> We collect the content (videos, photos etc), communications and other information you provide when you use our Products, including when you sign up for an account (Personal identifiers, contacts and characteristics (for example, name and contact details), create or share content and message or communicate with others. This can include information in or about the content that you provide (e.g. metadata), such as the location of a video or the date a file was created.<br />

                    Data with special protections: You can choose to provide information in your Qcast profile or life events about your religious views, political views, who you are "interested in" or your health. This and other information (such as racial or ethnic origin, philosophical beliefs or trade union membership) is subject to special protections under EU law.
                  </li><li><b>Networks and connections.</b> We collect information about the people, Qcast pages accounts, hashtags and groups that you are connected to and how you interact with them across our Products, such as people you communicate with the most or groups that you are part of.
                  </li>
                  <li><b>•	Your usage.</b>We collect information about how you use our Products, such as the types of content that you view or engage with, the features you use, the actions you take, the people or accounts you interact with and the time, frequency and duration of your activities. For example, we log when you're using and have last used our Products, and what posts, videos and other content you view on our Products. We also collect information about how you use features such as our camera.</li>
                  <li><b>•	Information about transactions made on our Products.</b>If you use our Products for purchases or other financial transactions we collect information about the purchase or transaction. This includes payment information, such as your credit or debit card number and other card information, other account and authentication information, and billing, delivery and contact details.</li>

                </ul>
              </li>
              <li>Things others do and information they provide about you.<br />We also receive and analyse content, communications and information that other people provide when they use our Products. This can include information about you, such as when others share or comment on a video of you, send a message to you or upload, sync or import your contact information. </li>
              <li>Device information: <br />

                We collect device information to personalise features that you see when you use our Products and to measure what you have done in response to certain videos and Qcasts on our platform. <br />
                Information that we obtain from these devices includes:
                <ul>
                  <li><b>Device attributes: </b>information such as the operating system, hardware and software versions, battery level, signal strength, available storage space, browser type, app and file names and types, and plugins.</li>
                  <li><b>Device operations: </b>information about operations and behaviours performed on the device, such as whether a window is in the foreground or background, or mouse movements (which can help distinguish humans from bots).</li>
                  <li><b>Identifiers:</b>unique identifiers, device IDs and other identifiers, such as from games, apps or accounts that you use</li>
                  <li><b>Device signals: </b>Bluetooth signals, information about nearby Wi-Fi access points, beacons and mobile phone masts.</li>
                  <li><b>Data from device settings: </b>information you allow us to receive through device settings that you turn on, such as access to your GPS location, camera or photos.</li>
                  <li><b>Network and connections:</b>: information such as the name of your mobile operator or ISP, language, time zone, mobile phone number, IP address, connection speed.</li>
                  <li><b>Cookie Data: </b>data from cookies stored on your device, including cookie IDs and settings. Learn more about how we use cookies in the (link to cookie policy)</li>
                </ul>
              </li>
              <li>Information from Partners<br />App developers, and other partner organisations can send information through plugins, APIs and Qcast tools. Partners receive your data when you visit or use their services, or through third parties that they work with. We require each of these partners to have lawful rights to collect, use and share your data before providing us with any data.</li>
            </ol>
            <p className={`mb-3 ${css([styles.subHeading])}`}>2.	HOW QCAST COLLECTS PERSONAL INFORMATION AND WHY WE HAVE IT</p>
            
            <p className={`mb-3 ${css(styles.description)}`}>
            Most of the personal information we process is provided to us directly by you for one of the following reasons:
            <ol className={`mb-3 ${css(styles.description)}`}>
              <li>Registration. Prior to using one or more of the Qcast Products, Qcast may require you to provide us with certain Personal Information and Non-Personal Information to create an account (“Account”) or to enable features or functionality of Qcast products.</li>
              <li>Users. Qcast may gather Personal Information about organisational representatives via various methods (phone, email, online forms, in-person meetings) but only if such Personal Information is submitted voluntarily. Qcast may use such Personal Information for sales, marketing, and support of Qcast products. This Personal Information is never shared with third parties other than Third Party Service Providers utilised by a User in connection with Qcast Products.</li>
              <li>3.	User communications. When you send email or other communications to us, we may retain those communications in order to process your inquiries, respond to your requests and improve Qcast Products.</li>
              <li>4.	Payment Information. When creating an Account, for certain Qcast Products, or when you make online purchases, you may be asked to provide information, which may include your payment instrument number (e.g., credit card), your name and billing address, and the security code associated with your payment instrument (e.g., the CVV) and other financial data (“Payment Information”). We use Payment Information to complete transactions, as well as for the detection and prevention of fraud. When you provide Payment Information while authenticated, we will store that data to help you complete future transactions without your having to provide the information again. We do not, however, retain the security code associated with your payment instrument (e.g., the CVV) in this manner. To remove or modify Payment Information, please contact us. After you close your account or remove Payment Information, however, we may retain your Payment Information for as long as reasonably necessary to complete your existing transaction and for the detection and prevention.</li>
              <li>5.	Information Collected Through Technology. Qcast automatically collects and receives certain information from your computer or mobile device, including the activities you perform on the Qcast Site (this includes our website qcast.io and our mobile application), the Qcast Software and the Qcast Services, the type of hardware and software you are using (for example, your operating system or browser), and information obtained from cookies (see below). If you have an Account, we may link this Non-Personal Information to your Account to better understand your needs and the needs of Users in the aggregate, diagnose problems, analyse trends, provide services, improve the features and usability of the Qcast Properties, and better understand and market to our customers and Users</li>
              <li>We use technology to automatically gather information by the following methods:<br/><br/>
              <ol className={`mb-3 ${css(styles.description)}`}>
                <li>1.	Cookies. Qcast uses cookies on the Qcast Site and other aspects of the Qcast Properties. Cookies, including local shared objects, are small pieces of information that are stored by your browser on your computer's hard drive which work by assigning to your computer a unique number that has no meaning outside of the Qcast Products. Most web browsers automatically accept cookies, but you can usually configure your browser to prevent this. Not accepting cookies may make certain features of the Qcast Products unavailable to you.</li>
                <li>2.	IP Address. You may visit many areas of the Qcast Site anonymously without the need to become a registered User. Even in such cases, Qcast may collect IP addresses automatically. An IP address is a number that is automatically assigned to your computer whenever you begin services with an Internet services provider. Each time you access the Qcast Site and each time you request one of the pages of the Qcast Site, the server logs your IP address.</li>
                <li>3.	Web Beacons. Web beacons are small pieces of data that are embedded in web pages and emails. Qcast may use these technical methods in HTML emails that Qcast sends to Users to determine whether they have opened those emails and/or clicked on links in those emails. The information from use of these technical methods may be collected in a form that is Personal Information.</li>
                <li>4.	Tracking Content Usage. If you use the Qcast Services and you post audio visual materials including, without limitation, videos, links, logos, artwork, graphics, pictures, advertisements, sound and other related intellectual property contained in such materials (collectively, “Content”) to your website or to a third-party website, Qcast tracks and captures information associated with User accounts and the use of Content by those that access your Content.</li>
                
              </ol>
              </li>
              <li>Information You Provide About a Third Party. You may have the opportunity to communicate with others from the Qcast Products, such as by sending an invitation to a Qcaster (A Qcaster is someone who uses Qcast services). If you choose to take advantage of this functionality, we may ask you to provide us with certain information about the person with whom you wish to communicate (e.g., name, email address, etc.). Qcast collects such information for the purposes of facilitating the requested communication, which may contain a specific promotional message from you (e.g., an invitation to watch a video). Unless we explicitly say otherwise, Qcast will not use this information for other marketing purposes without first obtaining consent from the person to whom the relevant information pertains. Please be aware that when you use any invitation functionality on the Qcast Products, your email address, name or username, and message may be included in the communication sent to your addressee(s).</li>
            </ol>
            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>How do we process your information?</p>
            <p className={`mb-3 ${css(styles.description)}`}>
            In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.<br/>
We process your personal information for a variety of reasons, depending on how you interact with our Services.<br/>
We use the information that you have given us in for us to do the following:

            </p>
            <ol className={`mb-3 ${css(styles.description)}`}>
              <li>Personal Information. Qcast identifies the purpose for which your Personal Information is collected and will be used or disclosed. If that purpose is not listed below, we will identify any additional purposes for which we will collect your Personal Information, before or at the time of collection, and we will obtain your consent to collect, use or disclose your Personal Information for such additional purpose(s).</li>
              <li>By using the Qcast Products, you will be deemed to consent to our use of your Personal Information for the purposes of:<br/>
              <ul>
                <li>communicating with you generally;</li>
                <li>processing your purchases;</li>
                <li>processing and keeping track of transactions and reporting back to you;</li>
                <li>protecting against fraud or error;</li>
                <li>providing information or services requested by you;</li>
                <li>administering and managing the Qcast Products and our business operations;</li>
                <li>personalising your experience with the Qcast Site, as well as evaluating statistics on Qcast Site activity;</li>
                <li>performing statistical analyses of your behaviour and characteristics, in order to measure interest in and use of the various sections of the Qcast Site</li>
                <li>communicating with you on other websites</li>
                <li>delivery of content and information to Third Party Services Providers</li>
                <li>complying with legal and governmental requirements; and/or</li>
                <li>fulfilling any other purpose that would be reasonably apparent to the average person at the time that we collect it</li>
                <li>Sending you information about Qcast new products and launch dates (marketing purposes and marketing communications) </li>
              </ul>
              </li>
              <li>Users utilise Qcast Products to manage and deliver Content to Viewers. As part of this process, Qcast may collect Personal Information from you</li>
              <li>Otherwise, we will obtain your express consent (by verbal, written or electronic agreement) to collect, use or disclose your Personal Information. You can change your consent preferences at any time by contacting us (see the “How to Access, Change and Erase Your Personal Information” section below).</li>
              <li>Qcast extends the rights granted to “data subjects” under the General Data Protection Regulation (Regulation (EU) 2016/679) (the “GDPR”) to all of its Users. Consequently, you have the right to withdraw your consent to our processing of your Personal Information at any time (if our processing is based on consent) and the right to object to our processing of your Personal Information (if processing is based on legitimate interests).</li>
        
            </ol>
            <p className={`mb-3 ${css([styles.subHeading])}`}>Do we use cookies and other tracking technologies?</p>
            <p className={`mb-3 ${css(styles.description)}`}>In Short: We may use cookies and other tracking technologies to collect and store your information.<br/>

We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
</p>
<p className={`mb-3 ${css([styles.subHeading])}`}>How do we handle your social logins?</p>
            <p className={`mb-3 ${css(styles.description)}`}>In Short: If you choose to register or log in to our services using a social media account, we may have access to certain information about you.<br/>
Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.<br/>
We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services.<br/>
Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps

</p>
<p className={`mb-3 ${css([styles.subHeading])}`}>HOW TO ACCESS, CHANGE AND ERASE YOUR PERSONAL INFORMATION</p>
            <p className={`mb-3 ${css(styles.description)}`}>Upon request, Qcast will allow Users to update or correct Personal Information previously submitted. Additionally, upon request, Qcast will delete Personal Information from the database where such information is stored; however, it may be impossible to entirely delete a User’s entry without some residual information being retained due to the manner in which data backups are maintained. Requests to delete Personal Information may be submitted to unsubscribe@qcast.io.<br/>

Users also have the right to receive their Personal Information from us in a structured, commonly used and machine-readable format, and the right to transmit their Personal Information to another controller without hindrance from us (data portability).<br/>
<b>Email preferences</b><br/>
Qcast may use your Personal Information to send you emails periodically listing promotions or events relating to the Qcast Products. You have the choice to opt out of receiving such promotional emails by sending an email to privacy@qcast.io and/or following the instructions in such correspondence. Once Qcast has processed your opt-out request, Qcast will not send you promotional emails unless you opt back into receiving such communications.<br/>

Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are: <br/>
(a)	As necessary to fulfill our Qcast Terms of Use;
(b)	Consistent with your consent, which you may revoke at any time through the Qcast settings;
(c)	as necessary for our legitimate interests, including our interests in providing an innovative, safe and profitable service to our users and partners, unless those interests are overridden by your interests or fundamental rights and freedoms that require protection of personal data.<br/>
<b>How we store your personal information</b> <br/>

Your information may be transferred to and stored in locations outside the European Economic Area (EEA), including countries that may not have the same level of protection for personal information. When we do this, we’ll ensure it has an appropriate level of protection and that the transfer is lawful. We may need to transfer your information in this way to carry out our contract with you, to fulfil a legal obligation, to protect the public interest and/or for our legitimate interests.<br/>

 We store data until it is no longer necessary to provide our services and Qcast Products or until your account is deleted – whichever comes first. This is a case-by-case determination that depends on things such as the nature of the data, why it is collected and processed, and relevant legal or operational retention needs.<br/>

When you delete your account, we delete videos and information you have posted and you won't be able to recover that information later. Information that others have shared about you isn't part of your account and won't be deleted. To delete your account at any time, please visit Qcast website.<br/>

<b>Do we collect information from minors?</b><br/>
In Short: We do not knowingly collect data from or market to children under 18 years of age.<br/>
We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us on contact@qcast.io
</p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>4.	Your data protection rights</p>
            <p className={`mb-3 ${css(styles.description)}`}>
            Under data protection law, you have rights including:<br/>
              <ul>
              <li><b>Your right of access </b>- You have the right to ask us for copies of your personal information. </li>
              <li><b>Your right to rectification </b>- You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. </li>
              <li><b>Your right to erasure </b> - You have the right to ask us to erase your personal information in certain circumstances. </li>
              <li><b>Your right to restriction of processing</b> - You have the right to ask us to restrict the processing of your personal information in certain circumstances. </li>
              <li><b>Your right to object to processing</b> - You have the the right to object to the processing of your personal information in certain circumstances.</li>
              <li><b>Your right to data portability </b> - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
              <li>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.</li>

              </ul><br/>
              Please contact us at privacy@qcast.io if you wish to make a request.
              <br/>
              If you are located in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
              <br/>
              <b>Do California residents have specific privacy rights?</b>
              <br/>

In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
<br/>
California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.<br/>
If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).

            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>5.	Do we make updates to this notice?</p>
            <p className={`mb-3 ${css(styles.description)}`}>
            In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. 
<br/>
<b>How will we notify you of changes to this policy?</b>
<br/>
We'll notify you before we make changes to this Policy and give you the opportunity to review the revised Policy before you choose to continue using our Products. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.


            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>6.	How can you contact us about this notice?</p>
            <p className={`mb-3 ${css(styles.description)}`}>
            If you have questions or comments about this notice, you may email us at contact@qcast.io or by post to: Qcast Ltd, 7th Floor 155-157 Portsoken House Aldgate, England EC3N 1LJ
            <br/>
<b>How you can reach us</b>
<br/>
If you would like to ask about, make a request relating to, or complain about how we process your personal information, please mail us at one of the addresses below. 
<br/>
<b>How can you review, update, or delete the data we collect from you?</b><br/>
Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by emailing data@qcast.io.
<br/>
<b>Our contact details </b>
<br/>
Qcast Limited (Company Registration: 13403332)<br/>
155-157 Portsoken House, Aldgate<br/>
London<br/>
England<br/>
United Kingdom EC3N 1LJ <br/>
E-mail: privacy@qcast.io<br/>


            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>7.	How to complain</p>
            <p className={`mb-3 ${css(styles.description)}`}>If you have any concerns about our use of your personal information, you can make a complaint to us at data@qcast.io
<br/>
You can also complain to the ICO if you are unhappy with how we have used your data.
<br/>
The ICO’s address:<br/>            
Information Commissioner’s Office<br/>
Wycliffe House<br/>
Water Lane<br/>
Wilmslow<br/>
Cheshire<br/>
SK9 5AF<br/>
<br/>
Helpline number: 0303 123 1113<br/>
ICO website: https://www.ico.org.uk<br/><br/>

Date of privacy notice: 10 May 2022

</p>
          </div>
        </div>
      </section>
      <WebFooter />
    </div>
  );
}
