// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./PressStyles";
import { WebHeader, WebFooter } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";
import businessInsider from "../../assets/images/businessInsider/businessInsider.png";

export default function PressView(props) {
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.mainHeading)}>Press</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section2)}>
        <Container>
          <Row className={css(styles.mainView)}>
            <Col>
              <div className={css(styles.view)}>
                <img src={businessInsider} className={css(styles.img)} />
                <p className={css(styles.description)}>
                  Here are the answers to 11 of the toughest job interview
                  questions growing startups ask their candidates
                </p>
                <a href="#" className={css(styles.button)}>
                  Read Article
                </a>
              </div>
            </Col>

            <Col>
              <div className={css(styles.view)}>
                <img src={businessInsider} className={css(styles.img)} />
                <p className={css(styles.description)}>
                  Here are the answers to 11 of the toughest job interview
                  questions growing startups ask their candidates
                </p>
                <a href="#" className={css(styles.button)}>
                  Read Article
                </a>
              </div>
            </Col>

            <Col>
              <div className={css(styles.view)}>
                <img src={businessInsider} className={css(styles.img)} />
                <p className={css(styles.description)}>
                  Here are the answers to 11 of the toughest job interview
                  questions growing startups ask their candidates
                </p>
                <a href="#" className={css(styles.button)}>
                  Read Article
                </a>
              </div>
            </Col>

            <Col>
              <div className={css(styles.view)}>
                <img src={businessInsider} className={css(styles.img)} />
                <p className={css(styles.description)}>
                  Here are the answers to 11 of the toughest job interview
                  questions growing startups ask their candidates
                </p>
                <a href="#" className={css(styles.button)}>
                  Read Article
                </a>
              </div>
            </Col>

            <Col>
              <div className={css(styles.view)}>
                <img src={businessInsider} className={css(styles.img)} />
                <p className={css(styles.description)}>
                  Here are the answers to 11 of the toughest job interview
                  questions growing startups ask their candidates
                </p>
                <a href="#" className={css(styles.button)}>
                  Read Article
                </a>
              </div>
            </Col>

            <Col>
              <div className={css(styles.view)}>
                <img src={businessInsider} className={css(styles.img)} />
                <p className={css(styles.description)}>
                  Here are the answers to 11 of the toughest job interview
                  questions growing startups ask their candidates
                </p>
                <a href="#" className={css(styles.button)}>
                  Read Article
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.lastSection)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.lastSectionHeading)}>
                Download Assets
              </h1>
            </Col>
          </Row>

          <Row className={css(styles.lastSectionRow2)}>
            <Col lg="6" md="12">
              <h1 className={css(styles.lastSectionHeading2)}>
                Qcast logos and a usage guide.
              </h1>
            </Col>

            <Col lg="6" md="12">
              <a href="#" className={css(styles.downloadButton)}>
                Download Assets
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
