// @flow
import React from "react";
import {useState} from "react";
import { NavLink,Link } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./SateliteStyles";
import { WebHeader, WebFooter,VideoPlayer } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import satalite from "../../assets/data/satalite";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { ROUTES } from "../../constants";



export default function SateliteView(props) {
  const [sat, setSat]=useState(satalite[0]);
  let allCast=[]
  satalite.forEach(v=>{
   allCast=[...allCast,...v.data]
  });
  const rows = satalite.reduce(function (rows, key, index) { 
    return (index % 4 == 0 ? rows.push([key]) 
      : rows[rows.length-1].push(key)) && rows;
  }, []);
  let featuredData= rows.map((row,index) =>   ( 
      <Row key={index} >
      { row.map((item,index) => (majorForm(item,index))) }
      </Row>
    ))

  let allData=allCast.map((item,index)=>{
    return bigForm(item,index)
  })
  
 
  function majorForm(item,index){
    return (<Col lg={4} md={6} sm={12}><div key={index}
      className={css(styles.majorFormContainer)}
      >
        <div
        className={css(styles.majorFormImage)}
        ><img src={item.image} style={{
          width:210,borderRadius:"50%"
  
        }} /></div>
        <p className={css(styles.mainTitleFont)}>{item.name}</p>
        <p className={css(styles.descriptionFont)}>{item.description}</p>
        <p className={css(styles.genreTitleFont)} >Genre: {item.gene}</p>
        <Link to={ROUTES.SATELITE+"/"+item.tag} className={css(styles.majorFormLink)}>Watch</Link>
      </div></Col>)
  }
  function bigForm(item,index){
    return (
     <Col>
     <Link style={{textDecoration:"none"}} to={ROUTES.SATELITE+"/"+item.parent+"/"+item.tag}>
          <div
          key={index}
          style={
            {
             display:"flex",
             flexDirection:"row"
            }
          }>
            <div><img src={item.image} style={{width:80,borderRadius:"50%"}} /></div>
            <div style={{marginLeft:5}}><p className={css(styles.titleFont)}>{item.name}</p>
            <p className={css(styles.titleFont)}>{item.question}</p>
            
            
            </div>
          </div>
          </Link>
          </Col>
    )
  }
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section)}>
        <Container className={css(styles.customWidth)}>
        
         
          <p className={css(styles.subHeading)}>QCAST EXAMPLES</p>
          <p>These are Qcasts some of our customers have recorded to harness the power of their questions.
            <br/>
            Responses will be made through our mobile App.
          </p>
          <Row>
            {allData}
            
          </Row>
          <p className={css(styles.subHeading)}>QCAST</p>
          <div>
          {featuredData}
          </div>
           
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
