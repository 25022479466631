// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./CorporateSocialResponsibilityStyles";
import { WebHeader, WebFooter } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";

export default function CorporateSocialResponsibilityView(props) {
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.heading)}>
                Corporate social responsibility
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={css(styles.description)}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                at iaculis leo. Nam in eleifend dolor. Donec eleifend ultricies
                purus, vitae molestie dui lobortis vel. Suspendisse in nulla
                consequat, bibendum sapien et, elementum urna. Nam vel risus
                rhoncus, varius purus a, varius leo. Maecenas porttitor non eros
                sit amet hendrerit. Duis finibus maximus rutrum. Maecenas quis
                leo in ante imperdiet venenatis a nec lorem. Aliquam porta,
                purus at rutrum aliquam, felis metus placerat est, commodo
                ultricies lorem purus sit amet tellus.
              </p>
              <p className={css(styles.description)}>
                Mauris lobortis leo vel est laoreet tempor. Nulla dictum ligula
                tellus, quis consectetur dolor fermentum non. Vestibulum
                dapibus, odio eget placerat mattis, mauris libero varius velit,
                et rhoncus quam mi vitae massa. Morbi dapibus neque egestas
                risus tristique, eu fermentum urna lacinia. Donec ante elit,
                malesuada ac magna ut, finibus aliquet eros. Etiam egestas
                suscipit ultricies. Nam ac mollis diam.
              </p>
              <p className={css(styles.description)}>
                Vivamus condimentum erat eu dignissim ornare. Maecenas posuere
                dictum molestie. Suspendisse et sem vehicula libero scelerisque
                laoreet. Vestibulum fermentum fringilla metus, eu posuere
                tortor. Sed posuere porttitor ultricies. Nulla tristique arcu
                vel massa pellentesque ornare. Integer consequat ac sem in
                mollis.
              </p>
              <p className={css(styles.description)}>
                Pellentesque vitae mi est. Suspendisse fermentum sapien vel
                tellus luctus interdum. Duis a odio vitae erat ullamcorper
                accumsan. Sed sollicitudin risus felis, ac efficitur enim rutrum
                sed. Donec porttitor porta consequat. Class aptent taciti
                sociosqu ad litora torquent per conubia nostra, per inceptos
                himenaeos. Maecenas sit amet sapien vel nisl sagittis tincidunt
                quis id urna. Nulla fermentum, mauris in semper pretium, leo
                augue fringilla massa, gravida convallis orci est quis massa.
                Nunc condimentum sit amet arcu eu cursus. Aliquam eu ipsum
                rhoncus, mattis risus id, commodo eros. Cras nec nulla ligula.
                Etiam eleifend sed erat ac ullamcorper.
              </p>
              <p className={css(styles.description)}>
                Vivamus congue tellus eu interdum ornare. Cras nec commodo diam.
                Praesent nec elit lacus. In vel enim non purus porttitor
                gravida. Aliquam facilisis, odio in dapibus semper, sapien ipsum
                auctor erat, sed gravida magna dui facilisis sapien. Maecenas
                sem dolor, viverra in dapibus et, semper et quam. Fusce pulvinar
                tincidunt metus, eu facilisis massa consectetur suscipit. Nullam
                sollicitudin tincidunt quam. Vestibulum ante ipsum primis in
                faucibus orci luctus et ultrices posuere cubilia curae; Nam id
                faucibus nulla. Sed sed tortor ac libero luctus varius at eget
                dolor. Fusce laoreet tempor ante eget egestas. Aenean non ipsum
                orci. Maecenas porttitor enim eget ex elementum, quis volutpat
                elit rutrum. Ut velit dui, ultrices ut quam mattis, efficitur
                eleifend orci.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
