// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";
import RightArrow from "../../assets/images/sliderRightArrowIcon/sliderRightArrowIcon.png";
import LeftArrow from "../../assets/images/sliderLeftArrowIcon/sliderLeftArrowIcon.png";

export default StyleSheet.create({
  section:{
    background: 'linear-gradient(171.89deg, #F0F6FB 2.42%, #E3F1FE 96.87%)',
    paddingTop:"20px"
  },
  customWidth: {
    maxWidth: 1120,
    minHeight: 500,
    paddingTop:"25px"
  },
  vid:{
    width:'250px',
    height:'250px',
  },subHeading: {
    fontSize: 20,
    textAlign: "center",
    color: "#6497C7",
    fontFamily: "Poppins",
    fontWeight: "600",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },download:{
    color: "#6497C7",
    

  },
  heading: {
    fontSize: 75,
    color: "#FF0000",
    fontWeight: "700",
    fontFamily: "Poppins",
    textAlign: "center",
    "@media (max-width: 767px)": {
      fontSize: 50,
    },
    "@media (max-width: 400px)": {
      fontSize: 38,
    },
  }, nextArrow: {
    background: "transparent",

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${RightArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -26,
      right: 26,
    },"@media (max-width: 1200px)": {
      ":before": {
        right: 26,
      }
    },
    "@media (max-width: 900px)": {
      ":before": {
        right: 50,
      }
    },
    "@media (max-width: 650px)": {
      ":before": {
        right: 10,
      }
    },
  },

  prevArrow: {
    background: "transparent",
    zIndex: 9999,

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${LeftArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -32,
      left: 26,
    },"@media (max-width: 1200px)": {
      ":before": {
        left: 26,
      }
    },
    "@media (max-width: 900px)": {
      ":before": {
        left: 50,
      }
    },

    "@media (max-width: 650px)": {
      ":before": {
        left: 10,
      }
    },
  },
});
