// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./SitemapStyles";
import { WebHeader, WebFooter } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";
import { ROUTES } from "../../constants";
import Tooltip from '@material-ui/core/Tooltip';
export default function SitemapView(props) {
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.heading)}>Sitemap</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section2)}>
        <Container>
          <Row className={css(styles.listMainView)}>
            <Col lg="3">
              <ul className={css(styles.listView)}>
                <li className={css(styles.listHeading)}>Discover</li>
                <li className={css(styles.list)}>
                  <NavLink to={ROUTES.FAQ} className={css(styles.listText)}>
                    FAQs
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink to={ROUTES.PRICING} className={css(styles.listText)}>
                    Pricing Page
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink
                    to={ROUTES.DOWNLOAD}
                    className={css(styles.listText)}
                  >
                    Download
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink
                    to={ROUTES.USES_CASES}
                    className={css(styles.listText)}
                  >
                    Use Cases
                  </NavLink>
                </li>
                {/* <li className={css(styles.list)}>
                  <NavLink to={ROUTES.PORTAL} className={css(styles.listText)}>
                    Portal
                  </NavLink>
                </li> */}
              </ul>
            </Col>

            <Col lg="3">
              <ul className={css(styles.listView)}>
                <li className={css(styles.listHeading)}>Company</li>
                <li className={css(styles.list)}>
                  <NavLink to={ROUTES.TEAM} className={css(styles.listText)}>
                    Team
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink to={ROUTES.ABOUT} className={css(styles.listText)}>
                    About
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink to={ROUTES.CONTACT} className={css(styles.listText)}>
                    Contact
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink to={{pathname:ROUTES.BLOG}} target="_blank" className={css(styles.listText)}>
                    Blog
                  </NavLink>
                </li>
               {/*  <li className={css(styles.list)}>
                  <NavLink to={ROUTES.CAREER} className={css(styles.listText)}>
                    Careers
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink to={ROUTES.PRESS} className={css(styles.listText)}>
                    Press
                  </NavLink>
                </li> */}
              </ul>
            </Col>

            <Col lg="3">
              <ul className={css(styles.listView)}>
                <li className={css(styles.listHeading)}>Support</li>
                <li className={css(styles.list)}>
                  <NavLink to={ROUTES.SITEMAP} className={css(styles.listText)}>
                    Sitemap
                  </NavLink>
                </li>
                {/* <li className={css(styles.list)}>
                  <NavLink
                    to={ROUTES.SUSTAINABILITY_AND_ENVIRONMENT}
                    className={css(styles.listText)}
                  >
                    Sustainability and environment
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink
                    to={ROUTES.CORPORATE_SOCIAL_RESPONSIBILITY}
                    className={css(styles.listText)}
                  >
                    Corporate social responsibility
                  </NavLink>
                </li> */}
                {/* <li className={css(styles.list)}>
                  <NavLink
                    to={ROUTES.USE_OF_API}
                    className={css(styles.listText)}
                  >
                    Use of API
                  </NavLink>
                </li> */}
              </ul>
            </Col>

            <Col lg="3">
              <ul className={css(styles.listView)}>
                <li className={css(styles.listHeading)}>Social</li>
                <li className={css(styles.list)}>
                  <a
                    href="https://www.facebook.com/QcastQuestions"
                    target="_blank"
                    className={css(styles.listText)}
                  >
                    Facebook
                  </a>
                </li>
                <li className={css(styles.list)}>
                  <a
                    href="https://www.instagram.com/qcastquestions/"
                    target="_blank"
                    className={css(styles.listText)}
                  >
                    Instagram
                  </a>
                </li>
                <li className={css(styles.list)}>
                  <a
                    href="https://twitter.com/QcastQuestions"
                    target="_blank"
                    className={css(styles.listText)}
                  >
                    Twitter
                  </a>
                </li>
                <li className={css(styles.list)}>
                  <a
                    href="https://www.linkedin.com/company/qcastquestions"
                    target="_blank"
                    className={css(styles.listText)}
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </Col>

            <Col lg="3">
              <ul className={css(styles.listView)}>
                <li className={css(styles.listHeading)}>Privacy Policy</li>
                <li className={css(styles.list)}>
                  <NavLink
                    to={ROUTES.TERMS_AND_CONDITION}
                    className={css(styles.listText)}
                  >
                    Terms & Conditions
                  </NavLink>
                </li>
                <li className={css(styles.list)}>
                  <NavLink
                    to={ROUTES.PRIVACY_POLICY}
                    className={css(styles.listText)}
                  >
                    Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </Col>

            <Col lg="3">
              <ul className={css(styles.listView)}>
                <li className={css(styles.listHeading)}>Download</li>
                <li className={css(styles.list)}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.qcast" target="_blank"
                    className={css(styles.listText)}
                  >
                    Google play app
                  </a>
                </li>
                <li className={css(styles.list)}>
                  <a
                     href="https://apps.apple.com/app/qcast/id1583558024" target="_blank"
                    className={css(styles.listText)}
                  >
                    Apple store app
                  </a>
                </li>
                <li className={css(styles.list)}>
                  <a href={ROUTES.DOWNLOAD} className={css(styles.listText)}>
                    Plugin
                  </a>
                </li>
                <li className={css(styles.list)}>
                  <a href={ROUTES.PRICING} className={css(styles.listText)}>
                    Platform
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
