// @flow
import React from "react";
import {useState} from "react";
import { NavLink,Link } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./SateliteMenuStyles";
import { WebHeader, WebFooter,VideoPlayer } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import satalite from "../../assets/data/satalite";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { ROUTES } from "../../constants";



export default function SateliteMenuView(props) {
  const [sat, setSat]=useState(satalite[0]);
  const { videoProp} = props;
  const rows = videoProp.data.reduce(function (rows, key, index) { 
    return (index % 4 == 0 ? rows.push([key]) 
      : rows[rows.length-1].push(key)) && rows;
  }, []);

  let featuredData= rows.map((row,index) =>   ( 
      <Row key={index} >
      { row.map((item,index) => (majorForm(item,index))) }
      </Row>
    ))
  
 
  function majorForm(item,index){
    return (<Col><div key={index}
      className={css(styles.majorFormContainer)}
      >
        <div
        className={css(styles.majorFormImage)}
        ><img src={item.image} style={{
          width:210,borderRadius:"50%"
  
        }} /></div>
        <p className={css(styles.mainTitleFont)}>{item.name}</p>
        <p className={css(styles.mainTitleFont)}>{item.question}</p>
        <p className={css(styles.descriptionFont)}>{item.description}</p>
        <p className={css(styles.genreTitleFont)} >Genre: {videoProp.gene}</p>
        <Link to={ROUTES.SATELITE+"/"+videoProp.tag+"/"+item.tag} className={css(styles.majorFormLink)}>Watch</Link>
      </div></Col>)
  }
 
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section)}>
      <ul className="steps">
        <li className="step">
          <Link to={ROUTES.HOME}>
            <i className="fa fa-home" aria-hidden="true"></i>
          </Link>
        </li>
        <li className="step">
          <Link to={ROUTES.SATELITE} >
            Qcast Examples
          </Link>
        </li>
        <li className="step">
          <a>
           {videoProp.name}
          </a>
        </li>
      </ul>
        <Container className={css(styles.customWidth)}>
          <p className={css(styles.subHeading)}>QCAST SERIES</p>
           {featuredData}
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
