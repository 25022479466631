// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";
import backgroundImage from "../../assets/images/about-image-2/about-image-2.png";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 60,
  },

  mainHeading: {
    fontSize: 48,
    color: "#23517B",
    fontWeight: "700",
    fontFamily: "Poppins",

    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 38,
    },
  },

  section2: {
    marginBottom: 30,
  },

  mainView: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },

  view: {
    background:
      "radial-gradient(315.18% 315.18% at -124.11% -101.79%, #B9CCE2 19%, #FFFFFF 77.71%)",
    boxShadow:
      "-10px -6px 32px rgba(255, 255, 255, 0.6), 6px 5px 24px rgba(112, 139, 163, 0.28)",
    borderRadius: "50%",
    width: 341,
    height: 341,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 45,
    margin: "auto",
    marginTop: 40,
    marginBottom: 40,
    "@media (max-width: 365px)": {
      width: "100%",
    },
  },

  img: {
    margin: "auto",
    marginTop: 0,
    marginBottom: 0,
    display: "flex",
  },

  description: {
    color: "#7B94B5",
    fontSize: 18,
    fontWeight: "600",
    fontFamily: "Poppins",
    textAlign: "center",
    lineHeight: "1.8",
    marginTop: 10,
  },

  button: {
    color: "#6497C7",
    fontWeight: "700",
    fontSize: 18,
    fontFamily: "Poppins",
    textAlign: "center",
    display: "block",
    textDecoration: "underline",
  },

  lastSection: {
    marginTop: 60,
    paddingTop: 50,
    paddingBottom: 60,
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
  },

  lastSectionHeading: {
    color: "#6497C7",
    fontWeight: "700",
    fontSize: 50,
    fontFamily: "Poppins",
    "@media (max-width: 600px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  lastSectionRow2: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },

  lastSectionHeading2: {
    color: "#515A6B",
    fontSize: 32,
    fontWeight: "600",
  },

  downloadButton: {
    width: "100%",
    background: "#97AAC3",
    color: "#fff",
    fontSize: 20,
    display: "block",
    paddingTop: 13,
    paddingBottom: 13,
    textAlign: "center",
    borderRadius: 100,
    textDecoration: "none",
    fontWeight: "700",
  },
});
