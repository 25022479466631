import React from 'react'
import { css } from 'aphrodite'
import { IMAGES } from '../../assets/images/path'

import style from './styles'
export const DownloadButtons = () => {
    return (
        <div className={css(style.view)}>
            <a
                href="https://play.google.com/store/apps/details?id=com.qcast"
                target="_blank">
                <img
                    src={IMAGES.DOWNLOAD_APP.GOOGLE_STORE}
                    style={{ marginRight: 10 }}
                    width="142px"
                />
            </a>
            <a
                href="https://apps.apple.com/app/qcast/id1583558024"
                target="_blank">
                <img
                    src={IMAGES.DOWNLOAD_APP.APPLE_STORE}
                    width="145px"
                    style={{ marginTop: '4px' }}
                />
            </a>
        </div>
    )
}
