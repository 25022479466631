// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 80,
    "@media (max-width: 980px)": {
      marginTop: 40,
    },
  },

  heading: {
    fontFamily: "Poppins",
    fontSize: 48,
    fontWeight: "700",
    color: "#23517B",
    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  description: {
    fontSize: 20,
    color: "#000",
    fontFamily: "Roboto",
    marginTop: 20,
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 300px)": {
      fontSize: 18,
    },
  },

  section2: {
    marginBottom: 120,
    "@media (max-width: 980px)": {
      marginBottom: 60,
    },
  },

  textInputHeading: {
    color: "#6497C7",
    fontFamily: "Poppins",
    fontSize: 42,
    fontWeight: "700",
    marginBottom: 30,
    marginTop: 60,
    "@media (max-width: 500px)": {
      fontSize: 36,
    },
    "@media (max-width: 400px)": {
      fontSize: 34,
    },
  },

  textInput: {
    width: "85%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 17,
    paddingRight: 17,
    marginTop: 15,
    borderRadius: 10,
    background: "#EFF6FC",
    border: "none",
    boxShadow:
      "inset -2px -5px 7px rgba(255, 255, 255, 0.7), inset 2px 5px 8px rgba(0, 0, 0, 0.25)",
    fontSize: 18,
    color: "#7B94B5",
    fontWeight: "500",
    fontFamily: "Poppins",
    "@media (max-width: 600px)": {
      width: "100%",
    },
    ":focus": {
      outline: "none",
    },
  },

  textArea: {
    width: "85%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 17,
    paddingRight: 17,
    marginTop: 15,
    borderRadius: 10,
    background: "#EFF6FC",
    border: "none",
    boxShadow:
      "inset -2px -5px 7px rgba(255, 255, 255, 0.7), inset 2px 5px 8px rgba(0, 0, 0, 0.25)",
    fontSize: 18,
    color: "#7B94B5",
    fontWeight: "500",
    height: 300,
    fontFamily: "Poppins",
    "@media (max-width: 600px)": {
      width: "100%",
    },
    ":focus": {
      outline: "none",
    },
  },

  submitButton: {
    width: "85%",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 17,
    paddingRight: 17,
    marginTop: 15,
    borderRadius: 100,
    background: "Linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    border: "none",
    boxShadow:
      "1px -3px 10px rgba(255, 255, 255, 0.7), 5px 3px 10px rgba(0, 0, 0, 0.18)",
    fontSize: 18,
    color: "#7B94B5",
    fontWeight: "700",
    fontFamily: "Poppins",
    "@media (max-width: 600px)": {
      width: "100%",
    },
    ":focus": {
      outline: "none",
    },
  },

  informationView: {
    display: "flex",
    alignItems: "center",
  },

  informationText: {
    fontSize: 20,
    fontFamily: "Poppins",
    color: "#515A6B",
    marginLeft: 20,
    textDecoration: "none",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 500px)": {
      fontSize: 18,
    },
  },
});
