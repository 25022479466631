// @flow
import React from "react";
import SitemapView from "./SitemapView";

class SitemapController extends React.Component {
  static propTypes = {};

  render() {
    return <SitemapView {...this.props} />;
  }
}

export default SitemapController;
