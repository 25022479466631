// @flow
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./DownloadStyles";
import { WebHeader, WebFooter } from "../../components";
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";
import googlePlayIcon from "../../assets/images/googlePlayIcon/googlePlayIcon@3x.png";
import appleStoreIcon from "../../assets/images/appleStoreIcon/appleStoreIcon@3x.png";
import downloadImg from "../../assets/images/downloadImg/downloadImg.png";
import downloadImg2 from "../../assets/images/downloadImg/downloadImg2.png";
import Tooltip from '@material-ui/core/Tooltip';

import { ROUTES } from "../../constants";

export default function DownloadView(props) {
  
  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.qcast";
    }else if(isIOS) {
      window.location.href =
        "https://apps.apple.com/app/qcast/id1583558024";
    }
  }, []);
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.heading)}>Download Qcast</h1>
            </Col>
          </Row>

          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>Qcast for phones</h1>
              <p className={`${css(styles.description)} mt-3`}>
                Qcast is a platform dedicated to one of humanity’s most
                important means of communication, Questions.
              </p>
              <p className={`${css(styles.description)} mt-5`}>
                Qcast means ‘Question Cast’ and is a new digital media format
                created to help you broadcast catalytic, open-ended,
                transformative questions.
              </p>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.qcast" target="_blank" >
                  <img
                    src={googlePlayIcon}
                    style={{ marginRight: 10 }}
                    width="142px"
                  />
                </a>
                <a  href="https://apps.apple.com/app/qcast/id1583558024" target="_blank">
                  <img src={appleStoreIcon} width="142px" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section2)}>
        <Container>
          <Row className={css(styles.section2View)}>
            <Col lg="4" md="12">
              <img src={downloadImg2} className={css(styles.img)} />
            </Col>

            <Col lg="8" md="12">
              <h1 className={css(styles.subHeading)}>Qcast Plugin for desktops</h1>
              <p className={`${css(styles.section2Description)} mt-3`}>
                Qcast will help organisations to better serve their clients
                through a simple, efficient, fast and immersive way to engage
                with the questions and answers which are intrinsic to our life
                and workplaces.
              </p>
              <NavLink to={ROUTES.PRICING} className={css(styles.downloadLink)}>
                Install Plugin
              </NavLink>
            </Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
