// @flow
import React from "react";
import SateliteMenuView from "./SateliteMenuView";
import satalite from "../../assets/data/satalite";
import { ROUTES } from "../../constants";
import { Redirect } from "react-router-dom";

class SateliteMenuController extends React.Component {
  static propTypes = {};

  render() {
    let pageData=satalite.filter(v=>v.tag===this.props.match.params.id);
    if(pageData.length>0){
      return <SateliteMenuView {...this.props} videoProp={pageData[0]}/>;
    }else{
      return <Redirect to={ROUTES.SATELITE} />
    }
  }
}

export default SateliteMenuController;
