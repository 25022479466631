// @flow
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./UsesCasesStyles";
import { WebHeader, WebFooter,VideoPlayer } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";
import image from "../../assets/images/useCasesImg/useCasesImg.png";
import tim_intro from '../../assets/video/tim_intro.mp4';
import tim_q1 from '../../assets/video/tim_q1.mp4';
import tim_q2 from '../../assets/video/tim_q2.mp4';
import timPoster from '../../assets/video/timPoster.png';
import mark_intro from '../../assets/video/mark_intro.mp4';
import mark_q1 from '../../assets/video/mark_q1.mp4';
import mark_q2 from '../../assets/video/mark_q2.mp4';
import markPoster from '../../assets/video/markPoster.png';
import alton_intro from '../../assets/video/alton_intro.mp4';
import alton_q1 from '../../assets/video/alton_q1.mp4';
import alton_q2 from '../../assets/video/alton_q2.mp4';
import altonPoster from '../../assets/video/altonPoster.png';
import juli_intro from '../../assets/video/juli_intro.mp4';
import juli_q1 from '../../assets/video/juli_q1.mp4';
import juli_q2 from '../../assets/video/juli_q2.mp4';
import juliPoster from '../../assets/video/juliPoster.png';
import davis_intro from '../../assets/video/davis_intro.mp4';
import davis_q1 from '../../assets/video/davis_q1.mp4';
import davis_q2 from '../../assets/video/davis_q2.mp4';
import davisPoster from '../../assets/video/davisPoster.png';
import Slider from "react-slick";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${css(styles.nextArrow)}`}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${css(styles.prevArrow)}`}
      onClick={onClick}
    />
  );
}

export default function UsesCasesView(props) {
  const settings = {
    customPaging:function(i){
      return (
        <span>{i+1} of 3</span>
      )
    },
    dots: true,
    infinite: false,
    slidesToShow:1,
    className: 'slick-use-case-player',
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },

      {
        breakpoint: 830,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "40px",
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.heading)}>Use Cases</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section2)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>Qcast for Business and Thought Leaders</h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
           

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
              Founder and CEO of strategic design firm <a href="https://eightinc.com" target="_blank"> Eight Inc</a>, Designer and Author <b>Tim Kobe</b> uses Qcast to share engaging insightful questions from thirty plus years creating Human Design experiences for clients such as Apple, Virgin, Tesla and many more.

              </p>
              <p className={css(styles.description)}>
              Have a look at the intro and first two questions from his Return on Experience Qcast.
              </p>
            </Col>
            <Col lg="4" md="12">
            <Slider {...settings}>
            <div className={css(styles.img)}>
              <VideoPlayer poster={timPoster} src={tim_intro} id="tim_intro"  />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={timPoster} src={tim_q1} id="tim_q1"  />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={timPoster}  src={tim_q2} id="tim_q2"  />
              </div>
          
            </Slider>
              
            
              {/* <img src={image} className={css(styles.img)} /> */}
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section3)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>Qcast for Influencers and Authors </h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
            

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
              Celebrity Barber, Author and Influencer <b>Mark Maciver</b>, of  <a href="https://www.slidercuts.com" target="_blank"> Slidercuts</a>, uses Qcast to promote his book ‘Shaping Up Culture’ and engage with his readers by sharing entrepreneurial questions from each of the chapters of his book, giving his readers an immersive experience when they record responses to his questions.

              </p>
              <p className={css(styles.description)}>
              Have a look at the intro and first two questions from his Shaping Up Culture Qcast.
              </p>
            </Col>
            <Col lg="4" md="12">
            <Slider {...settings}>
            <div className={css(styles.img)}>
              <VideoPlayer src={mark_intro} id="mark_intro"  />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer src={mark_q1}  id="mark_q1" />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer src={mark_q2}  id="mark_q2" />
              </div>
            </Slider>
            
              {/* <img src={image} className={css(styles.img)} /> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className={css(styles.section3)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>Qcast for Musicians and Filmmakers </h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
           

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
              Musician and filmmaker <b><Link to={{pathname:"https://twitter.com/ashirahsounds"}}  target="_blank">Sherry Davis</Link></b>  uses Qcast to share questions inspired by her thought provoking music and documentaries. In her Qcast titled ‘What is your untold story?’ Sherry connects with her audience by sharing deep philosophical questions about heritage, redemption and family legacy.

              </p>
              <p className={css(styles.description)}>
              Have a look at three questions from Sherry’s Qcast <b>What is your untold story?</b>.
              </p>
            </Col>
            <Col lg="4" md="12" >
              <Slider {...settings}>
            <div className={css(styles.img)}>
              <VideoPlayer poster={davisPoster} src={davis_intro} id="davis_intro"   />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={davisPoster} src={davis_q1}   id="davis_q1"/>
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={davisPoster} src={davis_q2}   id="davis_q2"/>
              </div>
            </Slider>
            
           
              {/* <img src={image} className={css(styles.img)} /> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className={css(styles.section3)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>Qcast for Teachers and Educators </h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
           

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
              Head Teacher and proprietor of <a href="https://riseeducation.org.uk/" target="_blank">RISE Education Independent school</a>, <b>Alton McDonald</b>, uses his Qcast titled ‘How can Change Theory help students in a school environment?’ to ask parents about the education services his school provides in order to help him and his team gain valuable insights and engage better with his students.


 
              

              </p>
              <p className={css(styles.description)}>
              Watch the intro and first two questions from his ‘How can Change Theory help students in a school environment? Qcast’. Click the play button to begin.
              </p>
            </Col>
            <Col lg="4" md="12">
            <Slider {...settings}>
            <div className={css(styles.img)}>
              <VideoPlayer poster={altonPoster} src={alton_intro}  id="alton_intro" />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={altonPoster} src={alton_q1}  id="alton_q1" />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={altonPoster} src={alton_q2}  id="alton_q2" />
              </div>
            </Slider>
            
              {/* <img src={image} className={css(styles.img)} /> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className={css(styles.section3)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>Qcast for Fitness, Wellness, and Mental Health Specialists </h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
            

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
              Corrective exercise, fitness and wellness specialist  <b>Juli Mey</b>, of Body and Soul Lifestyle, uses Qcast to share questions from her 18 year experience to help people understand how to prevent illnesses and injuries. In her Qcast titled ‘Where is your inner wellbeing?’ Juli engages with her clients, and potential clients, through a series of health related questions to help people understand more about their personal physical, spiritual, emotional and mental wellbeing.

              </p>
              <p className={css(styles.description)}>
              Have a look at the intro and first two questions from Juli’s Qcast <b>Where is your inner wellbeing?</b>.
              </p>
            </Col>
            <Col lg="4" md="12">
            <Slider {...settings}>
            <div className={css(styles.img)}>
              <VideoPlayer poster={juliPoster} src={juli_intro}   id="juli_intro" />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={juliPoster} src={juli_q1}   id="juli_q1" />
              </div>
              <div className={css(styles.img)}>
              <VideoPlayer poster={juliPoster} src={juli_q2}   id="juli_q2" />
              </div>
            </Slider>
              {/* <img src={image} className={css(styles.img)} /> */}
            </Col>
          </Row>
        </Container>
      </section>
      
      {/* <section className={css(styles.section3)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>
                Qcast For Music Artists
              </h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
            <Col lg="4" md="12">
              <img src={image} className={css(styles.img)} />
            </Col>

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
                Log in to Qcast Portal. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Mauris aliquet nec tellus nullam posuere mauris
                accumsan amet, tortor. Tellus sed arcu feugiat libero venenatis
                aliquet diam.
              </p>
              <p className={css(styles.description)}>
                Log in to Qcast Portal. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Mauris aliquet nec tellus nullam posuere mauris
                accumsan amet, tortor. Tellus sed arcu feugiat libero venenatis
                aliquet diam.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section3)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>Qcast For Authors</h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
            <Col lg="4" md="12">
              <img src={image} className={css(styles.img)} />
            </Col>

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
                Log in to Qcast Portal. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Mauris aliquet nec tellus nullam posuere mauris
                accumsan amet, tortor. Tellus sed arcu feugiat libero venenatis
                aliquet diam.
              </p>
              <p className={css(styles.description)}>
                Log in to Qcast Portal. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Mauris aliquet nec tellus nullam posuere mauris
                accumsan amet, tortor. Tellus sed arcu feugiat libero venenatis
                aliquet diam.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={css(styles.section3)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.subHeading)}>
                Qcast For Organisations
              </h1>
            </Col>
          </Row>

          <Row className={css(styles.section2Row2)}>
            <Col lg="4" md="12">
              <img src={image} className={css(styles.img)} />
            </Col>

            <Col lg="8" md="12">
              <p className={css(styles.description)}>
                Log in to Qcast Portal. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Mauris aliquet nec tellus nullam posuere mauris
                accumsan amet, tortor. Tellus sed arcu feugiat libero venenatis
                aliquet diam.
              </p>
              <p className={css(styles.description)}>
                Log in to Qcast Portal. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Mauris aliquet nec tellus nullam posuere mauris
                accumsan amet, tortor. Tellus sed arcu feugiat libero venenatis
                aliquet diam.
              </p>
            </Col>
          </Row>
        </Container>
      </section> */}

      <WebFooter />
    </div>
  );
}
