// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Fonts, Images } from "../../theme";
import "./Landing.scss"
export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
    paddingBottom: 30,
  },

  view: {
    paddingTop: 10,
    paddingBottom: 30,
    height: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 980px)": {
      textAlign: "center",
      marginTop: 20,
    },
  },

  logo: {
    width: 190,
    margin:"auto",
    "@media (max-width: 980px)": {
      margin: "auto",
    },
  },
  viewDesktop:{
    display:"flex",
    flexDirection: "column",
    "@media (max-width: 600px)": {
      display: "none",
    }
  },
  viewMobile:{
    display:"none",
    "@media (max-width: 600px)": {
      display:"flex",
      flexDirection: "column",
    }
  },
  heading: {
    fontSize: 35,
    color: "#23517B",
    lineHeight: 1.3,
    fontFamily: "Poppins",
    fontWeight: "700",
    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  heading2: {
    fontSize: 48,
    color: "#6497C7",
    lineHeight: 1.3,
    fontWeight: "700",
    fontFamily: "Poppins",
    "@media (max-width: 500px)": {
      fontSize: 40,
      margin:"20px 0px"
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  para: {
    fontSize: 20,
    color: "#515A6B",
    marginTop: 5,
    fontFamily: "Poppins",
    lineHeight: 1.5,
    "@media (max-width: 400px)": {
      fontSize: 20,
    },
  },

  heroSectionPara: {
    fontSize: 28,
    color: "#515A6B",
    fontWeight: 700,
    marginTop: "5px",
    fontFamily: "Poppins",
    lineHeight: 1.5,
    width: "80%",
    "@media (max-width: 980px)": {
      width: "100%",
    },
    "@media (max-width: 430px)": {
      fontSize: 24,
      // paddingBottom:30
    },
  },

  tagline: {
    color: "#97AAC3",
    fontSize: 20,
    fontWeight: "700",
    marginBottom: -6,
    fontFamily: "Poppins",
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },

  image1View: {
    position: "relative",
    height: 350,
    marginTop: "auto",
    marginBottom: "auto",
    "@media (max-width: 980px)": {
      height: "200px",
      textAlign:"center",
      left:-150
    },
  },
  laptop:{
    position: "absolute",
    width:"100%",
    
    "@media (max-width: 980px)": {
      width: "300px",
    }
  },
  iPad:{
    position: "absolute", 
    width:"300px",
    bottom:-50,
    right:-100,
    "@media (max-width: 980px)": {
      width: "150px",
      right:0,
      bottom:0
    }
  },
  phone:{
    position: "absolute",
    width: "140px",
    bottom:-45,
    zIndex:1,
    "@media (max-width: 980px)": {
      width: "50px",
      bottom:0
    }
  },
  
  image1Style: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    // margin-left: -70px !important;
    marginLeft: -70,
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "50%",
      display: "flex",
      marginLeft: 0,
      margin: "auto",
    },
  },

  image2View: {
    position: "relative",
    height: 680,
    "@media (max-width: 980px)": {
      height: "auto",
      marginTop: 30,
      left:-30
    },
  },
  image2StyleContainer:{
    "@media (max-width: 980px)": {
      position: "relative",
      width: "350px",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  image2StyleInner:{
    position: "absolute",
    height:"400px",
    width:"auto",
    right: -20,
    top: 0,
    bottom: -350,
    margin: "auto",
    "@media (max-width: 980px)": {
      position: "absolute",
      textAlign: "center",
      height:"200px",
      width:"auto",
      display: "flex",
      bottom:-200,
      
    },
  },
  image2Style: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "350px",
      display: "flex",
    },
  },

  section2Col2: {
    paddingLeft: 40,
    "@media (max-width: 980px)": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },

  image3Style: {
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "50%",
      display: "flex",
      margin: "auto",
    },
  },

  section3Col2: {
    paddingLeft: 40,
    "@media (max-width: 980px)": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },

  image4View: {
    height: 800,
    position: "relative",
    paddingRight: 70,
    "@media (max-width: 980px)": {
      height: "auto",
      paddingRight: 15,
    },
  },

  section4Col2: {
    paddingLeft: 40,
    "@media (max-width: 980px)": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },

  image4Style: {
   // position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "50%",
      display: "flex",
      marginRight: 0,
      margin: "auto",
    },
  },

  image5View: {
    height: 600,
    position: "relative",
    "@media (max-width: 980px)": {
      height: "620px",
      justifyContent: "center",
      display: "flex",
    },
  },
  touchResponse:{
    position:"absolute",
    zIndex: 1,
    height: "400px",
    top: "20px",
    left: "-50px",
    "@media (max-width: 980px)": {
      left: "50px",
      
    },
  },
  touch:{
   position:"absolute"
  },
  image5Style: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "50%",
      display: "flex",
    },
  },

  section5Col2: {
    paddingLeft: 40,
    "@media (max-width: 980px)": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },

  customRowStyle: {
    flexDirection: "row-reverse",
    "@media (max-width: 980px)": {
      flexDirection: "unset",
    },
  },
  getStartedText:{
    fontSize:'14px',
    fontWeight:"bold",
    color: "#515A6B",
    paddingTop:30
  }
});
