// @flow
import React from "react";
import SateliteView from "./SateliteView";

class SateliteController extends React.Component {
  static propTypes = {};

  render() {
    return <SateliteView {...this.props} />;
  }
}

export default SateliteController;
