// @flow
import React from "react";
import TeamView from "./TeamView";


class TeamController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderOne: null,
      sliderTwo: null,
    };
  }

  componentDidMount() {
    this.setState({
      sliderOne: this.slider1,
      sliderTwo: this.slider2,
    });
  }

  static propTypes = {};

  render() {
    return (
      <TeamView
        {...this.props}
        sliderOne={this.state.sliderOne}
        sliderTwo={this.state.sliderTwo}
        slider1Ref={(ref) => {
          this.slider1 = ref;
        }}
        slider2Ref={(ref) => {
          this.slider2 = ref;
        }}
      />
    );
  }
}

export default TeamController;
