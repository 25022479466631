// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./BlogStyles";
import { WebHeader, WebFooter } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";

export default function BlogView(props) {
  return (
    <div>
      <WebHeader />

      <section>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
