// @flow
import React from "react";
import DownloadView from "./DownloadView";

class DownloadController extends React.Component {
  static propTypes = {};

  render() {
    return <DownloadView {...this.props} />;
  }
}

export default DownloadController;
