import APPLE_STORE from './appleStoreIcon/App Store.png'
import GOOGLE_STORE from './googlePlayIcon/Google Play.png'
import FB from './SocialMediaIcons/thumb_Social Icons - FB.png'
import INSTA from './SocialMediaIcons/thumb_Social Icons - Instagram.png'
import LINKED_IN from './SocialMediaIcons/thumb_Social Icons - LinkedIn.png'
import TWITTER from './SocialMediaIcons/thumb_Social Icons - Twitter.png'

export const IMAGES = {
    DOWNLOAD_APP: {
        APPLE_STORE,
        GOOGLE_STORE,
    },
    SOCIAL_MEDIA_ICONS: {
        FB,
        INSTA,
        LINKED_IN,
        TWITTER,
    },
}
