// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 70,
    marginBottom: 40,
    "@media (max-width: 98px)": {
      marginTop: 40,
    },
  },

  heading: {
    fontSize: 48,
    color: "#23517B",
    fontWeight: "700",
    fontFamily: "Poppins",

    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 38,
    },
  },

  description: {
    color: "#515A6B",
    fontFamily: "Poppins",
    fontSize: 20,
    marginTop: 30,

    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },
});
