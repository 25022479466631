// @flow
import React from 'react'
import { NavLink } from 'react-router-dom'
import { css } from 'aphrodite'
import styles from './AboutStyles'
import { WebHeader, WebFooter } from '../../components'
import { Images, AppStyles, Colors } from '../../theme'
import { Container, Row, Col } from 'react-bootstrap'
import image0 from '../../assets/images/about-image-1/Why Qcast_.png'
import image1 from '../../assets/images/about-image-1/about-image-1-new.png'
import image3 from '../../assets/images/about-image-3/about-image-3-new.png'
import image4 from '../../assets/images/about-image-4/Founder Quote.png'
import authorImg from '../../assets/images/authorImg/authorImg.png'
 
import Slider from 'react-slick'
import Tooltip from '@material-ui/core/Tooltip'
import { DownloadButtons } from '../../components/DownloadButtons'
import { DownloadLine } from '../../components/DownloadLine'

function NextArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} ${css(styles.nextArrow)}`}
            onClick={onClick}
        />
    )
}

function PrevArrow(props) {
    const { className, style, onClick } = props
    return (
        <div
            className={`${className} ${css(styles.prevArrow)}`}
            onClick={onClick}
        />
    )
}

export default function AboutView(props) {
    const sliderSettings = {
        className: 'slider journey-slider',
        dots: false,
        infinite: false,
        centerMode: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        arrows: true,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        variableWidth: true,
        swipeToSlide: true,
    }

    return (
        <div>
            <WebHeader />

            <section>
                <Container className={css(styles.customWidth)}>
                    <Row className={css(styles.section1)}>
                        <Col>
                            <h1
                                className={`${css(
                                    styles.heroSectionHeading
                                )} HeadingGradient`}
                                style={{ textAlign: 'center' }}>
                                Get ready for Qcast!
                            </h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p
                                className={css(styles.customheroSectionPara)}
                                style={{ textAlign: 'center' }}>
                                Qcast means ‘Question-Cast’ and is <br/> “a social
                                network built on questions”.
                            </p>
                            {/* <p className={css(styles.customheroSectionPara)}>
              Instead of posturing, how about seeking answers?
              </p> */}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <p
                                className={css(styles.heroSectionPara)}
                                style={{ textAlign: 'center' }}>
                                With innovative user-friendly UX design, Qcast
                                combines multiple unique features to deliver a
                                fresh experience to social media users.
                                Immersive Engagement is the valuable experience
                                ‘Qcasters’ are invited into, as they can share,
                                and respond to, insightful questions, creating
                                shareable video streams called Qcast
                                Conversations; a dynamic new type of content for
                                everyone online.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section style={{ overflow: 'hidden' }}>
                <Container className={css(styles.customWidth)}>
                    <Row className={css(styles.section2)}>
                        <Col lg={5}>
                            <img
                                src={image1}
                                className={css(styles.img1)}
                                width="480px"
                            />
                        </Col>

                        <Col lg={7}>
                            <h1
                                className={`${css(
                                    styles.section2Heading
                                )} HeadingGradient`}>
                                Why Qcast?
                            </h1>
                            {/* <img src={image0} alt=""/> */}
                            <p
                                className={css(styles.section2Para)}
                                style={{ fontWeight: '500' }}>
                                Qcast evolved from the idea that we’ve drifted
                                away from the very things that make social media
                                great; the collaboration, the connection and new
                                ways of thinking.
                            </p>
                            <p className={css(styles.section2Para)}>
                                We’d like to change the paradigm and get back to
                                some of that good stuff by opening new
                                discussions through Qcast conversations.
                            </p>
                            <p className={css(styles.section2Para)}>
                                Our goal is to open up new conversations,
                                enabling users (Qcasters) to share insights by
                                asking catalytic questions of each other that
                                spark fresh ideas which can generate both
                                personal and commercial solutions.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section style={{ overflow: 'hidden' }}>
                <Container className={css(styles.customWidth)}>
                    <Row className={css(styles.section3)}>
                        <Col lg={5}>
                            <div className={css(styles.img2View)}>
                                <img
                                    src={image3}
                                    className={css(styles.img2)}
                                />
                            </div>
                        </Col>

                        <Col lg={7}>
                            <h1
                                className={`${css(
                                    styles.section2Heading
                                )} HeadingGradient`}>
                                Qcast is for everyone!
                            </h1>
                            <p
                                className={css(styles.section2Para)}
                                style={{ fontWeight: '500' }}>
                                Qcast is for anyone and everyone who either has
                                insight to share or insight to gain.
                            </p>
                            <p className={css(styles.section2Para)}>
                                Whether you're an influencer, author, musician
                                or business leader with questions to share, or a
                                student, researcher or music fan with answers to
                                give - Qcast is for you.
                            </p>
                            <p className={css(styles.section2Para)}>
                                And it’s also a network where you can be
                                interviewed by your favourite celebrity! Qcast
                                is for anyone with a question to ask, an idea to
                                share or with a little bit of curiosity in their
                                soul.
                            </p>
                            <p className={css(styles.section2Para)}>
                                Download the app today and share, respond and
                                engage with fellow Qcasters.
                            </p>
                            <DownloadLine/>
                            <DownloadButtons />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
                <Container fluid={true}>
                    <Row>
                        <Container className={css(styles.customWidth)}>
                            <Row className={css(styles.ourJourneyView)}>
                                <Col>
                                    <h1
                                        className={`${css(
                                            styles.section2Heading
                                        )} HeadingGradient`}
                                        style={{ textAlign: 'center' }}>
                                        Our Journey
                                    </h1>
                                </Col>
                            </Row>
                        </Container>
                    </Row>
                    <row>
                        <Slider {...sliderSettings}>
                            <div className={css(styles.sliderView)}>
                                <h6 className={css(styles.sliderHeading)}>
                                    Dec 2017
                                </h6>
                                <p className={css(styles.sliderDescription)}>
                                    Development begins on a digital platform
                                    which Qcast is created for, as an exclusive
                                    feature.
                                </p>
                            </div>
                            <div className={css(styles.sliderView)}>
                                <h6 className={css(styles.sliderHeading)}>
                                    April 2021
                                </h6>
                                <p className={css(styles.sliderDescription)}>
                                    Tim Kobe, founder of 8inc and creator of the
                                    Apple Store, becomes our first Senior
                                    Advisor.
                                </p>
                            </div>

                            <div className={css(styles.sliderView)}>
                                <h6 className={css(styles.sliderHeading)}>
                                    May 2021
                                </h6>
                                <p className={css(styles.sliderDescription)}>
                                    Development begins on Qcast as a standalone
                                    platform, and Qcast Ltd. Is registered.
                                </p>
                            </div>
                            <div className={css(styles.sliderView)}>
                                <h6 className={css(styles.sliderHeading)}>
                                    Nov 2021
                                </h6>
                                <p className={css(styles.sliderDescription)}>
                                    Our first public announcement at the
                                    inaugural Step FWD Awards, beginning our
                                    Alpha phase of testing.
                                </p>
                            </div>

                            <div className={css(styles.sliderView)}>
                                <h6 className={css(styles.sliderHeading)}>
                                    Dec 2021
                                </h6>
                                <p className={css(styles.sliderDescription)}>
                                    We captured a number of Qcasts at the MOBO
                                    Awards 2021, in Coventry UK.
                                </p>
                            </div>
                            <div className={css(styles.sliderView)}>
                                <h6 className={css(styles.sliderHeading)}>
                                    Jan 2022
                                </h6>
                                <p className={css(styles.sliderDescription)}>
                                    Focus group Beta UAT (User Acceptance
                                    Testing)
                                </p>
                            </div>
                        </Slider>
                    </row>
                </Container>
            </section>

            <section className={css(styles.lastSection)}>
                <Container className={css(styles.customWidth)}>
                    <Row style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        <img src={image4} alt="" 
                        className={css(styles.img4)}
                        />
                        {/* <Col>
              <h1 className={css(styles.authorHeading1)}>
                “Behind every breakthrough, is a better
              </h1>
              <h1 className={css(styles.authorHeading2)}>
                question <span className={css(styles.authorHeading1)}>”</span>
              </h1>
            </Col>
          </Row>

          <Row>
            <div className={css(styles.authorView)}>
              <img src={authorImg} width="120px" />
              <div className={css(styles.authorDetailView)}>
                <p className={css(styles.authorText)}>
                  MIT Professor and author of ‘Questions are the Answer’,
                </p>
                <p className={css(styles.authorName)}>Hal Gregerson</p>
              </div>
            </div> */}
                    </Row>
                </Container>
            </section>
            <WebFooter />
        </div>
    )
}
