// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./DocumentStyles";
import { WebHeader, WebFooter } from "../../components";
import { Container, Row, Col } from "react-bootstrap";
import pdfIcon from "../../assets/images/pdfIcon/pdfIcon.png";

export default function DocumentView(props) {
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={css(styles.heading)}>Download NDA</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={css(styles.description)}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                aliquet nec tellus nullam posuere mauris accumsan amet, tortor.
                Tellus sed arcu feugiat libero venenatis aliquet diam.
              </p>
            </Col>
          </Row>

          <Row>
            <Col className={css(styles.pdfView)}>
              <img src={pdfIcon} />
              <a
                href="../../assets/dummy.pdf"
                className={css(styles.downloadText)}
                download
              >
                Download Qcast NDA
              </a>
            </Col>
          </Row>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
