
import alton from '../video/satalite/alton/alton.png';
import alton_intro from '../video/satalite/alton/alton_intro.mp4';
import alton_q1 from '../video/satalite/alton/alton_q1.mp4';
import alton_q2 from '../video/satalite/alton/alton_q2.mp4';
import alton_q3 from '../video/satalite/alton/alton_q3.mp4';
import alton_q4 from '../video/satalite/alton/alton_q4.mp4';
import alton_q5 from '../video/satalite/alton/alton_q5.mp4';
import alton_q6 from '../video/satalite/alton/alton_q6.mp4';
import alton_q7 from '../video/satalite/alton/alton_q7.mp4';
import alton_q8 from '../video/satalite/alton/alton_q8.mp4';
import alton_q9 from '../video/satalite/alton/alton_q9.mp4';
import alton_outro from '../video/satalite/alton/alton_outro.mp4';
import icon from '../video/satalite/alton/icon.png';
import astepfwd from '../video/satalite/astepfwd/astepfwd.png';
import astepfwd_intro from '../video/satalite/astepfwd/astepfwd_intro.mp4';
import astepfwd_q1 from '../video/satalite/astepfwd/astepfwd_q1.mp4';
import astepfwd_q2 from '../video/satalite/astepfwd/astepfwd_q2.mp4';
import astepfwd_q3 from '../video/satalite/astepfwd/astepfwd_q3.mp4';
import astepfwd_q4 from '../video/satalite/astepfwd/astepfwd_q4.mp4';
import astepfwd_q5 from '../video/satalite/astepfwd/astepfwd_q5.mp4';
import astepfwd_intro_img from '../video/satalite/astepfwd/astepfwd_intro_img.jpg';
import astepfwd_q1_img from '../video/satalite/astepfwd/astepfwd_q1_img.jpg';
import astepfwd_q2_img from '../video/satalite/astepfwd/astepfwd_q2_img.jpg';
import astepfwd_q3_img from '../video/satalite/astepfwd/astepfwd_q3_img.jpg';
import astepfwd_q4_img from '../video/satalite/astepfwd/astepfwd_q4_img.jpg';
import astepfwd_q5_img from '../video/satalite/astepfwd/astepfwd_q5_img.jpg';
import joel from '../video/satalite/joel/joel.jpg';
import joel_intro from '../video/satalite/joel/joel_intro.mp4';
import joel_q1 from '../video/satalite/joel/joel_q1.mp4';
import joel_q2 from '../video/satalite/joel/joel_q2.mp4';
import joel_q3 from '../video/satalite/joel/joel_q3.mp4';
import joel_q4 from '../video/satalite/joel/joel_q4.mp4';
import joel_q5 from '../video/satalite/joel/joel_q5.mp4';
import joel_q6 from '../video/satalite/joel/joel_q6.mp4';

import astepfwd2 from '../video/satalite/astepfwd2/astepfwd2.png';
import astepfwd2_intro from '../video/satalite/astepfwd2/astepfwd2_intro.mp4';
import astepfwd2_q1 from '../video/satalite/astepfwd2/astepfwd2_q1.mp4';
import astepfwd2_q2 from '../video/satalite/astepfwd2/astepfwd2_q2.mp4';
import astepfwd2_q3 from '../video/satalite/astepfwd2/astepfwd2_q3.mp4';
import astepfwd2_q4 from '../video/satalite/astepfwd2/astepfwd2_q4.mp4';

import astepfwd3 from '../video/satalite/astepfwd3/astepfwd3.png';
import astepfwd3_intro from '../video/satalite/astepfwd3/astepfwd3_intro.mp4';
import astepfwd3_q1 from '../video/satalite/astepfwd3/astepfwd3_q1.mp4';
import astepfwd3_q2 from '../video/satalite/astepfwd3/astepfwd3_q2.mp4';

const satalite=[
    {
        "id": 3,
        "name": "RISE Education",
        "question": "Reality Therapy in School for all!",
        "description":"When Therapy meets education we both heal and empower at the same time! How can more Reality Therapy be used in our schools?",
        "tag":"rise-education-series",
        "image":alton,
        "gene":"Education",
        "data":[{
            "id": 3,
            "name": "RISE Education",
            "question": "Reality Therapy in School for all!",
            "description":"When Therapy meets education we both heal and empower at the same time! How can more Reality Therapy be used in our schools?",
            "tag":"rise-education",
            "parent":"rise-education-series",
            "image":alton,
            "video": [
                {
                    "name": "Introduction - Welcome to my Qcast",
                    "poster":alton,
                    "url": alton_intro,
                    "description": ""
                },{
                    "name": "Q1 - Choice Theory: Meeting young people's needs",
                    "url": alton_q1,
                    "poster":alton,
                    "description": ""
                },
                {
                    "name": "Q2 - Love & Belonging",
                    "url": alton_q2,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Q3 - Power",
                    "url": alton_q3,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Q4 - Freedom",
                    "url": alton_q4,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Q5 - Fun",
                    "url": alton_q5,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Q6 - Survival",
                    "url": alton_q6,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Q7 - Meeting the five basic needs, of young people",
                    "url": alton_q7,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Q8 - Where do you go for wisdom?",
                    "url": alton_q8,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Q9 - What do you think is most important thing we can give our children?",
                    "url": alton_q9,
                    "poster":alton,
                    "description": ""
                },{
                    "name": "Outro - Thank you for your Qcast Responses",
                    "url": alton_outro,
                    "poster":alton,
                    "description": ""
                }
            ]
        },],
    },
    

    {
        "id": 2,
        "name": "Joel Wilson",
        "question": "Empathy and Deep Human Connections",
        "description":"I’m a filmmaker, writer, rap lyricist, Alastair McIntosh quoter and Afrofuturism enthusiast. I believe we’re living in rap’s golden era right now.",
        "tag":"joel-wilson-series",
        "image":joel,
        "gene":"Society",
        "data":[{
            "id": 2,
            "name": "Joel Wilson",
            "question": "Empathy and Deep Human Connections",
            "description":"I’m a filmmaker, writer, rap lyricist, Alastair McIntosh quoter and Afrofuturism enthusiast. I believe we’re living in rap’s golden era right now.",
            "tag":"joel-wilson",
            "parent":"joel-wilson-series",
            "image":joel,
            "video": [
                {
                    "name": "",
                    "url": joel_intro,
                    "poster":"",
                    "description": ""
                },
                {
                    "name": "",
                    "url": joel_q1,
                    "poster":"",
                    "description": ""
                },
                {
                    "name": "",
                    "url": joel_q2,
                    "poster":"",
                    "description": ""
                },
                {
                    "name": "",
                    "url": joel_q3,
                    "poster":"",
                    "description": ""
                },
                {
                    "name": "",
                    "url": joel_q4,
                    "poster":"",
                    "description": ""
                },
                {
                    "name": "",
                    "url": joel_q5,
                    "poster":"",
                    "description": ""
                },
                {
                    "name": "",
                    "url": joel_q6,
                    "poster":"",
                    "description": ""
                }
            ]
        }]
    },{
        "id": 1,
        "name": "AStepFWD",
        "description":"StepFWD is a multi-generational 360-media organisation that also powers the national UK Christian Charts",
        "tag":"astepfwd-series",
        "image":astepfwd,
        "gene":"Faith",
        "data":[
            {
                "id": 1,
                "name": "AStepFWD",
                "question": "Industry Questions: Series 1",
                "description":"StepFWD is a multi-generational 360-media organisation that also powers the national UK Christian Charts",
                "tag":"astepfwd",
                "parent":"astepfwd-series",
                "image":astepfwd,
                "video": [
                    {
                        "name": "Lady T - Legacy",
                        "poster":astepfwd_intro_img,
                        "url": astepfwd_intro,
                        "description": ""
                    },
                    {
                        "name": "Les Moir - Collaboration",
                        "poster":astepfwd_q1_img,
                        "url": astepfwd_q1,
                        "description": ""
                    },
                    {
                        "name": "Lurine Cato MBE - Decisions",
                        "poster":astepfwd_q2_img,
                        "url": astepfwd_q2,
                        "description": ""
                    },
                    {
                        "name": "Volney Morgan - Successful",
                        "poster":astepfwd_q3_img,
                        "url": astepfwd_q3,
                        "description": ""
                    },
                    {
                        "name": "Goziam Okogwu - Motivation",
                        "poster":astepfwd_q4_img,
                        "url": astepfwd_q4,
                        "description": ""
                    },
                    {
                        "name": "Noel Robinson - Step FWD",
                        "url": astepfwd_q5,
                        "poster":astepfwd_q5_img,
                        "description": ""
                    }
                ]
            },{
                "id": 2,
                "name": "AStepFWD",
                "question": "Qcast Questions from the StepFWD Awards 2021 Red Carpet",
                "description":"StepFWD is a multi-generational 360-media organisation that also powers the national UK Christian Charts",
                "tag":"astepfwd-round-2",
                "parent":"astepfwd-series",
                "image":astepfwd2,
                "video": [
                    {
                        "name": "Faith Child - What do we need to do?",
                        "url": astepfwd2_intro,
                        "poster":"",
                        "description": ""
                    },{
                        "name": "Matt Cooper - What would happen?",
                        "url": astepfwd2_q1,
                        "poster":"",
                        "description": ""
                    },
                    {
                        "name": "Philippa Hanna - Who would you honour?",
                        "url": astepfwd2_q2,
                        "poster":"",
                        "description": ""
                    },{
                        "name": "Dai Woolidge - What would you say?",
                        "url": astepfwd2_q3,
                        "poster":"",
                        "description": ""
                    },{
                        "name": "O’Neil Dennis - What are you doing to StepFWD?",
                        "url": astepfwd2_q4,
                        "poster":"",
                        "description": ""
                    }
                ]
            },{
                "id": 5,
                "name": "AStepFWD",
                "question": "Three Qcast Questions from Premier Radio’s Cassandra Maria",
                "description":"StepFWD is a multi-generational 360-media organisation that also powers the national UK Christian Charts",
                "tag":"astepfwd-round-3",
                "parent":"astepfwd-series",
                "image":astepfwd3,
                "video": [
                    {
                        "name": "What does gospel music mean to you?",
                        "url": astepfwd3_intro,
                        "poster":"",
                        "description": ""
                    },{
                        "name": " What does music for life mean to you?",
                        "url": astepfwd3_q1,
                        "poster":"",
                        "description": ""
                    },
                    {
                        "name": "Who is your favourite Gospel artist?",
                        "url": astepfwd3_q2,
                        "poster":"",
                        "description": ""
                    }
                ]
            }
        ]
    },
    
    
    
]
export default satalite
