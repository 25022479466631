// @flow
import { StyleSheet } from 'aphrodite'

export default StyleSheet.create({
    view: {
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 480px)': {
          justifyContent:"center"
        },
    },
})
