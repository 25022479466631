// @flow
import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./PricingStyles";
import { WebHeader, WebFooter,PricingCard } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col } from "react-bootstrap";
import rightIconBlue from "../../assets/images/rightIconBlue/rightIconBlue.png";
import pricingLogo from "../../assets/images/pricingLogo/pricingLogo.png"
import { ROUTES } from "../../constants";
export default function PricingView(props) {
  const [mode, setMode]=useState(true)
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <div className={css(styles.topMainSection)}>
            <img src={pricingLogo} className={css(styles.topLogo)} />
            <p className={css(styles.mainHeaderParagraph1)} >How could you put questions to work?</p>
            <p className={css(styles.mainHeaderParagraph2)}>Go Pro with Qcast for Business </p>
            <p className={css(styles.mainHeaderParagraph3)}>Select pricing model</p>
            <div  style={{display:"flex",width:256,marginBottom:20}}>
              <button 
              onClick={() => setMode(true)}
              className={mode === true ? `${css(styles.getStartButton)}` : `${css(styles.getStartButtonInactive)}`}>
                  Monthly
                </button>
                <button onClick={() => setMode(false)}
              className={mode === false ? `${css(styles.getStartButton)}` : `${css(styles.getStartButtonInactive)}`}>
                  Yearly
                </button></div>
            </div>
          <div className={css(styles.mainContainer)}>
            <div className={css(styles.priceHeader)}>
              <div className={css(styles.topSection)}></div>
              <div className={css(styles.topSection)}>
                <h1 className={css(styles.topSectionHeading)}>FREE</h1>
                <h1 className={css(styles.topSectionHeading2)}>
                  &pound; 0 </h1><p className={css(styles.topSectionDescription)}>per Person / Month<br /><br /> </p>
                <p className={css(styles.topSectionDescriptiongray)}>Storage Quota: Unlimited for recorded Qcasts + Qcast Responses</p>

                <p className={css(styles.topSectionDescription)}>For people starting out, using Qcast  to begin engagement.<br /><br />
                </p>
                <Link to={ROUTES.CONTACT} className={css(styles.getStartButton)} style={{textDecoration: "none"}} >
                  Get Started
                </Link>
              </div>

              <div className={css(styles.topSection)}>
                <h1 className={css(styles.topSectionHeading)}>PRO</h1>
                <h1 className={css(styles.topSectionHeading2)}>
                  &pound; {mode === true ? "5.99" : "71.88"} </h1><p className={css(styles.topSectionDescription)}>per Person / Month <br /><br /></p>
                <p className={css(styles.topSectionDescriptiongray)}>Storage Quota: Unlimited for recorded Qcasts + Qcast Responses</p>

                <p className={css(styles.topSectionDescription)}>For people who want to share deeper immersive engagement with their audience.
                </p>
                <Link to={ROUTES.CONTACT} className={css(styles.getStartButton)} style={{textDecoration: "none"}} >
                  Get Started
                </Link>
              </div>



              <div className={css(styles.topSection)}>
                <h1 className={css(styles.topSectionHeading)}>BUSINESS</h1>
                <h1 className={css(styles.topSectionHeading2)}>
                  &pound; {mode === true ? "2.99" : "35.88"} </h1><p className={css(styles.topSectionDescription)}>per Person <br />(£14.95 for x5 User; starting price) / Month</p>
                <p className={css(styles.topSectionDescriptiongray)}>Storage Quota: Unlimited for recorded Qcasts + Qcast Responses</p>

                <p className={css(styles.topSectionDescription)}>For teams of people who want to use Qcast with a private network. <br />(User Limits Apply)
                </p>
                <Link to={ROUTES.CONTACT} className={css(styles.getStartButton)} style={{textDecoration: "none"}} >
                  Get Started
                </Link>
              </div>
            </div>
            <div className={css(styles.pricingBreak)}>Features</div>
            <div >
              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Subscriber Limit</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>1</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>1</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>5+</p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Question Per Qcast</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>6</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>12</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>20</p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Question Length</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>1 minute</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>2 minutes</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>5 minutes</p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Response Length</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>3 minute</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>10 minutes</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>20 minutes</p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Team Channel</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Webcam Feature</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>
            </div>
            <div className={css(styles.pricingBreak)}>Video Editing</div>
            <div >


              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Video Editing</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>In App only</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>In App only + Website</p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}>In App only + Website</p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Video Uploading</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>
            </div>
            <div className={css(styles.pricingBreak)}>Security & Privacy</div>
            <div >


              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Channel Permission</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Ban and report</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Workspace Privacy</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>
            </div>
            <div className={css(styles.pricingBreak)}>Admin Control</div>
            <div >


              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Single Sign On (SSO + SAML)</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Account Transfer and ownership of content (Admin, Contributor, View)</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>
            </div>
            <div className={css(styles.pricingBreak)}>Support</div>
            <div >


              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Telephone Based Support</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Qcast Chat</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Dedicated Acount Manager</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>
            </div>
            <div className={css(styles.pricingBreak)}>Qcast Plugin Integration</div>
            <div >


              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Qcast Plugin Feature</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>
            </div>
            <div className={css(styles.pricingBreak)}>Additional Features</div>
            <div >

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Qcast Keynote</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Archive Feature</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Channel Insights</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>


              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Sentiment Analysis</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Inspect Permissions</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Session Duration Management</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Global Search</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Channel Search</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              </div>

              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}>Google Searchable Content</div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
                <div className={css(styles.streetHouse)}><p className={css(styles.streetParagph)}><img src={rightIconBlue} className={css(styles.icon)} /></p></div>
              
              </div>
              <div className={css(styles.street)}>
                <div className={css(styles.streetHouse)}></div>
                <div className={css(styles.streetHouse)}><p style={{    paddingRight: "20px"}} className={css(styles.streetParagph)}><Link to={ROUTES.CONTACT} className={css(styles.getStartButton)} style={{textDecoration: "none"}} >
                  Get Started
                </Link></p></div>
                <div className={css(styles.streetHouse)}><p style={{    paddingRight: "20px"}} className={css(styles.streetParagph)}><Link to={ROUTES.CONTACT} className={css(styles.getStartButton)} style={{textDecoration: "none"}} >
                  Get Started
                </Link></p></div>
                <div className={css(styles.streetHouse)}><p style={{    paddingRight: "20px"}} className={css(styles.streetParagph)}><Link to={ROUTES.CONTACT} className={css(styles.getStartButton)} style={{textDecoration: "none"}} >
                  Get Started
                </Link></p></div>
              
              </div>
              
            </div>
          </div>

          <div className={css(styles.mobilePricing)}>
            <PricingCard></PricingCard>
          </div>
        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
