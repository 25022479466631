import teamImage1 from "../../assets/images/teamImage1/teamImage1.png";
import teamImage2 from "../../assets/images/teamImage2/teamImage2.png";
import teamImage3 from "../../assets/images/teamImage3/teamImage3.png";
import teamImage4 from "../../assets/images/teamImage4/teamImage4.png";
import teamImage5 from "../../assets/images/teamImage5/teamImage5.png";
import teamImage6 from "../../assets/images/teamImage6/teamImage6.png";
import teamImage7 from "../../assets/images/teamImage7/teamImage7.png";
import teamImage8 from "../../assets/images/teamImage8/teamImage8.png";
import teamImage10 from "../../assets/images/teamImage10/teamImage10.png";
import teamImage9 from "../../assets/images/teamImage9/teamImage9.png";
import AntonioGoncalve from "../../assets/images/otherTeam/AntonioGoncalve.png";
import AntonioGoncalveS from "../../assets/images/otherTeam/AntonioGoncalveS.png";
import BogdanLlie from "../../assets/images/otherTeam/BogdanLlie.png";
import BogdanLlieS from "../../assets/images/otherTeam/BogdanLlieS.png";
import DemolaOyinlade from "../../assets/images/otherTeam/DemolaOyinlade.png";
import DemolaOyinladeS from "../../assets/images/otherTeam/DemolaOyinladeS.png";
import JamesElwen from "../../assets/images/otherTeam/JamesElwen.png";
import JamesElwenS from "../../assets/images/otherTeam/JamesElwenS.png";

import Jeremy from "../../assets/images/otherTeam/Jeremy.png";
import JeremyS from "../../assets/images/otherTeam/JeremyS.png";
import RokhsanaRashid from "../../assets/images/otherTeam/RokhsanaRashid.png";
import RokhsanaRashidS from "../../assets/images/otherTeam/RokhsanaRashidS.png";
import dummyImage1WithShadow from "../../assets/images/dummyImage1WithShadow/dummyImage1WithShadow.png";
 import dummyImage2WithShadow from "../../assets/images/dummyImage2WithShadow/dummyImage2WithShadow.png";
import dummyImage3WithShadow from "../../assets/images/dummyImage3WithShadow/dummyImage3WithShadow.png";
import dummyImage4WithShadow from "../../assets/images/dummyImage4WithShadow/dummyImage4WithShadow.png";
import dummyImage5WithShadow from "../../assets/images/dummyImage5WithShadow/dummyImage5WithShadow.png";
import dummyImage6WithShadow from "../../assets/images/dummyImage6WithShadow/dummyImage6WithShadow.png";
import dummyImage7WithShadow from "../../assets/images/dummyImage7WithShadow/dummyImage7WithShadow.png";
import dummyImage8WithShadow from "../../assets/images/dummyImage8WithShadow/dummyImage8WithShadow.png";
import dummyImage9WithShadow from "../../assets/images/dummyImage9WithShadow/dummyImage9WithShadow.png";
import dummyImage10WithShadow from "../../assets/images/dummyImage10WithShadow/dummyImage10WithShadow.png";

export  const TeamData=[
        {
                name:'Shabazz L. Graham',
                title:"Co-Founder / CEO",
                story:`<p><b>Shabazz’s most important question:</b></p>
                <p>“What is my purpose; why am I here?”</p>
                <p>I asked myself this question when I was about 12 years old. How I live today, over 30 years later, is a result of the answer I was given.”</p>
                <p>Shabazz has spent the last 28 years working in the UK film and TV industry as a writer, director and film editor. After losing a dear friend and mentor in 2014; (Philip Taylor, a great Questioner) the idea for Qcast was born.</p>
                <p>Shabazz is a divergent thinker who questions everything in the hope to find the best answer and solution. He loves great conversation and is passionate about story.</p>
                <p><b>If Shabazz could ask everyone on earth the same question he would ask</b></p> “What makes you feel alive?`,
                image1:dummyImage3WithShadow,
                image2:teamImage2,
            },
            {
                name:'Tayo Dada',
                title:"Co-Founder / CTO",
                story:`<p>Tayo’s most important question :</p>
                <p>“What does success look like for you?”</p>
                <p>“I asked myself this question a number of years ago when I realised that pursuing a goal is great but who you pursue your goal with, is greater.”</p>
                <p>Tayo is a proud husband, Dad and geek with over 30 years working in software development, IT management and startups. Tayo has always been inquisitive and started software coding on a Commodore 64 when he was young, hacking away in his teenage years and working with startups in his latter years. “Innovation and challenging problems keeps me young.</p>
               <p>If Tayo could ask everyone on earth the same question he would ask</p>
                <p>“What challenges you to be the best version of yourself?</p>`,
                image1:dummyImage5WithShadow,
                image2:teamImage4,
            },
            {
                    
        
                    name:'Archana Melfi',
                    title:"Operations and Legal Manager",
                    story:`
                    <p> Archie’s most important question: How can I help make this better?</p>

                    <p>I ask this question in many situations; it allows me to play a more substantial role in my family, projects, teams, and even the occasional movement.</p>

                    <p>I learnt to ask this question through the long-term observation of my parents and their parents, who have been persistent and active members of their respective communities and always willing to help others during the most challenging times.</p>

                    <p>It’s an open-ended question underpinned by the situation, the people involved, and the problem presented. Asking it encourages me to interpret a challenge through my own experiences and knowledge and to contribute something workable.</p>

                    <p>Archie is the Operations Lead at Qcast. Having studied and worked in law and policy for almost a decade, she believes that actual change begins when people get together with a common purpose.</p>`,
                    image1:dummyImage1WithShadow,
                    image2:teamImage1,
                },
                {
                    name:'Tim Kobe',
                    title:"Senior Advisor",
                    story:`<p>Tim’s most important question: What do you want to be when you grow up? </p><p>“It’s a quintessential question and one that I remember from such a young age that I almost don’t recall the first time I was asked. I was asked by relatives, friends and grandparents but what was interesting with almost each case was, the question could not be answered.</p>
                    <p>It was very much based on the context, my age and my point in life. At some point I even abandoned the idea that it was possible to answer the question and only then did I start to realize that I was consistently in the act of becoming through learning and new experiences.</p>
                    <p>Tim is Founder and CEO of Eight Inc, one of the most progressive design firms, sometimes called “Apple’s best kept secret”.</p>`,
                    image1:dummyImage8WithShadow,
                    image2:teamImage7,
                },
                {
                name:'Mansoor Mizra',
                title:"Lead UX / UI Designer",
                story:`<p>Mansoor’s most important question: What is my ambition?
                </p><p>I asked this question in my early teens when I read a quote from Ralph Waldo Emerson “Without ambition one starts nothing. Without work, one finishes nothing. The prize will not be sent to you. You have to win it.”</p>
                <p>Mansoor is a self-taught UI UX designer, who started at the age of 13 and now has over 16 years of design experience. Mansoor is passionate about solving problems through design and believes that design is intelligence made visible.</p>
                <p>If Mansoor could ask everyone on planet earth the same question he would ask
                “Is your glass half empty or half full?.</p>`,
                image1:dummyImage4WithShadow,
                image2:teamImage3,
            },
            {
                    
        
                    name:'Kumar Sanu',
                    title:"Lead Developer",
                    story:`
                    <p>Sanu’s most important question: “Will I be having a successful and proud career in the IT industry ?</p><p> I asked myself this question when I was in college . Today I realized  success , money is an illusion. Work for excellence , success will follow you .</p>
<p>Sanu has spent the last 10 years in the IT industry  as a developer. He worked for a number of multinational banks in  the India and the UK.  Being a fan of computer applications, he pursued his career in IT and was recruited by Multinational Service based giant in 2012.</p>
<p>Sanu likes to learn new things and improve himself every day . As Gandhi once said - “Live like you have to die  tomorrow and learn like you have to live forever”. Apart from Technical stuff , he likes to play cricket and explore historical places.</p>
`,
                    image1:dummyImage10WithShadow,
                    image2:teamImage9,
                },
            {
                    
        
                    name:'Bogdan Ilie',
                    title:"Software Developer",
                    story:`
                    `,
                    image1:BogdanLlieS,
                    image2:BogdanLlie,
                },
                {
                    
        
                    name:'Jeremy Luisetti',
                    title:"Software Developer",
                    story:`<p>Jeremy’s most important question: How does it work? Why does it work like this?
                    </p><p>Since my childhood I’m always amazed to understand how things work. From then I’ve always been interested in new things and repairing things.</p>
                    <p>Jérémy has been a developer for 12 years, he manages servers, creates sites every day and makes mobile apps. As he likes to understand how things work, he develops in 7 programming languages on a daily basis. He joined the ranks of Qcast for the same reasons that push him forward every day; to understand ‘how Qcast works’ - “How can I do my best to improve and optimize this amazing app?”</p><p>If Jeremy’s could ask everyone on planet earth the same question he would ask, “How do you think the world of today is working? and how can we make it better?</p>`,
                    
                    image1:JeremyS,
                    image2:Jeremy,
                },
                {
                    
        
                    name:'Rokhsana Rashid',
                    title:"Software Developer",
                    story:`
                    
                    <p>Rokhsana’s most important question: How powerful is the human brain? Can it solve any problem?</p>

                    <p>Since I was a child in Kindergarten, I always thought about the human brain, how it reacts, and how we move our hands?</p>

                    <p>I have been developing software for 4 years, and work with React/Next JS/Express (MERN Stack). As a Software Engineer, I have been honoured to work with great projects; Qcast is one of them.</p>

                    <p>If Rokhsana could ask everyone on planet earth the same question she would ask, “Do you know why we (humans) are unique? Her own response; “It’s because of our brain! So don’t waste your time, utilise it up to its highest potential and solve problems that make other people smile :)”</p>`,
                    image1:RokhsanaRashidS,
                    image2:RokhsanaRashid,
                },
                {
                    
        
                    name:'Antonio Goncalves',
                    title:"Software Developer",
                    story:`<p>Antonio’s most important question: What can you do that could change the world?
                    </p><p>Since the beginning of Antonio’s adolescence, he’d ask himself what could he create that could change the world for the better and since then he has been building and working around this idea.</p>
                    <p>Antonio spent the last 5 years developing skills in the area he considers most important, software development, which brings him every day the possibility to create and improve someone’s life. His dream was always to be the owner of its business and be able to impact the lives of everyone in a positive way and he aspires to become that person one day.</p>`,
                    image1:AntonioGoncalveS,
                    image2:AntonioGoncalve,
                    
                    }
                    ,
                    {
                    
        
                        name:'Chris Melfi',
                        title:"Design Lead",
                        story:`<p>Chris's most important question:
                        How do they see it?</p>
                        <p>"Design empathy, apart from being the most overused buzzword out there, is pretty much the most foundational skill in <s>design</s> everything.. and if you can truly figure out how the other person sees and feels about the the thing you're doing, then you're half way there."</p>
                        <p>Chris has been a designer for a good long while. After specialising in form design for agencies, then working in-house for a couple of larger organisations, designing for government, insurance and emergency services, he's gained a keen interest in trying to let the little words do the work.</p>
                        <p>When Chris isn't working, he slaps bass.
                        If he could ask the world a question, it'd be:  When did you last have a good laugh at yourself?</p>`,
                        image1:dummyImage7WithShadow,
                        image2:teamImage6,
                        },
                    
                {
                    
        
                    name:'Oyinlade Demola',
                    title:"Website Developer",
                    story:``,
                    image1:DemolaOyinladeS,
                    image2:DemolaOyinlade,
                    }
                  
        ]
        
    

   