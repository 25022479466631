// @flow
import React from 'react'
import { css } from 'aphrodite'
import styles from './WebFooterStyles'
import { Container, Row, Col } from 'react-bootstrap'
import footerLogo from '../../assets/images/footerLogo/Purple Text- PNG Small.png'
import facebookIconBlue from '../../assets/images/facebookIconBlue/facebookIconBlue@3x.png'
import instagramIconBlue from '../../assets/images/instagramIconBlue/instagramIconBlue@3x.png'
import twitterIconBlue from '../../assets/images/twitterIconBlue/twitterIconBlue@3x.png'
import linkedinIconBlue from '../../assets/images/linkedinIconBlue/linkedinIconBlue@3x.png'
import googlePlayIcon from '../../assets/images/googlePlayIcon/googlePlayIcon@3x.png'
import appleStoreIcon from '../../assets/images/appleStoreIcon/appleStoreIcon@3x.png'
import Tooltip from '@material-ui/core/Tooltip'
import { ROUTES } from '../../constants'
import { NavLink } from 'react-router-dom'
import { IMAGES } from '../../assets/images/path'

export default function WebFooterView(props) {
    return (
        <>
            <div className={css(styles.container)}>
                <Container className={css(styles.customWidth)}>
                    <Row className={css(styles.footerMainView)}>
                        <Col lg="3" className={css(styles.listColumnLeft)}>
                            <NavLink
                                to={ROUTES.HOME}
                                className={css(styles.logoMainView)}>
                                <img src={footerLogo} width="186px" />
                            </NavLink>
                            <p className={css(styles.description)}>
                                Qcast is a platform dedicated to one of
                                humanity’s most important means of
                                communication, Questions.
                            </p>
                        </Col>

                        <Col
                            lg="2"
                            md="6"
                            xs="6"
                            sm="6"
                            className={css(styles.listColumn)}>
                            <ul className={css(styles.listMainView)}>
                                <li className={css(styles.listHeading)}>
                                    Discover
                                </li>
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.FAQ}
                                        className={css(styles.list)}>
                                        FAQs
                                    </NavLink>
                                </li>
                               {/*  <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.PRICING}
                                        className={css(styles.list)}>
                                        Pricing Page
                                    </NavLink>
                                </li> */}
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.DOWNLOAD}
                                        className={css(styles.list)}>
                                        Download
                                    </NavLink>
                                </li>
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.USES_CASES}
                                        className={css(styles.list)}>
                                        Use Cases
                                    </NavLink>
                                </li>
                                {/* <li className={css(styles.listView)}>
                  <NavLink to={ROUTES.PORTAL} className={css(styles.list)}>
                    Portal
                  </NavLink>
                </li> */}
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.SATELITE}
                                        className={css(styles.list)}>
                                        Qcast Examples
                                    </NavLink>
                                </li>
                            </ul>
                        </Col>

                        <Col
                            lg="2"
                            md="6"
                            xs="6"
                            sm="6"
                            className={css(styles.listColumn)}>
                            <ul className={css(styles.listMainView)}>
                                <li className={css(styles.listHeading)}>
                                    Company
                                </li>
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.TEAM}
                                        className={css(styles.list)}>
                                        Team
                                    </NavLink>
                                </li>
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.ABOUT}
                                        className={css(styles.list)}>
                                        About
                                    </NavLink>
                                </li>
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.CONTACT}
                                        className={css(styles.list)}>
                                        Contact
                                    </NavLink>
                                </li>
                                {/*  <li className={css(styles.listView)}>
                  <NavLink to={{pathname:ROUTES.BLOG}} className={css(styles.list)}>
                    Blog
                  </NavLink>
                </li> */}
                                {/*  <li className={css(styles.listView)}>
                  <NavLink to={ROUTES.CAREER} className={css(styles.list)}>
                    Careers
                  </NavLink>
                </li>
                <li className={css(styles.listView)}>
                  <NavLink to={ROUTES.PRESS} className={css(styles.list)}>
                    Press
                  </NavLink>
                </li> */}
                            </ul>
                        </Col>

                        <Col
                            lg="2"
                            md="6"
                            xs="6"
                            sm="6"
                            className={css(styles.listColumn)}>
                            <ul className={css(styles.listMainView)}>
                                <li className={css(styles.listHeading)}>
                                    Support
                                </li>
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.SITEMAP}
                                        className={css(styles.list)}>
                                        Sitemap
                                    </NavLink>
                                </li>
                                {/* <li className={css(styles.listView)}>
                  <NavLink
                    to={ROUTES.SUSTAINABILITY_AND_ENVIRONMENT}
                    className={css(styles.list)}
                  >
                    Sustainability and environment
                  </NavLink>
                </li>
                <li className={css(styles.listView)}>
                  <NavLink
                    to={ROUTES.CORPORATE_SOCIAL_RESPONSIBILITY}
                    className={css(styles.list)}
                  >
                    Corporate social responsibility
                  </NavLink>
                </li> */}
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.TERM_OF_USE}
                                        className={css(styles.list)}>
                                        Terms of Service
                                    </NavLink>
                                </li>
                                <li className={css(styles.listView)}>
                                    <NavLink
                                        to={ROUTES.COOKIE_POLICY}
                                        className={css(styles.list)}>
                                        Cookie Policy
                                    </NavLink>
                                </li>
                                {/* <li className={css(styles.listView)}>
                  <NavLink to={ROUTES.USE_OF_API} className={css(styles.list)}>
                    Use of API
                  </NavLink>
                </li> */}
                            </ul>
                        </Col>

                        <Col
                            lg="3"
                            md="6"
                            xs="6"
                            sm="6"
                            className={css(styles.listColumn)}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                            <div
                                style={{ marginTop: 5 }}
                                className={css(styles.minilistColumn)}>
                                <a
                                    href="https://www.linkedin.com/company/qcastquestions"
                                    target="_blank">
                                    <img
                                        src={
                                            IMAGES.SOCIAL_MEDIA_ICONS.LINKED_IN
                                        }
                                        width="46px"
                                        height="46px"
                                    />
                                </a>
                                <a
                                    href="https://www.facebook.com/QcastQuestions"
                                    target="_blank">
                                    <img
                                        src={IMAGES.SOCIAL_MEDIA_ICONS.FB}
                                        width="46px"
                                        height="46px"
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/QcastQuestions"
                                    target="_blank">
                                    <img
                                        src={IMAGES.SOCIAL_MEDIA_ICONS.TWITTER}
                                        width="46px"
                                        height="46px"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/qcastquestions/"
                                    target="_blank">
                                    <img
                                        src={IMAGES.SOCIAL_MEDIA_ICONS.INSTA}
                                        width="46px"
                                        height="46px"
                                    />
                                </a>
                            </div>
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <a
                                    href="https://apps.apple.com/app/qcast/id1583558024"
                                    target="_blank">
                                    <img
                                        src={IMAGES.DOWNLOAD_APP.APPLE_STORE}
                                        width="142px"
                                    />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.qcast"
                                    target="_blank">
                                    <img
                                        src={IMAGES.DOWNLOAD_APP.GOOGLE_STORE}
                                        width="142px"
                                    />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className={css(styles.copyRightContainer)}>
                <Container>
                    <Row className={css(styles.copyRightView)}>
                        <Col
                            lg="6"
                            md="12"
                            className={css(styles.copyRightText1)}>
                            <p className={css(styles.copyrightMsg)}>
                                Copyright © 2022 Qcast LTD. All rights reserved.
                            </p>
                        </Col>
                        <Col
                            lg="6"
                            md="12"
                            className={css(styles.copyRightText2)}>
                            <p className={css(styles.copyrightMsg)}>
                                <NavLink
                                    to={ROUTES.TERMS_AND_CONDITION}
                                    className={css(styles.list)}>
                                    Terms & Conditions
                                </NavLink>{' '}
                                |{' '}
                                <NavLink
                                    to={ROUTES.PRIVACY_POLICY}
                                    className={css(styles.list)}>
                                    Privacy Policy
                                </NavLink>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
