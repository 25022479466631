// @flow
import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  heading: {
    fontSize: 48,
    fontWeight: "700",
    fontFamily: "Poppins",
    color: "#23517B",
    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  subHeading: {
    fontSize: 20,
    marginTop: 40,
    color: "#6497C7",
    fontFamily: "Poppins",
    fontWeight: "600",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },

  description: {
    color: "#515a6b",
    fontSize: 20,
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },
});
