// @flow
import React from "react";
import CareerView from "./CareerView";

class CareerController extends React.Component {
  static propTypes = {};

  render() {
    return <CareerView {...this.props} />;
  }
}

export default CareerController;
