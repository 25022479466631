// @flow
import { StyleSheet } from 'aphrodite';
import { Colors } from '../../theme';

export default StyleSheet.create({
  pricingCard:{
    minHeight: "196px",
    width: "343px",
    borderRadius: "10px",
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: "5px 3px 10px 0px #0000002E",
    display:"flex",
    flexDirection:"column",
    padding:"12px 0px 0px 10px"
    //boxShadow: "-1px -3px 10px 0px #FFFFFFB2"



      },
      Row1:{
        display:"flex",
        marginRight:"10px"
      },
      Row2:{
        textAlign:"end",
        marginRight:"10px",
        //styleName: med+14,
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "18px",
        color: "#6497C7"

      },
      Row3:{
        //styleName: med+14;
        fontFamily: "Poppins",
        marginRight:"10px",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "21px",
        color: "#515A6B"

      },
      Row4:{
        //styleName: med+14,
        fontFamily: "Poppins",
        marginRight:"10px",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "21px",
        color: "#6497C7"


      },
      Row5:{
        display:"flex",
        marginTop:"auto",
        paddingTop:"5px"

      },
      h1:{
        
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#6497C7",
      },
      cost:{
        marginLeft:"auto",
        fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "27px",
      color: "#6497C7",
      },
      button:{
        marginLeft:"auto",
        cursor:"pointer",
        background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
        boxShadow: "-1px -3px 10px rgba(255, 255, 255, 0.7), 5px 3px 10px rgba(0, 0, 0, 0.18)",
        width:"116px",
        height:"44px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#6497C7",
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none"
        

      },
      viewFeature:{
        cursor:"pointer",
        display:"flex",
        alignItems:"center",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#6497C7",
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none",
        

      },
      options:{
        borderBottom: "1px solid rgba(151, 170, 195, 0.3)",
        height:"44px",
        width:"100%",
        display:"flex",
        alignItems:"center"
      },
      end:{
        width:"100%",
        height:"60px",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#6497C7",
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        MsUserSelect: "none",
        userSelect: "none"
      },
      optionsItem:{marginLeft:"auto",minWidth:"50px",textAlign:"center"}
});
