// @flow
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./SateliteVideoStyles";
import { WebHeader, WebFooter, VideoPlayer } from "../../components";
import { Images, AppStyles, Colors } from "../../theme";
import googlePlayIcon from "../../assets/images/googlePlayIcon/googlePlayIcon@3x.png";
import appleStoreIcon from "../../assets/images/appleStoreIcon/appleStoreIcon@3x.png";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import satalite from "../../assets/data/satalite";
import { ROUTES } from "../../constants";
import Tooltip from '@material-ui/core/Tooltip';
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${css(styles.nextArrow)}`}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} ${css(styles.prevArrow)}`}
      onClick={onClick}
    />
  );
}

export default function SateliteVideoView(props) {
  const { videoProp } = props;
  const [qcast, setQcast] = useState(videoProp.data.video[0])

  let videodata = videoProp.data.video.map((item, index) => {
    return <div className="vid" key={index}>
      <div style={{ width: "100%", height: '100%' }}>
        <VideoPlayer poster={item.poster} src={item.url} id={`${videoProp.tag}${index}`} />
      </div>

    </div>
  });
  let sliderOption
  if (videoProp.data.video.length > 3) {
    sliderOption = 3;
  } else {
    sliderOption = 1;
  }
  var settings = {
    afterChange: function (i) {
      setQcast(videoProp.data.video[i])
    }, dots: false,
    infinite: false,
    slidesToShow: sliderOption,
    className: 'slick-player',
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    centerPadding: "20px",
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },

      {
        breakpoint: 830,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },

      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <div>
      <WebHeader />

      <section className={css(styles.section)}>
        <ul className="steps">
          <li className="step">
            <Link to={ROUTES.HOME}>
              <i className="fa fa-home" aria-hidden="true"></i>
            </Link>
          </li>
          <li className="step">
            <Link to={ROUTES.SATELITE} >
              Qcast
            </Link>
          </li>

          <li className="step">
            <Link to={ROUTES.SATELITE + "/" + videoProp.video.tag} >
              {videoProp.data.name}
            </Link>
          </li>
          <li className="step">
            <a>
              {videoProp.data.question}
            </a>
          </li>
        </ul>
        <Container className={css(styles.customWidth)}>

          <Slider {...settings}>
            {videodata}

          </Slider>

          <div style={{ textAlign: "center" }}>
            <p className={css(styles.subHeading)}>{qcast.name.toUpperCase()}</p>
            <br />
            <p className={css(styles.download)}>Respond on the Qcast App. Download here.</p>
            <div style={{ paddingBottom: "40px" }}>
                <a href="https://play.google.com/store/apps/details?id=com.qcast" target="_blank" >
                  <img
                    src={googlePlayIcon}
                    style={{ marginRight: 10 }}
                    width="140px"
                  />
                </a>
                <a  href="https://apps.apple.com/app/qcast/id1583558024" target="_blank">
                  <img src={appleStoreIcon} width="142px" />
                </a>
            </div>

          </div>




        </Container>
      </section>

      <WebFooter />
    </div>
  );
}
