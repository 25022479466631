// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./TeamStyles";
import { WebHeader, WebFooter } from "../../components";
import { Container, Row, Col } from "react-bootstrap";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Slider from "react-slick";
const data=require('./TeamData');


export default function TeamView(props) {
  function createMarkup(para) {
    return {__html: para};
  } 

  let teamSingleDisplay=data.TeamData.map((item,index)=>{
    return <div key={index} className={css(styles.columnData)}>
      <Popup trigger={
      <div className={css(styles.insideData)}>
        
      <img src={item.image2} className={css(styles.image)} />
      <div className={css(styles.title)}>
      <h1 className={css(styles.detailTitle)}>{item.name}</h1>
              <h3 className={css(styles.detailSubTitle)}>
                {item.title}
              </h3>
              </div>
      </div>
    } modal nested>
    {close =>(<div className={css(styles.modal)+" team-root"}>
    <div className="closeroot" onClick={() => {
              console.log('modal closed ');
              close();
            }}>
          &times;
        </div>
      <div className={css(styles.modalHeader)}>
      <img src={item.image2} className={css(styles.modalHeaderImage)} />
      <div>
      <h1 className={css(styles.modaldetailTitle)}>{item.name}</h1>
              <h3 className={css(styles.modaldetailSubTitle)}>
                {item.title}
              </h3>
              </div>
      </div>
      <div>
    <p className={css(styles.detailDescription)} dangerouslySetInnerHTML={createMarkup(item.story)}>
     </p>
      </div>
    </div>)}
    
  </Popup>
      
        
          </div>
  })
  const { sliderOne, sliderTwo, slider1Ref, slider2Ref } = props;


  return (
    <div>
      <WebHeader />

      <Container>
        <div className={css(styles.sliderView)}>
          <div className={css(styles.newLayout)}>
          {teamSingleDisplay}
          </div>
          
        </div>
      </Container>

      <WebFooter />
    </div>
  );
}
