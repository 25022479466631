// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";
import RightArrow from "../../assets/images/sliderRightArrowIcon/sliderRightArrowIcon.png";
import LeftArrow from "../../assets/images/sliderLeftArrowIcon/sliderLeftArrowIcon.png";
export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 60,
  },

  heading: {
    color: "#23517B",
    fontSize: 48,
    fontFamily: "Poppins",
    fontWeight: "700",
    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  section2: {
    marginTop: 30,
  },

  subHeading: {
    color: "#6497C7",
    fontWeight: "700",
    fontSize: 32,
    fontFamily: "Poppins",
    "@media (max-width: 500px)": {
      fontSize: 26,
    },
    "@media (max-width: 400px)": {
      fontSize: 20,
    },
  },

  section2Row2: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
  },

  img: {
    marginLeft:"15px",
    width: "90%",
    minHeight:"300px",
    "@media (max-width: 991px)": {
      height: "auto",
    },
    "@media (max-width: 500px)": {
      height: "350px",
      width: "350px",
    },
    "@media (max-width: 400px)": {
      height: "300px",
      width: "300px",
    }
    
  },

  description: {
    fontSize: 20,
    color: "#515A6B",
    fontFamily: "Poppins",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },

  section3: {
    marginTop: 60,
    marginBottom: 40,
  },
  nextArrow: {
    background: "transparent",

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${RightArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -26,
      right:0
    },
    "@media (max-width: 990px)": {
      right:70
    },
    "@media (max-width: 650px)": {
      right: 25,
    },
  },

  prevArrow: {
    background: "transparent",
    zIndex: 9999,

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${LeftArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -26,
      left: 0,
    },
    "@media (max-width: 990px)": {
      left: 20,
    },
    "@media (max-width: 650px)": {
      left: 20,
    },
  },
});
