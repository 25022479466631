// @flow
import { StyleSheet } from "aphrodite";
import { Colors } from "../../theme";

export default StyleSheet.create({
  container: {
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow: " 5px -3px 10px rgba(0, 0, 0, 0.1);",
  },

  customWidth: {
    maxWidth: 1120,
  },

  footerMainView: {
    paddingTop: 20,
    paddingBottom: 20,
  },

  description: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#515A6B",
    marginTop: 15,
    width: "90%",
    "@media (max-width:980px)": {
      width: "100%",
    },
  },
  minilistColumn:{
"@media (max-width:430px)": {
      width: "70%",
    },
  },
  listColumn: {
    "@media (max-width:980px)": {
      textAlign: "center",
    },
  },listColumnLeft: {
    "@media (max-width:980px)": {
      textAlign: "center",
    },"@media (max-width:500px)": {
      textAlign: "unset",
    }
  },

  listMainView: {
    marginTop: 10,
    paddingLeft: 0,
  },

  listHeading: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "Poppins",
    marginBottom: 12,
    listStyle: "none",
    color: "#2C3037",
  },

  listView: {
    fontSize: 14,
    fontFamily: "Poppins",
    marginBottom: 12,
    listStyle: "none",
  },

  list: {
    color: "#7E4BC9",
    textDecoration: 'underline',
  },

  copyRightContainer: {
    background: "#DCE8F4",
    paddingTop: 5,
    paddingBottom: 5,
  },

  copyRightView: {
    justifyContent: "space-between",
    "@media (max-width:980px)": {
      justifyContent: "unset",
    },
  },

  copyrightMsg: {
    color: "#515A6B",
    marginBottom: 0,
    // textAlign: "center",
    fontFamily: "Poppins",
    padding: 10,
  },

  copyRightText1: {
    textAlign: "left",
    "@media (max-width:980px)": {
      textAlign: "center",
    },
  },

  copyRightText2: {
    textAlign: "right",
    "@media (max-width:980px)": {
      textAlign: "center",
    },
  },
});
