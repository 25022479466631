// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 50,
  },

  heading: {
    color: "#23517B",
    fontSize: 48,
    fontFamily: "Poppins",
    fontWeight: "700",
    textAlign: "center",

    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 34,
    },
  },
  heading2: {
    color: "#23517B",
    fontSize: 30,
    fontFamily: "Poppins",
    fontWeight: "700",
    textAlign: "center",

    "@media (max-width: 500px)": {
      fontSize: 25,
    },
    "@media (max-width: 400px)": {
      fontSize: 20,
    },
  },
  subHeading: {
    fontSize: 20,
    fontWeight: "600",
    fontFamily: "Poppins",
    color: "#515A6B",
    textAlign: "center",
    marginTop: 10,
    letterSpacing: 2,
  },

  textInputView: {
    background: "#EFF6FC",
    boxShadow:
      "inset -2px -5px 5px rgba(255, 255, 255, 0.7), inset 2px 5px 5px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    width: "70%",
    margin: "auto",
    paddingTop: 18,
    paddingBottom: 18,
    paddingRight: 15,
    paddingLeft: 15,
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
  },

  textInput: {
    background: "#EFF6FC",
    width: "100%",
    marginLeft: 10,
    border: "none",
    color: "#A177F5",
    fontSize: 14,
    paddingRight: 8,
    paddingLeft: 8,

    "::placeholder": {
      color: "#A177F5",
    },
    ":focus": {
      outline: "none",
    },
  },

  section2: {
    marginTop: 60,
    marginBottom: 60,
  },

  faqHeadingView: {
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  faqHeading: {
    color: "#515A6B",
    fontSize: 20,
    fontFamily: "Poppins",
    fontWeight: "600",
    cursor:"pointer"
  },

  description: {
    fontSize: 16,
    color: "#515A6B",
    fontFamily: "Poppins",
  },
});
