// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";
import backgroundImage from "../../assets/images/about-image-2/about-image-2.png";
import RightArrow from "../../assets/images/sliderRightArrowIcon/sliderRightArrowIcon.png";
import LeftArrow from "../../assets/images/sliderLeftArrowIcon/sliderLeftArrowIcon.png";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },
  getStartedText:{
    color: "#6497C7"
  },
  section1: {
    marginTop: 70,
    "@media (max-width: 980px)": {
      marginTop: 40,
    },
  },

  
  heroSectionHeading: {
    fontSize: 44,
    color: "#23517B",
    //textAlign: "center",
    fontFamily: "Poppins",
    fontWeight: "700",
    "@media (max-width: 500px)": {
      textAlign:"center",
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      textAlign:"center",
      fontSize: 28,
    },
  },

  customheroSectionPara: {
    fontSize: 24,
    color: "#515A6B",
    fontWeight:"bold",
    margin: '0px',
    //textAlign: "center",
    //marginTop: 10
    width:'60%',
    textAlign:"center",
    margin:"0 auto",
    fontFamily: "Poppins",
    "@media (max-width: 700px)": {
      width:'100%',
    },
    "@media (max-width: 580px)": {
      fontSize: 18,
      width:'100%',
    },
    "@media (max-width: 400px)": {
      fontSize: 16,
      width:'100%',
    },
  },
  heroSectionPara: {
    fontSize: 20,
    color: "#515A6B",
    //textAlign: "center",
    marginTop: 10,
    fontFamily: "Poppins",
    "@media (max-width: 400px)": {
      fontSize: 14,
      textAlign:"center"
    },
  },
  section2: {
    marginTop: 50,
    paddingTop: 50,
    paddingBottom: 70,
    "@media (max-width: 980px)": {
      marginTop: 20,
      paddingTop: 0,
      paddingBottom: 0,
    },
    
  },

  section3: {
    marginTop: 50,
    paddingTop: 50,
    paddingBottom: 70,
    flexDirection: "row-reverse",
    "@media (max-width: 980px)": {
      flexDirection: "unset",
      marginTop: 20,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },

  img1: {
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto",
    right: 0,
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "50%",
      display: "flex",
    },
    "@media (max-width: 600px)": {
      width: "70%",
    },
  },

  section2Heading: {
    fontSize: 42,
    // color: "#6497C7",
    fontWeight: "700",
    fontFamily: "Poppins",
    "@media (max-width: 400px)": {
      textAlign:"center",
      fontSize: 28,
    },
  },

  section2Para: {
    fontSize: 20,
    color: "#515A6B",
    marginTop: 20,
    fontFamily: "Poppins",
    "@media (max-width: 400px)": {
      fontSize: 14,
      textAlign:"center"
    },
  },

  img2View: {
    //height: 480,
  },

  img2: {
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "50%",
      display: "flex",
    },
    "@media (max-width: 600px)": {
      width: "70%",
    },
  },
  img4: {
    // position: "absolute",
    // top: 0,
    // bottom: 0,
    // margin: "auto",
    // left: 0,
    "@media (max-width: 980px)": {
      position: "relative",
      textAlign: "center",
      width: "50%",
      display: "flex",
    },
    "@media (max-width: 600px)": {
      width: "70%",
    },
  },
  lastSection: {
    marginTop: 160,
    paddingTop: 30,
    paddingBottom: 30,
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    width: "100%",
    backgroundSize: "cover",
    "@media (max-width: 980px)": {
      marginTop: 60,
    },
  },

  authorHeading1: {
    color: "#515A6B",
    fontWeight: "700",
    fontSize: 50,
    fontFamily: "Poppins",
    "@media (max-width: 600px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },
  authorHeading2: {
    color: "#6497C7",
    fontWeight: "700",
    fontFamily: "Poppins",
    fontSize: 50,
    "@media (max-width: 600px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 36,
    },
  },

  authorView: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
  },

  authorDetailView: {
    width: "50%",
    marginLeft: 15,
    "@media (max-width: 400px)": {
      width: "70%",
    },
  },

  authorText: {
    marginBottom: 3,
    fontSize: 20,
    lineHeight: 1.3,
    color: "#515A6B",
    fontFamily: "Poppins",
    fontWeight: "500",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 16,
    },
  },

  authorName: {
    marginBottom: 3,
    fontSize: 20,
    lineHeight: 1.3,
    fontWeight: "700",
    color: "#2c3037",
    fontFamily: "Poppins",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 16,
    },
  },

  ourJourneyView: {
    marginTop: 80,
    marginBottom: 40,
  },

  sliderHeading: {
    fontSize: 18,
    color: "#6497C7",
    fontFamily: "Poppins",
    fontWeight: "700",
  },

  sliderDescription: {
    fontSize: 14,
    color: "#515A6B",
    fontFamily: "Poppins",
  },
  nextArrow: {
    backgroundImage: `url(${RightArrow})`,
    width: 100,
    height: 100,
    backgroundSize: "100% 100%",
    cursor: "pointer",
    position: "absolute",
    top: "-6%",
    left: "auto",
    right: 0,
    marginLeft: "auto",
    marginTop: 20,
    "@media (max-width: 767px)": {
      // right: "10%",
    },
    "@media (max-width: 520px)": {
      // right: 0,
    },
  },

  prevArrow: {
    backgroundImage: `url(${LeftArrow})`,
    width: 100,
    height: 100,
    backgroundSize: "100% 100%",
    cursor: "pointer",
    position: "absolute",
    top: "-6%",
    left: 0,
    right: "auto",
    marginLeft: "auto",
    marginTop: 20,
    "@media (max-width: 767px)": {
      // right: "20%",
    },
    "@media (max-width: 520px)": {
      // right: "15%",
    },
  },
});
