// @flow
import React from "react";
import UseOfAPIView from "./UseOfAPIView";

class SustainabilityAndEnvironmentController extends React.Component {
  static propTypes = {};

  render() {
    return <UseOfAPIView {...this.props} />;
  }
}

export default SustainabilityAndEnvironmentController;
