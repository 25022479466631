// @flow
import React,{useState} from 'react';
import _ from 'lodash';
import { css } from 'aphrodite/no-important';
import styles from './styles';
export default class VideoPlayer extends React.PureComponent {





  render() {
    let y=0;
    let duration=0;
    let cas=this.props.id;
    let poster
    if(this.props.poster){
      poster=this.props.poster
    }else{
      poster=null
    }
    function checkAllVideoStatus(){
      let videos=document.getElementsByTagName("video")
      for(const video of videos){
        if(video && video.paused == false){
          video.pause();
          //document.getElementById(cas+"c").classList.remove('fa-pause');
          //document.getElementById(cas+"c").classList.add('fa-play');
        }
      }
      let playStatus=document.getElementsByClassName("fa-pause")
      for(const playStat of playStatus){
        playStat.classList.remove('fa-pause');
        playStat.classList.add('fa-play');
      }

    }
    function play() {
      var video = document.getElementById(cas);
      if (video) {
        let myInterval
        if (video.paused == true) {
          checkAllVideoStatus()
          video.play();
          document.getElementById(cas+"c").classList.add('fa-pause');
          document.getElementById(cas+"c").classList.remove('fa-play');
          duration=video.duration;
          //interval
          video.myInterval=setInterval(function () {
            y=video.currentTime;
            if(y>=duration || video.paused){
              if(y>=duration){
                y=0;
                video.pause()
                document.getElementById(cas+"c").classList.remove('fa-pause');
                document.getElementById(cas+"c").classList.add('fa-play');
                video.currentTime =0
              }
              clearInterval(video.myInterval)
            }else{
              document.getElementById(cas+"h").style=`--p:${(y/duration)*100};--b:15px;--w:100%`
            }
            
          }, 10);
        } else {
          video.pause();
          document.getElementById(cas+"c").classList.remove('fa-pause');
          document.getElementById(cas+"c").classList.add('fa-play');
         
        }
      }
      
    }

    return (
  
      <div className={css(styles.skill)}>
        <div className={css(styles.outer)}  onClick={function () {
            play();
          }}>
        <div className="circular-loader no-round" id={this.props.id+"h"} style={{'--p':0,'--b':"15px",'--w':'100%'}}>
          
        </div>
          <div className={css(styles.inner)}>
            
            <div className={css(styles.rc)}>
            <div className={` ${css(styles.playPause)}`} style={{}}>
              <i className='fa fa-play' id={this.props.id + "c"}></i>
            </div>
              <video poster={poster} src={this.props.src} id={this.props.id}  className={css(styles.videoCircle)} playsInline="playsinline">
                
              </video>
            </div>
          </div>
        </div>
     
      </div>
      
    );
  }
}
