// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";
import RightArrow from "../../assets/images/sliderRightArrowIcon/sliderRightArrowIcon.png";
import LeftArrow from "../../assets/images/sliderLeftArrowIcon/sliderLeftArrowIcon.png";

export default StyleSheet.create({
  section:{
    background: 'linear-gradient(171.89deg, #F0F6FB 2.42%, #E3F1FE 96.87%)',
    paddingBottom: "30px"

  },
  customWidth: {
    maxWidth: 1120,
    minHeight: 500,
    paddingTop:"20px"
  },
  vid:{
    width:'250px',
    height:'250px',
  }, subHeading: {
    fontSize: 20,
    textAlign: "center",
    color: "#6497C7",
    fontFamily: "Poppins",
    fontWeight: "600",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },
  heading: {
    fontSize: 75,
    color: "#FF0000",
    fontWeight: "700",
    fontFamily: "Poppins",
    textAlign: "center",
    "@media (max-width: 767px)": {
      fontSize: 50,
    },
    "@media (max-width: 400px)": {
      fontSize: 38,
    },
  }, nextArrow: {
    background: "transparent",

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${RightArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -26,
    },

    "@media (max-width: 650px)": {
      right: 25,
    },
  },

  prevArrow: {
    background: "transparent",
    zIndex: 9999,

    ":hover": {
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%);",
    },

    ":before": {
      fontSize: 25,
      backgroundImage: `url(${LeftArrow})`,
      width: 50,
      height: 50,
      position: "absolute",
      backgroundSize: "100% 100%",
      color: "transparent",
      top: -32,
      left: -25,
    },

    "@media (max-width: 650px)": {
      left: 20,
    },
  },



  //new UI Interface #6497C7
  subHeading: {
    fontSize: 20,
    marginTop: 10,
    color: "#23517B",
    fontFamily: "Poppins",
    fontWeight: "600",
    "@media (max-width: 500px)": {
      fontSize: 20,
    },
    "@media (max-width: 400px)": {
      fontSize: 18,
    },
  },
  titleFont: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    color: "#6497C7",
    fontFamily: "Poppins",
    fontWeight: "500",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
    "@media (max-width: 400px)": {
      fontSize: 8,
    },
  },descriptionFont: {
    fontSize: 12,
    marginTop: 5,
    textAlign:"center",
    marginBottom: 5,
    color: "rgb(81, 90, 107)",
    fontFamily: "Poppins",
    fontWeight: "500",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
    "@media (max-width: 400px)": {
      fontSize: 8,
    },
  },mainTitleFont: {
    fontSize: 12,
    marginTop: 5,
    marginBottom: 5,
    textAlign:"center",
    color: "#6497C7",
    fontFamily: "Poppins",
    fontWeight: "500",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
    "@media (max-width: 400px)": {
      fontSize: 8,
    },
  },genreTitleFont: {
    fontSize: 12,
    marginTop: 5,
    marginBottom:44,
    textAlign:"center",
    color: "#6497C7",
    fontFamily: "Poppins",
    fontWeight: "500",
    "@media (max-width: 500px)": {
      fontSize: 10,
    },
    "@media (max-width: 400px)": {
      fontSize: 8,
    }
  },
    majorFormContainer:{
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      alignItems:"center",
      alignContent:"center",
      maxWidth:240,
      marginTop:15,
      marginBottom:15,
     },
     majorFormImage:{
      display:"flex",justifyContent:"center",
      
      alignItems:"center",width:230,height:230,borderRadius:"50%",border: "solid 1px #ECF5FC",backgroundColor:"#ECF5FC",
      boxShadow:"0 0 10px  rgba(0,0,0,0.6)",MozBoxShadow: "0 0 10px  rgba(0,0,0,0.6)",
      WebkitBoxShadow: "0 0 10px  rgba(0,0,0,0.6)",
      OBoxShadow: "0 0 10px  rgba(0,0,0,0.6)"
    },
    majorFormLink:{
      background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
      boxShadow:
        "-1px -3px 10px rgba(255, 255, 255, 0.7), 5px 3px 10px rgba(0, 0, 0, 0.18)",
      borderRadius: 100,
      width: "140px",
      paddingTop: 10,
      paddingBottom: 10,
      border: "none",
      marginTop: 10,
      display:"flex",justifyContent:"center",
      alignItems:"center",
      color: "#7B94B5",
      fontSize: 16,
      fontFamily: "Poppins",
      fontWeight: "700",
      position: "absolute",
      bottom: 0,
      textDecoration:"none"

    }
  
});
