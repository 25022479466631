// @flow
import { StyleSheet } from 'aphrodite'

export default StyleSheet.create({
    view: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#515A6B',
        paddingTop: 30,
        '@media (max-width: 480px)': {
            textAlign: 'center',
        },
    },
})
