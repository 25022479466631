// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },
  mobilePricing:{
    display:"none",
    "@media (max-width:722px)": {
      display: "flex",
      justifyContent:"center",
      alignItems:"center",
      marginTop:"10px"
    }
  },
  mainContainer:{
    display:"flex",
    flexDirection:"column",
    border:"1px solid rgb(151, 170, 195,1)",
    borderRadius:"5px",
    "@media (max-width:722px)": {
      display: "none",
    },
  },
  pricingBreak:{
    height:44,
    backgroundColor:"#D2E5F3",
    width:"100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  street:{
    minHeight:34,
    display:"flex",
    justifyContent: "space-between"
  },
  streetParagph:{
textAlign:"center",
height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  streetHouse:{
    flex: 1,
    borderTop: "1px solid rgb(151, 170, 195,0.3)",
    borderBottom: "1px solid rgb(151, 170, 195,0.3)",
    borderLeft: "1px solid rgb(151, 170, 195,1)",
    borderRight: "1px solid rgb(151, 170, 195,1)",
    paddingLeft:30.5
  },
  priceHeader:{
    display:"flex"
  },








  section1: {
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor:"#ECF5FC"
  },
  topMainSection:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    textAlign: "center",
  },
topLogo:{
  width:"183px"
},
mainHeaderParagraph1:{
  color:"#23517B",
  fontFamily:"Poppins",
  fontWeight:"700",
  fontStyle:"normal",
  fontSize:"48px",
  lineHeight:"72px"
},
mainHeaderParagraph2:{
color:"#515A6B",
fontFamily:"Poppins",
  fontWeight:"400",
  fontStyle:"normal",
  fontSize:"24px",
  lineHeight:"36px"
},
mainHeaderParagraph3:{

  color:"#515A6B",
  fontFamily:"Poppins",
    fontWeight:"700",
    fontStyle:"normal",
    fontSize:"32px",
    lineHeight:"48px"
  },
  Container: {
    display: "flex",
    alignItems: "center",

    "@media (max-width:980px)": {
      display: "block",
    },
  },

  topSection: {
    flex:1,
    borderTop: "1px solid rgb(151, 170, 195,0.3)",
    borderBottom: "1px solid rgb(151, 170, 195,0.3)",
    borderLeft: "1px solid rgb(151, 170, 195,1)",
    borderRight: "1px solid rgb(151, 170, 195,1)",
    padding:"15px 16px 25px 17px ",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    "@media (max-width:980px)": {
      textAlign: "center",
    },
  },

  topSectionHeading: {
    color: "#7B94B5",
    fontWeight: "700",
    fontSize: 20,
    fontFamily: "Poppins",
  },

  topSectionHeading2: {
    color: "#6497C7",
    fontWeight: "700",
    fontSize: 42,
    fontFamily: "Poppins",
  },

  monthText: {
    fontSize: 14,
    fontWeight: "normal",
  },

  topSectionDescription: {
    fontSize: 13,
    fontFamily: "Poppins",
    marginBottom:"10px",
    color:"#6497C7",
    "@media (max-width:1367px)": {
      fontSize: 11,
    },
  },topSectionDescriptiongray: {
    color: "#515A6B",
    fontSize: 13,
    fontFamily: "Poppins",
    marginBottom:"10px",
    "@media (max-width:1367px)": {
      fontSize: 11,
    },
  },

  getStartButton: {
    background: "linear-gradient(135.07deg, #F1F9FF 6.66%, #DCE8F7 104.83%)",
    boxShadow:
      "-1px -3px 10px rgba(255, 255, 255, 0.7), 5px 3px 10px rgba(0, 0, 0, 0.18)",
    borderRadius: 100,
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    border: "none",
    marginTop: 10,
    color: "#7B94B5",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "700",
  },getStartButtonInactive: {
    backgroundColor:"transparent",
    borderRadius: 100,
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    border: "none",
    marginTop: 10,
    color: "#7B94B5",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: "700",
  },

  mainViewForPricing: {
    background: "#ECF5FC",
    boxShadow: "-3px -3px 10px #FFFFFF, 3px 4px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: 15,
  },

  mainView: {
    "@media (max-width:980px)": {
      width: "100%",
      marginTop: 30,
    },
  },

  mainViewForHeading: {
    "@media (max-width:980px)": {
      display: "none",
    },
  },

  gridRow: {
    height: 45,

    "@media (max-width:980px)": {
      height: "auto",
      paddingTop: 10,
      paddingBottom: 10,
    },
  },

  heading: {
    color: "#515A6B",
    fontFamily: "Poppins",
    fontSize: 16,
  },

  icon: {
    display: "flex",
    margin: "auto",

    "@media (max-width:980px)": {
      //display: "none",
    },
  },

  responsiveHeading: {
    fontFamily: "Poppins",
    textAlign: "center",
    display: "none",

    "@media (max-width:980px)": {
      display: "block",
    },

    "@media (max-width:50px)": {
      fontSize: 13,
    },
  },
});
