// @flow
import React from "react";
import { NavLink,Link } from "react-router-dom";
import { css } from "aphrodite";
import styles from "./FaqStyles";
import { WebHeader, WebFooter } from "../../components";
 import { Images, AppStyles, Colors } from "../../theme";
import { Container, Row, Col, Accordion, Card } from "react-bootstrap";
import searchIconBlue from "../../assets/images/searchIconBlue/thumb_Search_button.png";
import faqImg from "../../assets/images/faqImg/faqImg.png";
import { ROUTES } from "../../constants";

export default function FaqView(props) {
  return (
    <div>
      <WebHeader />

      <section className={css(styles.section1)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
              <h1 className={`${css(styles.heading)} HeadingGradient`}>How can we help?</h1>
              <h3 className={css(styles.subHeading)}>
                Select a topic or search below to get started.
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={css(styles.textInputView)}>
                <img src={searchIconBlue} className={css(styles.searchIcon)} />
                <input
                  type="text"
                  className={css(styles.textInput)}
                  placeholder={"Search a question"}
                />
              </div>
            </Col>
          </Row>
        </Container>

      </section>

      <section className={css(styles.section2)}>
        <Container className={css(styles.customWidth)}>
          <Row>
            <Col>
            
              <Accordion defaultActiveKey="0">
                <Card style={{ marginTop: 10 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    What is Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Qcast is a new social network designed to promote collaboration. 
                          It offers a space to ask the questions on your mind – 
                          enabling you to inspire insights, answers and understanding.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Who should use Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Qcast is for anyone and everyone who nurtures curiosity. In Academia, 
                          Qcast enables you to reach out to colleagues around the world 
                          and to promote interdisciplinary thinking. 
                          In business, Qcast helps brands to understand their customers. 
                          For the third sector (non-profit), 
                          Qcast helps you to canvas and connect with your audiences. 
                          Off the clock, Qcast creates a fun environment in
                          which you could be interviewed by your favourite celebrity!
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="2"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Is Qcast free to use?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          It’s free to download the Qcast app and 
                          it’s free to post and respond to Qcasts. 
                          It’s free to follow your favourite Qcasters 
                          and it's free to share Qcast content to your other social networks. 
                          That’s all included in our basic plan.
                          </p>
                          <p className={css(styles.description)}>
                          We also offer two more plans: pro and business. 
                          Subscribing to our pro service gives you additional benefits, 
                          like being able to edit the Qcasts you produce in the app and 
                          extra reporting tools so you can track the responses to the Qcasts you post.
                          </p>
                          <p className={css(styles.description)}>
                          Our business plan has been specially designed to meet 
                          the needs of our corporate clients and researchers, 
                          enabling you to run private Qcast networks so your corporate users’ 
                          Qcasts remain secure and confidential for internal use. 
                          For more information about this service, 
                          please contact our enterprise sales team on: info@qcast.io.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="3"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I set up a Qcast account?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Download the Qcast app from either the Apple App Store 
                          or Google Play app store. Simply enter your name, 
                          email address and a password and you can get started 
                          connecting with Qcasters around the world.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="4"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I create a Qcast profile?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Upon logging into Qcast for the first time, 
                          you will be prompted to create an account by 
                          uploading a profile picture, entering your name, 
                          a description and a short ‘Qcaster motto’. 
                          Your profile will express your sense of curiosity
                          or maybe your sense of insight for all who visit your profile.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="5"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I make changes to my Qcast account?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          At the bottom of the app screen, you can see the navigation bar. 
                          The ‘Settings’ icon can be found at the furthest right of the four 
                          icons along the navigation bar. Clicking on this icon allows you to edit:
                          Notifications (view notifications; turn push notifications on or off)
                          Secure login (biometric: turn fingerprint unlock on or off; turn facial unlock on or off)
                          Subscription (choose between our free plan, our pro plan or our business plan; 
                          choose monthly or annual subscriptions for our pro or business plans)
                          Help and Info (here’ll you’ll find our FAQs)
                          Contact Qcast (get in touch with our team)
                          Report (use this to report another user or to report offensive or inappropriate content to our team)
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="6"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    What are the differences between the free, pro and business plans?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Additional benefits are given to both the Pro and Business plans at a cost. 
                          These benefits can be viewed on our subscription page in Settings.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="7"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I navigate around Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          There are three main elements to Qcast and you can use 
                          the three buttons at the top of the screen to find your way to each:
                          Discover: This is where you’ll find the latest Qcasts. 
                          They’ll be organised by spotlighted Qcasts, most popular Qcasts, 
                          by topic, and by most popular Qcaster. 
                          You can also freely search to find particular Qcasters or Qcasts.
                          Channel: This is your Qcast channel. 
                          Your profile appears at the top. Below your profile, 
                          you can see Qcasts from the Qcasters to whom you have subscribed.
                          Respond: This is where you can see lists of your own 
                          Qcasts: ‘drafts’, which are unpublished; ‘private’, 
                          for sending to family or friends privately; and ‘published’ 
                          Qcasts. This is your own Qcast broadcasting platform. 
                          Here you can manage all your interactions on Qcast. 
                          You can also use the navigation bar at the bottom of the screen 
                          to find your way around Qcast. It offers four options:
                          Discover: this takes you back to the Discover page.
                          Qcam: click this to record a new Qcast.
                          Messages: view your communication with other Qcasters and send and receive messages.
                          Settings: takes you to options to manage your account and contact our team.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="8"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I record and post a Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          It’s easy to record a Qcast. Just click on the Qcam 
                          icon in the navigation bar at the bottom of your app screen. 
                          You’ll be prompted to record your Qcast questions one by one.
                          Every time you record a question, you’ll be counted down from five. 
                          Then recording will start. You have 60 seconds (in the free Standard plan) 
                          in which to record each of your questions. And you can record a maximum of twelve questions per Qcast.
                          Once you’ve recorded and published your Qcast, 
                          your subscribers on the platform will receive a notification so they can respond. 
                          Why not message your Qcast contacts directly to invite responses? 
                          This is especially useful if there’s a particular someone from whom you’d like to elicit a response.
                          You can also feedback your thoughts to the Responders to your Qcast 
                          who have piqued your interest or set you thinking. Comment, star or save the responses that you love.
                          Or: why not share it to your other social media channels? 
                          Simply click on your published Qcast and click on the ‘Share’ option. 
                          This way, you can invite and encourage your followers and 
                          connections on other social sites to respond to your Qcast.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="9"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How can I use prompts to help me record my Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Since you can ask up to 12 questions per Qcast, 
                          we’ve added the ability for you to enter some text prompts to guide your recording. 
                          You can think of them as an auto-cue for your Qcasts. 
                          Each of your prompts will display in turn as you record your Qcast questions.
                          </p>
                          <p className={css(styles.description)}>
                          To create your prompts, simply click on the Qcam icon on the navigation bar. 
                          Under the Qcam circle, you’ll see the ‘Load Prompts’ button. 
                          Clicking on ‘Load Prompts’ will take you to the Prompts page 
                          where you can create new prompts and edit old ones. 
                          Once you’ve created or selected the prompts you want click ‘Load Prompt’ 
                          at the bottom of the screen. This will load your prompt for 
                          each question at the top of the screen, above your Qcast circle. 
                          You’re now ready to record your Qcast. 
                          As you record, click ‘next’ or ‘prev’ between recording 
                          your questions and your prompts will slide on.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="10"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Can I edit my Qcasts before publishing them?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="10">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          You’re always given an option to review and edit your Qcast before you save it. 
                          You have the option to delete entire questions or to trim the video captured 
                          for each individual question. When you’re happy with your edit, click ‘Done’ 
                          and you’ll be taken to the review screen where you can save your 
                          Qcast as a draft, a private Qcast or a published Qcast.
                          Alternatively, if you wish to go back later – after recording and saving your Qcast – 
                          you can use the edit option. Find the Qcast you wish to 
                          edit and click the arrow below it to reveal the following options:
                          <ul>
                            <li>Forward</li>
                            <li>Share</li>
                            <li>Publish</li>
                            <li>Download</li>
                            <li>Edit</li>
                            <li>Delete</li>
                          </ul>
                          Choose the ‘Edit’ option and you will be taken to the edit 
                          screen where you can choose to either delete entire questions or to 
                          trim the video captured for each individual question. Click ‘Done’, review and save.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="11"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I save my Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="11">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          After you’ve recorded your Qcast questions, 
                          you’re automatically taken through the Edit, Review and Save process. 
                          When you’ve edited and reviewed and are happy with your Qcast, 
                          click on the ‘Save’ button at the bottom of the Review screen. 
                          The Save screen asks you to:
                          <ul>
                            <li>add a title</li>
                            <li>select a topic from the drop-down list</li>
                            <li>enter a short description about your Qcast (up to180 words)</li>
                            <li>add a tag (choose your tags wisely as these will be a way people find your content on Qcast!)</li>
                            <li>add a cover thumbnail to your Qcast</li>
                            <li>record an intro to your Qcast (this will play on the profile page of your Qcast)</li>
                            <li>record an outro to your Qcast (sharing final thoughts about your questions)</li>
                            <li>Then you can save your Qcast as a draft, a private Qcast or publish your Qcast.</li>
                          </ul>
                          
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="12"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How are the images chosen for my Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="12">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Before you publish your Qcast, you can choose a cover thumbnail. 
                          By default, this will be a thumbnail from that Qcast. 
                          The app will offer you a selection from which to choose. 
                          These images are taken from the video you recorded for your Qcast. 
                          Simply search and select the still you prefer.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="13"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Are there any time limits on Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="13">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Yes, there are limits to your Qcast questions and responses.
                          Qcasters have up to 60 seconds to ask a question, 
                          on the standard plan, 2 minutes on the Pro plan and 
                          5 minutes on the Business plan (See Subscription page)
                          And you have up to five minutes to respond to a Qcast question.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="14"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Do I have to use video?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="14">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Whenever you record a Qcast or a Response, you will record a video. 
                          However, if you’re listening to a Qcast that has already been published, 
                          it is possible to turn off the video. This is handy if you have 
                          a bad connection or simply want to save data. 
                          Underneath the Qcast circle, on the left of the screen, 
                          you’ll see a toggle. By clicking on the toggle, 
                          you can switch between video and audio only.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="15"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How can I respond to a Qcast?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="15">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          If you want to respond to a Qcast, you can do that 
                          straight from the Qcast by hitting the ‘Respond’ button.
                          If you aren’t already viewing the Qcast, we recommend that 
                          you hit the ‘Respond’  button at the top of the home page. 
                          This will take you to a view of:
                          <ul>
                            <li>the Qcasters you follow</li>
                            <li>the individual Qcasts you have saved and</li>
                            <li>the Qcast Responses you’ve already recorded</li>
                          </ul>
                          </p>
                          <p>
                          Once you’ve found the Qcast to which you wish to publish a response, simply click on ‘Load Qcast’.
                          </p>
                          <p>
                          
                          Click on the ‘Record’ button. You’ll hear the first question from your Qcaster. 
                          Respond to their question and, when you’re ready, 
                          click on the ‘Next Question’ button. Continue responding to the 
                          questions in this way until you’ve answered them all. 
                          Your Qcast response will be recorded as a continuous video 
                          that incorporates both the questions and your responses to them, 
                          so it plays back as a conversation between you and your chosen Qcaster. 
                          When you’ve finished, you have the opportunity to edit your response. 
                          Add a title and select the topics tags. Add a description and thumbnail 
                          image to accompany it from the selection offered. 
                          Then save as a draft Qcast response, a private Qcast response or publish.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="16"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I publish my saved Qcast Responses?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="16">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          If you now go to your Channel, you can view all your Qcasts and Qcast Responses. 
                          Your responses appear in three separate groups:
                          <ul>
                            <li>Drafts</li>
                            <li>Private</li>
                            <li>Published</li>
                            </ul>
                          
                          </p>
                          <p>
                          To publish a draft, click on the draft Qcast you wish to publish. You’ll be given six options: 
                          <ul>
                            <li>Forward</li>
                            <li>Share</li>
                            <li>Publish</li>
                            <li>Download</li>
                            <li>Edit</li>
                            <li>Delete</li>
                            </ul>
                          </p>
                          <p>
                          Simply select ‘Publish’ and your response will be live. 
                          Qcasters will be now be able to see your response in your 
                          channel as well as underneath the Qcast to which you responded.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="17"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I share Qcasts?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="17">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          You can share your own Qcasts and those of other Qcasters. 
                          Click on the arrows beneath the Qcast you want to share and you’ll be given six options: 
                          <ul>
                            <li>Forward</li>
                            <li>Share</li>
                            <li>Publish</li>
                            <li>Download</li>
                            <li>Edit</li>
                            <li>Delete</li>
                          </ul>
                          
                          </p><p>
                          Either choose ‘Forward’ to forward the Qcast directly to your contacts. 
                          Or choose Share to publish the Qcast to your other social media channels. 
                          Download will save your Qcast videos to your mobile device.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="18"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I delete one of my Qcasts?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="18">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          You can delete your own Qcasts by clicking on 
                          the arrows beneath the Qcast you want to delete 
                          and selecting Delete from the dropdown menu.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="19"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I mark a Qcast as a favourite?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="19">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          As you watch a Qcast, simply click on the star 
                          icon under the Qcast circle and it will be saved 
                          to your channel’s favourite list.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="20"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I find out about the Qcasts of my favourite Qcasters?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="20">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          The best way to see the Qcasts published by your favourite 
                          Qcasters is to subscribe to their Qcast channel. 
                          Find their profile and click on the ‘Subscribe’ button – 
                          it’s that easy. Their Qcasts will then appear in your channel. 
                          If you want to be the first to know, go to settings and turn 
                          on notifications. This way, you’ll be notified immediately 
                          when your favourite Qcasters publish new Qcasts.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="21"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Can I save my Qcasts locally to watch offline?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="21">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Yes, click on the Qcast you want to save. You’ll see the six options: 
                          <ul>
                              <li>Forward</li>
                              <li>Share</li>
                              <li>Publish</li>
                              <li>Download</li>
                              <li>Edit</li>
                              <li>Delete</li>

                          </ul>
                          </p>
                          <p>
                          Click on ‘Download’ and your Qcast will be saved onto your device.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="22"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Is it possible to send or share multiple Qcasts with my contacts?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="22">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Yes! This is what the playlist feature allows you to do. 
                          Click on the ‘Create Playlist’, add the Qcasts you want to 
                          share to your playlist, then ‘Share’ or ‘Publish’ your playlist.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="23"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How can I subscribe to another Qcaster’s channel?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="23">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          At the top of the profile of each Qcaster, 
                          you’ll see a ‘subscribe’ button. Simply click 
                          once to add the Qcaster to the list of people you follow. 
                          You’ll then be able to see all their Qcasts in your channel.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="24"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I search Qcasts to find particular people or topics?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="24">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          At the top right corner of the Discover page, 
                          you will see a magnifying glass. Click on this 
                          symbol to open up a free search field. 
                          Enter the terms for which you are searching. 
                          A list of matching results will be displayed, 
                          with most relevant match at the top.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="25"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Who will see my Qcast responses?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="25">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          The Qcaster whose questions your answering will be notified 
                          when you respond and will be able to view your responses. 
                          Plus, anyone who subscribes to your Qcast channel will see your Qcast responses. 
                          Plus, your response will be searchable, so some people might find you that way too. 
                          If you want to maximise your views, then why not forward your 
                          Qcasts and Responses to your friends? Or share them to 
                          your other social media channels.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="26"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Can I DM or private message another Qcaster?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="26">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Yes, you can message a fellow Qcaster using the Message 
                          icon in the navigation bar at the bottom of the screen. 
                          Simply click on the message icon and you’ll be taken to a 
                          screen which displays all your messages to and from other Qcasters, most recent first.
                          If you aren’t already in contact, 
                          there’s a search field so you can find the Qcaster with whom 
                          you wish to communicate. Click on the ‘Message’ button alongside 
                          the Qcaster you wish to message. This will open up a new screen 
                          where you can write your message. Options to add emojis, a file, 
                          or a link to a Qcast from your channel can be found at the bottom 
                          of the screen. When you’re ready, click on the send icon.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="27"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I report offensive or inappropriate content?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="27">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          If you come across offensive or inappropriate content, 
                          please let our team know. We want to ensure Qcast is a 
                          welcoming place for everyone and we need your help to do this. 
                          Let our team know about offensive or inappropriate content by 
                          clicking on the “report user” option on a Qcaster’s profile page. 
                          Our team will investigate. We will keep you informed about any action we take.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="28"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How long will my Qcasts stay live?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="28">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          Qcasts are currently permanent videos which will remain live unless you decide to delete them.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="29"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I edit my Qcast profile?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="29">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          To edit your Channel’s Profile simply select and hold 
                          down your profile picture. This will launch ‘edit profile’. 
                          Once you’ve made your changes simply click Update Profile to save changes.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="30"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I delete my Qcast profile?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="30">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          To delete your Channel’s Profile simply select and hold 
                          down your profile picture. This will launch ‘edit profile’. 
                          To delete your profile, click on the ‘delete’ option and 
                          this will permanently delete your profile. You can find out more about this in our data and <Link to={ROUTES.PRIVACY_POLICY}>privacy policy</Link>.
                          
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="31"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do you store my data?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="31">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          We work with one of the UK’s leading cyber security businesses 
                          to keep your data safe and we comply with all relevant data 
                          protection regulations. You can find out more about 
                          this in our data and <Link to={ROUTES.PRIVACY_POLICY}>privacy policy</Link>.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="32"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    How do I report a problem or fault?
                    </h1>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="32">
                    <Card.Body>
                      <Row>
                        {/* <Col lg="3">
                          <img src={faqImg} width="100%" />
                        </Col> */}

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          You can contact our team by using the ‘Settings’ icon 
                          in the navigation bar at the bottom of the app screen. 
                          Click on ‘Settings’ and you’ll be given the following options:
                          <ul>
                            <li>Notifications</li>
                            <li>Secure login</li>
                            <li>Subscription</li>
                            <li>Help and Info (here’ll you’ll find our FAQs)</li>
                            <li>Contact Qcast (get in touch with our team)</li>
                            <li>Report (use this to report another user or offensive or inappropriate content to our team)</li>
                            <li>About Qcast</li>
                            <li>Logout</li>
                          </ul>
                          </p>
                          <p>
                          Select the ‘Contact Qcast’ option to send us a message. 
                          If you are one of our Pro or Business Plan users, 
                          please add details of which account you have in the subject field.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
 {/* 
                <Card style={{ marginTop: 20 }}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="33"
                    className={css(styles.faqHeadingView)}
                  >
                    <h1 className={css(styles.faqHeading)}>
                    Have we answered your question?
                    </h1>
                  </Accordion.Toggle>
                 <Accordion.Collapse eventKey="33">
                    <Card.Body>
                      <Row>
                        

                        <Col lg="12" style={{ marginTop: 20 }}>
                          <p className={css(styles.description)}>
                          If you haven’t found the answer you need here, please share your question with us.
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Accordion.Collapse> 
                </Card>
*/}

              </Accordion>

            
            </Col>
            
          </Row>
       {/* <div style={{marginTop:10}}>
       <h3 className={`${css(styles.heading2)} HeadingGradient`}>Have we answered your question?</h3>
              <p className={css(styles.subHeading)}>
              If you haven’t found the answer you need here, please share your question with us. 
              </p>
       </div> */}
          
        </Container>
      </section>
      <WebFooter />
    </div>
  );
}
