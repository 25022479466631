// @flow
import { StyleSheet } from 'aphrodite';
import { Colors } from '../../theme';

export default StyleSheet.create({
    skill:{
        width:"100%",
        height:"100%",
        position:"relative",
        border:"none",
        outline: "unset"
      },
      outer:{
        height:"100%",
        width:"100%",
        borderRadius:"50%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        padding:"15px",
        boxShadow: "6px 6px 10px -1px rgba(0,0,0,0.15), -6px -6px 10px -1px rgba(255,255,255,0.7)"
              
      },
      playPause:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "22px",
        width:"100%",
        height:"100%",
        borderRadius:"50%",
        backgroundColor:"black",
        color:'#97AAC3',
        opacity:0,
        zIndex: 1,
        position:'absolute',
        ":hover":{
          opacity:0.5,
          cursor:"pointer"
        }
      },
      inner:{
        height:"100%",
        width:"100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius:"50%",
          boxShadow: "inset 4px 4px 6px -1px rgba(0,0,0,0.2), inset -4px -4px 6px -1px rgba(255,255,255,0.7), -0.5px -0.5px 0px  rgba(255,255,255,1), 0.5px 0.5px 0px  rgba(0,0,0,0.15), 0px 12px 10px -10px rgba(0,0,0,0.05)",
         
      },
      rc:{
        width: "100%",//88%
        height: "100%",//88%
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        borderRadius: "100%",
        WebkitMaskImage: "-webkit-radial-gradient(circle, white 100%, black 100%)",
        WebkitTransform: "rotate(0.000001deg)",
        WebkitBorderRadius: "100%",
        MozBorderRadius: "100%",
      },
      videoCircle: {
       borderRadius: "50%",
       width: "100%",//88%
        height: "100%",//88%
       objectFit: "cover",
       border:"none",
        outline: "unset",
        WebkitMaskImage: "-webkit-radial-gradient(white, black)",
        WebkitBackfaceVisibility: "hidden",
        MozBackfaceVisibility: "hidden",
        WebkitAppearance: "none"
       },
      videoParent:{
          position:"relative"
      },
      play:{
          display: "block",
          left: 0,
          position: "absolute",
          right: "0",
          top: "50%",
      
      }
});
