// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
    minHeight: 500,
  },

  heading: {
    fontSize: 75,
    color: "#FF0000",
    fontWeight: "700",
    fontFamily: "Poppins",
    textAlign: "center",
    "@media (max-width: 767px)": {
      fontSize: 50,
    },
    "@media (max-width: 400px)": {
      fontSize: 38,
    },
  },
});
