// @flow
import React from "react";
import { css } from "aphrodite";
import styles from "./TermsOfServiceStyles";
import { AppStyles } from "../../theme";
import { WebHeader, WebFooter } from "../../components";
import { Link } from 'react-router-dom';
import { ROUTES } from "../../constants";
export default function TermsOfServiceView(props) {
  return (
    <div>
      <WebHeader isChildPage={true} bannerTitle={"Terms of Use"} />
      <section>
        <div className={`px-5 ${css(AppStyles.container)}`}>
          <div className={css(styles.view)}>
            <h2 className={`mb-3 ${css([styles.heading])}`}>
              TERMS OF SERVICE
            </h2>
            <p className={`mb-3 ${css(styles.description)}`}>
              These Terms of Service help determine Qcast’s relationship with you as you use our services on our platform. Understanding these terms are important as you must accept these terms to use the Qcast platform.
            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>SERVICE PROVIDER</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              Qcast services are provided by, and you are contracting with
              <p className={`mb-3 ${css(styles.description)}`}>
              </p>
              Qcast Limited (Company Registration: 13403332)<br />
              155-157 Portsoken House, Aldgate<br />
              London<br />
              England<br />
              United Kingdom EC3N 1LJ

            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>YOUR RELATIONSHIP WITH QCAST</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              These terms help define the relationship between you and Qcast. We give you permission to use our services if you agree to follow these terms, which reflects Qcast’s business model and how Qcast generates revenue. When we speak of “Qcast,” “we,” “us,” and “our,” we mean Qcast Limited.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              Please also refer to our <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link> as a resource that sets expectations of our services to you.
            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>WHAT YOU CAN EXPECT FROM US</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              The Service we provide
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              Our mission is to create a platform that empowers people to ask questions, and find answers to their most pressing problems, creating an insightful digital space.
            </p>
            <ul>
              <li>Connect you with organisations and people you care about</li>
              <li>Empower you to ask questions and answer questions from others</li>
              <li>Help you discover content, products and services that maybe of interest to you</li>
              <li>Use and develop technologies to provide safe and functional services</li>
              <li>Research ways to make our services better</li>
              <li>Provide seamless experience on the Qcast Platform</li>
              <li>Enable global access to our services</li>
            </ul>
            <p className={`mb-3 ${css(styles.description)}`}>
              The license we give you is:
            </p>
            <ul>
              <li>worldwide, which means it’s valid anywhere in the world</li>
              <li>non-exclusive, which means that we can license the software to others</li>
              <li>royalty-free, which means there are no fees for this license</li>
              <li>personal, which means it doesn’t extend to anyone else</li>
              <li>non-assignable, which means you’re not allowed to assign the license to anyone else</li>
            </ul>
            <p className={`mb-3 ${css(styles.description)}`}>
              You may not copy, modify, distribute, sell, or lease any part of our services or software. Also, you may not reverse engineer or attempt to extract any of our source code unless you have our written permission or applicable law lets you do so.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              When a service requires or includes downloadable software, that software sometimes updates automatically on your device once a new version or feature is available. Some services let you adjust your automatic update settings.
            </p>






            <p className={`mb-3 ${css([styles.subHeading])}`}>WHAT WE EXPECT FROM YOU</p>

            <p className={`mb-3 ${css(styles.description)}`}>
              We continue to give you permission to use our services as long as you meet your responsibilities in these Terms.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              Although we give you permission to use our services, we retain any intellectual property rights we have in the services.
            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>WHO CAN USE QCAST</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              When people stand behind their questions and answers, our community is safer and more accountable. For this reason, you must:

            </p>
            <ul>
              <li>use the same name that you use in everyday life;
              </li>
              <li>provide accurate information about yourself;
              </li>
              <li>create only one account (your own); and
              </li>
              <li>not share your password, give access to your Qcast account to others or transfer your account to anyone else (without our permission).
              </li>
            </ul>
            <p className={`mb-3 ${css(styles.description)}`}>
              We try to make Qcast broadly available to everyone, but you cannot use Qcast if:
            </p>
            <ul>
              <li>You are under 13 years old.
              </li>
              <li>You are a convicted sex offender.</li>
              <li>We've previously disabled your account for breaches of our Terms or Policies.
              </li>
              <li>You are prohibited from receiving our products, services or software under applicable laws.</li>
            </ul>
            <p className={`mb-3 ${css([styles.subHeading])}`}>WHAT YOU CAN SHARE ON QCAST</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              Many of our services allow you to interact with others. We want to maintain a respectful environment for everyone, which means you must follow these basic rules of conduct:
            </p>
            <ul>
              <li>comply with applicable laws, including export control, sanctions, and human trafficking laws</li>
              <li>respect the rights of others, including privacy and intellectual property rights </li>
              <li>don’t abuse or harm others or yourself (or threaten or encourage such abuse or harm) — for example, by misleading, defrauding, defaming, bullying, harassing, or stalking others </li>
            </ul>
            <p className={`mb-3 ${css(styles.description)}`}>
              We want people to ask questions and gain uncommon insight from people all over the world, we think having access to such information should not be at the expense of the safety and wellbeing of others. You therefore agree not to engage in the conduct described below.

            </p>

            <ul>
              <li>You may not upload viruses or malicious code, or do anything that could disable, overburden or impair the proper working or appearance of our Products.
              </li>
              <li>You may not access or collect data from our Products using automated means (without our prior permission) or attempt to access data that you do not have permission to access.</li>

            </ul>
            <p className={`mb-3 ${css(styles.subHeading)}`}>THE PERMISSIONS YOU GIVE US</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              Permission to use your content
            </p>
            <ul>
              <li>Our services are designed to let you upload, submit, store, send, receive, or share your content including videos. You have no obligation to provide any content to our services and you’re free to choose the content that you want to provide. If you choose to upload or share content, please make sure you have the necessary rights to do so and that the content is lawful.
              </li>
              <li>Some content that you share or upload, such as photos or videos, may be protected by intellectual property laws. Nothing in these Terms takes away the rights you have to your own content. You are free to share your content with anyone else, wherever you want.</li>
              <li>However, to provide our services, we need you to give us some legal permissions (known as a ‘licence') to use this content. This is solely for the purposes of providing and improving our Products and services.</li>
              <li>Specifically, when you share, post or upload content that is covered by intellectual property rights on or in connection with our Products, you grant us a non-exclusive, transferable, sub-licensable, royalty-free and worldwide licence to host, use, distribute, modify, run, copy, publicly perform or display, translate and create derivative works of your content (consistent with your privacy settings).</li>
              <li>This means, for example, that if you share a photo on Qcast, you give us permission to store, copy and share it with others (again, consistent with your settings) such as service providers that support our service or other Qcast Products you use. This licence will end when your content is deleted from our systems.</li>
              <li>You can delete content individually or all at once by deleting your account. Learn more about how to delete your account. You can download a of your data at any time before deleting your account.
              </li>
            </ul>
            <p className={`mb-3 ${css([styles.description])}`}>Permission to update software that you use or download:</p>
            <ul>
              <li>If you download or use our software, you give us permission to download and install updates to the software where available.</li>
            </ul>
            <p className={`mb-3 ${css([styles.description])}`}>Limits to using our Intellectual Property</p>
            <ul>
              <li>If you use content covered by intellectual property rights that we have and make available in our Products (for example, images, designs, videos or sounds that we provide, which you add to content that you create or share on Qcast), we retain all rights to that content (but not yours). You can only use our copyrights or trademarks as expressly permitted with our prior written permission. You must obtain our written permission (or permission under an open-source licence) to modify, create derivative works of, decompile or otherwise attempt to extract source code from us.</li>
            </ul>


















            <p className={`mb-3 ${css([styles.subHeading])}`}>UPDATING OUR TERMS</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              We will make changes if the terms of service are incomplete, no longer appropriate and is reasonable and will take your interests into account when doing so.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              If we materially change these terms, we’ll provide you with reasonable advance notice and the opportunity to review the changes, except (1) when we launch a new service or feature, or (2) in urgent situations, such as preventing ongoing abuse or responding to legal requirements. If you don’t agree to the new terms, you should remove your content and stop using the services. You can also end your relationship with us at any time by deleting your Qcast Account.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              We will notify you (for example, by email or through our Products) at least 30 days before we make changes to these Terms and give you an opportunity to review them before they go into effect, unless the changes are required by law. Once any updated Terms are in effect, you will be bound by them if you continue to use our Products.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              We hope that you will continue using our Products, but if you do not agree to our updated Terms and no longer want to be part of Qcast, you can delete your account at any time.
            </p>

            <p className={`mb-3 ${css([styles.subHeading])}`}>ACCOUNT SUSPENSION OR TERMINATION</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              If we determine that you have clearly, seriously or repeatedly breached our Terms or Policies, we may suspend or permanently disable access to your account. We may also suspend or disable your account if you repeatedly infringe other people's intellectual property rights or where we are required to do so for legal reasons.
            </p>

            <p className={`mb-3 ${css([styles.subHeading])}`}>LIMITS ON LIABILITY</p>
            <p className={`mb-3 ${css(styles.description)}`}>
              These terms only limit our responsibilities as allowed by applicable law. Specifically, these terms don’t limit Qcast’s liability for death or personal injury, fraud, fraudulent misrepresentation, gross negligence, or willful misconduct.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              Other than the rights and responsibilities described in this section (In case of problems or disagreements), Qcast won’t be responsible for any other losses, unless they’re caused by our breach of these terms.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              As far as the law allows, the total liability to you for claims of any kind that are related to the forum or content on the forum will be limited to £50.
            </p>

            <p className={`mb-3 ${css([styles.subHeading])}`}>DISPUTES</p>

            <p className={`mb-3 ${css(styles.description)}`}>
              If you’re a resident of, or an organization based in, the United Kingdom, these terms and your relationship with Qcast under these terms are governed by English law, and you can file legal disputes in the English courts.
            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>TERMINATION</p>

            <p className={`mb-3 ${css(styles.description)}`}>
              Either you or the company may end the agreement written out in these terms at any time. When our agreement ends, your permission to use Qcast also ends.
            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>ABOUT THESE TERMS</p>

            <p className={`mb-3 ${css(styles.description)}`}>
              These terms describe the relationship between you and Qcast. They don’t create any legal rights for other people or organizations, even if others benefit from that relationship under these terms.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              You will not transfer any of your rights or obligations under these Terms to anyone else without our consent.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              These Terms make up the entire agreement between you and Qcast Limited regarding your use of our Products. They supersede any prior agreements.
            </p>
            <p className={`mb-3 ${css(styles.description)}`}>
              These Terms do not confer any third-party beneficiary rights. All of our rights and obligations under these Terms are freely assignable by us in connection with a merger, acquisition or sale of assets, or by operation of law or otherwise.


            </p>
            <p className={`mb-3 ${css([styles.subHeading])}`}>UPDATED ON 19 NOVEMBER 2021</p>

          </div>
        </div>
      </section>
      <WebFooter />
    </div>
  );
}
