import React,{useEffect} from "react";
import {
    isAndroid,
    isIOS
  } from "react-device-detect";
const Workspace=()=>{
    useEffect(() => {
        if (isAndroid) {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.qcast";
        }else if(isIOS) {
          window.location.href =
            "https://apps.apple.com/app/qcast/id1583558024";
        }
      }, []);
    return (<></>)
}
export default Workspace