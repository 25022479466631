// @flow
import React from "react";
import PricingView from "./PricingView";

class PricingController extends React.Component {
  static propTypes = {};

  render() {
    return <PricingView {...this.props} />;
  }
}

export default PricingController;
