// @flow
import React from "react";
import { Redirect } from "react-router-dom";
import SateliteVideoView from "./SateliteVideoView";
import satalite from "../../assets/data/satalite";
import { ROUTES } from "../../constants";
class SateliteVideoController extends React.Component {
 

  static propTypes = {};
  
  render() {
    let pageData=satalite.filter(v=>v.tag===this.props.match.params.menu);
    
    if(pageData.length>0){
      let videoData=pageData[0].data.filter(v=>v.tag===this.props.match.params.id)
      if(videoData.length>0){

        return <SateliteVideoView {...this.props} videoProp={{video:pageData[0],data:videoData[0]}}/>;
      }else{
        return <Redirect to={ROUTES.SATELITE+"/"+pageData[0].tag} />
      }
     
    }else{
      return <Redirect to={ROUTES.SATELITE} />
    }

    
  }
}

export default SateliteVideoController;
