// @flow
import { StyleSheet } from "aphrodite";
import { Colors, Images } from "../../theme";

export default StyleSheet.create({
  customWidth: {
    maxWidth: 1120,
  },

  section1: {
    marginTop: 60,
  },

  heading: {
    fontSize: 48,
    color: "#23517B",
    fontWeight: "700",
    fontFamily: "Poppins",

    "@media (max-width: 500px)": {
      fontSize: 40,
    },
    "@media (max-width: 400px)": {
      fontSize: 38,
    },
  },

  description: {
    fontSize: 20,
    color: "#515A6B",
    fontWeight: "500",
    fontFamily: "Poppins",
  },

  section2: {
    marginTop: 20,
    marginBottom: 20,
  },
});
